import Footer from "../component/Footer";
import Header from "../component/Header";
import Dashboard_sidebar from "./Dashboard_sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import "./maindashboard.css";
import {
  getSessionForLoginUser,
  setSessionForLoginUser,
} from "../utils/helper";
import { validateUpdateProfile } from "../component/Validation/profileValidation";
import { post_Request } from "../Controller/Login";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
import {
  isValidMobile,
  validateEmail,
} from "../component/Validation/loginValidation";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';

const My_Profile = () => {
  const navigate =useNavigate()
  const User = getSessionForLoginUser("User");
  console.log(User,"User")
  const [content, setContent] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [addressList, setAddressList] = useState("");
  const [addressForm, setAddressForm] = useState(false);
  const [addressFormData, setAddressFormData] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (addressFormData.country) {
      const country = Country.getAllCountries().find(c => c.name === addressFormData.country);
      setSelectedCountry(country ? { value: country.isoCode, label: country.name } : null);
    }
    if (addressFormData.state && selectedCountry) {
      const state = State.getStatesOfCountry(selectedCountry.value).find(s => s.name === addressFormData.state);
      setSelectedState(state ? { value: state.isoCode, label: state.name } : null);
    }
    if (addressFormData.city && selectedState) {
      const city = City.getCitiesOfState(selectedCountry.value, selectedState.value).find(c => c.name === addressFormData.city);
      setSelectedCity(city ? { value: city.name, label: city.name } : null);
    }
  }, [addressFormData]);

  const countryOptions = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    label: country.name,
  }));

  const stateOptions = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map(state => ({
        value: state.isoCode,
        label: state.name,
      }))
    : [];

  const cityOptions = selectedState
    ? City.getCitiesOfState(selectedCountry.value, selectedState.value).map(city => ({
        value: city.name,
        label: city.name,
      }))
    : [];

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setAddressFormData({ ...addressFormData, country: country ? country.label : '', state: '', city: '' });
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(null);
    setAddressFormData({ ...addressFormData, state: state ? state.label : '', city: '' });
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setAddressFormData({ ...addressFormData, city: city ? city.label : '' });
  };
  const handleClose = () => {
    setShow(false);
    set_address(false);
    setAddressForm(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  // address popup

  const [address, set_address] = useState(false);

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [formData, setFormData] = useState({
    firstName: User?.firstName ?? "",
    lastName: User?.lastName ?? "",
    email: User?.email ?User.email:isValidEmail(User.phone)?User.phone:'',
    mobile: User?.mobile ?? "",
    date_of_birth: User?.date_of_birth ?? "",
    gender: User?.gender ?? "",
    pincode: User?.pincode ?? "",
    country: User?.country ?? "",
    state: User?.state ?? "",
    city: User?.city ?? "",
    address1: User?.address1,
    address2: User?.address2,
    latitude: "56788899",
    longitude: "77665566777",
  });
  const handleAddressChangeProfile = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    const validationErrors = validateUpdateProfile({
      ...formData,
      [name]: value,
    });
    setError(validationErrors);
  };
  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validate the form data
      const validationErrors = validateUpdateProfile(formData);
      setError(validationErrors);
      // Check if there are any validation errors
      if (Object.keys(validationErrors).length === 0) {
        const options = {
          url: "/users/edit-profile",
          postData: formData,
        };
        await post_Request(options).then((response) => {
          if (response && response.status === true) {
            if (response.result.token) {
              setSessionForLoginUser("User", response.result);
              sessionStorage.setItem("TOKEN", response.result.token);
              setShow(false);
              if (address === true) {
                setContent({ h1: "Profile Updated Successfully", p: null,h7:"Ok" });
                set_address(false);
                setShowPopup(true);
              } else {
                set_address(true);
              }
            }
          }
        });
      }
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      // Handle errors occurred during form submission
    }
  };
  const getAddressList = async () => {
    const options = {
      url: "/users/address-list",
    };
    await post_Request(options).then((response) => {
      setAddressList(response.result);
    });
  };
  useEffect(() => {
    getAddressList();
  }, []);
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddressFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleUpdateAddress = async () => {
    const temp = {
      ...addressFormData, // Include all properties from addressFormData
      address_id: addressFormData._id, // Add address_id property with value from _id
    };
    try {
      const options = {
        url: "/users/edit_address",
        postData: temp,
      };
      const result = await post_Request(options);
      if (result.status === true) {
        setContent({ h1: "Address Updated Successfully", p: null });
        setAddressForm(false);
        setShowPopup(true);
        getAddressList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveAddress = async (item) => {
    try {
      const options = {
        url: "/users/delete_address",
        postData: {
          address_id: item._id,
        },
      };
      const result = await post_Request(options);
      if (result.status === true) {
        setContent({ h1: "Address Deleted Successfully", p: null });
        setShowPopup(true);
        getAddressList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Header></Header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="dashbaord">
              <h1>my Account</h1>
              <img
                src={process.env.PUBLIC_URL + "./img/address_line.svg"}
                alt="logo"
              />
              <br></br>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and <br></br>{" "}
                typesetting industry. Lorem Ipsum has been the industry's.
              </p> */}
              <button onClick={()=>{navigate('/Listing')}}>Shop Now</button>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <Dashboard_sidebar></Dashboard_sidebar>
              </div>
              <div className="col-md-9">
                <div className="profile_detail">
                  <h1>Profile Details</h1>
                  <p>
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                  </p>
                  <div className="profile_details_inner">
                    <ul>
                      <li>
                        <span className="user_information">Name</span>{" "}
                        <span>: </span>{" "}
                        <p>
                          {" "}
                          {User?.firstName} {User?.lastName}{" "}
                        </p>
                      </li>
                      <li>
                        <span className="user_information">
                          DOB ( Date of Birth )
                        </span>{" "}
                        <span>: </span> <p> {User?.date_of_birth}</p>
                      </li>
                      <li>
                        <span className="user_information">Gender</span>{" "}
                        <span>: </span> <p> {User?.gender} </p>
                      </li>
                      <li>
                        <span className="user_information">Mobile No.</span>{" "}
                        <span>: </span> <p> {User?.mobile} </p>
                      </li>
                      <li>
                        <span className="user_information">Email</span>{" "}
                        <span>: </span> <p className="user_email"> {User?.email} </p>
                      </li>
                    </ul>
                    <div className="d-flex">
                      <div className="">
                        <button onClick={handleShow}>
                          <FontAwesomeIcon icon={faEdit} />{" "}
                          <span className="edit">Edit</span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <h1 className="mt-3">Profile Details</h1>
                  <p>
                    The Most-Coveted Essentials. Curated By Us, Inspired By You.
                  </p>
                </div>
                {addressList?.length > 0 ? (
                  addressList?.map((item, index) => (
                    <div className="address" key={index}>
                      <div className="d-flex">
                        <h1 className="mx-0">Address {index + 1}</h1>
                        <div className="">
                          <button>
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={() => {
                                setAddressFormData(item);
                                setAddressForm(true);
                              }}
                            />
                          </button>
                          <button>
                            {" "}
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => {
                                handleRemoveAddress(item);
                              }}
                            />
                          </button>
                        </div>
                      </div>

                      <div>
                        <p>
                          {item.first_name} {item.last_name}, {item.mobile_no}{" "}
                          {item.address2}, {item.address1},{item.city} ,{" "}
                          {item.state} ,{item.country} ,{item.pincode}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="address">
                      <div className="d-flex">
                        <h1 className="mx-0">Address 01</h1>
                        <div className="">
                          <button onClick={()=>{set_address(true)}}>
                            <FontAwesomeIcon icon={faEdit}  />
                          </button>
                          {/* <button>
                            {" "}
                            <FontAwesomeIcon icon={faTrash} />
                          </button> */}
                        </div>
                      </div>
                      <p>
                        {User?.firstName + ","} {User?.lastName + ","}{" "}
                        {User?.mobile + ","} {User?.address1 + ","}{" "}
                      {User?.city + ","}{" "}
                        {User?.state + ","} {User?.country + ","}{" "}
                        {User?.pincode}
                      </p>
                      <div>{/* <p>{addreslistUser}</p> */}</div>
                    </div>

                    <div className="address">
                      <div className="d-flex">
                        <h1 className="mx-0">Address 02</h1>
                        <div className="">
                          <button>
                            <FontAwesomeIcon icon={faEdit} onClick={()=>{set_address(true)}}/>
                          </button>
                          {/* <button>
                            {" "}
                            <FontAwesomeIcon icon={faTrash} />
                          </button> */}
                        </div>
                      </div>
                      <p>
                        {User?.firstName + ","} {User?.lastName + ","}{" "}
                        {User?.mobile + ","} {User?.address2 + ","}{" "}
                        {User?.city + ","}{" "}
                        {User?.state + ","} {User?.country + ","}{" "}
                        {User?.pincode}
                      </p>
                      <div>{/* <p>{addreslistUser}</p> */}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_top">
      <Footer ></Footer>
      </div>
      <Modal show={show} onHide={handleClose} size="lg" id="add_address">
        <div className="cross py-0">
          <img
            src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
            alt="logo"
            className="model_logo"
          />
          <div className="update_profile_header">
            <h1 className="heading">Update Your Profile Details</h1>
            <p>
              {/* {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. */}
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "./img/cross.svg"}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div></div>

        <Modal.Body className="py-0">
          <form>
            <div className="row mt-2">
              <h1 className="sub_heading"> Fill the details Below</h1>
            </div>
            <div className="row">
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  placeholder="First Name"
                  className="form_group"
                  onChange={handleAddressChangeProfile}
                />
                {error.firstName && (
                  <div className="error_Validtaion">{error.firstName}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName}
                  placeholder="Last Name"
                  className="form_group"
                  onChange={handleAddressChangeProfile}
                />
                {error.lastName && (
                  <div className="error_Validtaion">{error.lastName}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="number"
                  name="mobile"
                  placeholder="Mobile Number"
                  className="form_group"
                  value={formData?.mobile}
                  onChange={handleAddressChangeProfile}
                />
                {error.mobile && (
                  <div className="error_Validtaion">{error.mobile}</div>
                )}
              </div>
              <div className="col-md-12 padding-right">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className="form_group"
                  value={formData?.email}
                  onChange={handleAddressChangeProfile}
                  readOnly={isValidEmail(User.phone)} 
                />
                {error.email && (
                  <div className="error_Validtaion">{error.email}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="date"
                  name="date_of_birth"
                  placeholder="dd-mm-yyyy"
                  className="form_group"
                  value={formData?.date_of_birth}
                  onChange={handleAddressChangeProfile}
                />
                {error.date_of_birth && (
                  <div className="error_Validtaion">{error.date_of_birth}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <select
                  className="form_group"
                  name="gender"
                  value={formData.gender}
                  onChange={handleAddressChangeProfile}
                >
                  <option>Gender</option>
                  <option>Women</option>
                  <option>Men</option>
                </select>
                {error.gender && (
                  <div className="error_Validtaion">{error.gender}</div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>

        <div className="model_footer">
          <button className="view_cart" onClick={handleRegistrationSubmit}>
            Save & Proceed
          </button>
        </div>
      </Modal>
      <Modal show={address} onHide={handleClose} size="lg" id="add_address">
        <div className="cross py-0">
          <img
            src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
            alt="logo"
            className="model_logo"
          />
          <div className="update_profile_header">
            <h1 className="heading">Update Address</h1>
            <p>
              {/* {" "}
              Lorem Ipsum is simply dummy  */}
            </p>
          </div>
          <img
            src={process.env.PUBLIC_URL + "./img/cross.svg"}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div></div>

        <Modal.Body className="py-0">
          <form>
            <div className="row mt-2">
              <h1 className="sub_heading"> Fill the details Below</h1>
            </div>
            <div className="row">
              {/* <div className="col-md-4 padding-right">
                    <input type="date" name="begin"
                     placeholder="dd-mm-yyyy" className="form_group"
                     
                      /> 
                    </div> */}
              {/* <div className="col-md-4 padding-right">
                    <select  className="form_group">
                           <option>Gender</option>
                           <option>Women</option>
                           <option>Men</option>
                           
                        </select>
                    </div>
                    <div className="col-md-4">
                    <input type="Pincode" className="form_group" placeholder="Mobile Number"/>
                    </div> */}

              <div className="col-md-12">
                <input
                  type="text"
                  name="address1"
                  className="form_group"
                  placeholder="Address Line 1"
                  value={formData.address1}
                  onChange={handleAddressChangeProfile}
                  required
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  name="address2"
                  className="form_group"
                  placeholder="Address Line 2"
                  value={formData.address2}
                  onChange={handleAddressChangeProfile}
                />
              </div>

              <div className="col-md-4 padding-right">
        <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select Country"
          className="form_group"
        />
      </div>
      <div className="col-md-4 padding-right">
        <Select
          options={stateOptions}
          value={selectedState}
          onChange={handleStateChange}
          placeholder="Select State"
          className="form_group"
          isDisabled={!selectedCountry}
        />
      </div>
      <div className="col-md-4 padding-right">
        <Select
          options={cityOptions}
          value={selectedCity}
          onChange={handleCityChange}
          placeholder="Select City"
          className="form_group"
          isDisabled={!selectedState}
        />
      </div>
            </div>
          </form>
        </Modal.Body>

        <div className="model_footer">
          <button className="view_cart" onClick={handleRegistrationSubmit}>
            {" "}
            Save & Proceed
          </button>
        </div>
      </Modal>

      <Modal show={addressForm} onHide={handleClose} size="lg" id="add_address">
        <div className="cross py-0">
          <img
            src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
            alt="logo"
            className="model_logo"
          />

          <img
            src={process.env.PUBLIC_URL + "./img/cross.svg"}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div></div>

        <Modal.Body className="py-0">
          <form>
            <div className="row mt-2">
              <h1 className="sub_heading"> Fill the details Below</h1>
            </div>
            <div className="row">
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="first_name"
                  value={addressFormData?.first_name}
                  placeholder="First Name"
                  className="form_group"
                  onChange={handleAddressChange}
                />
                {error.first_name && (
                  <div className="error_Validtaion">{error.first_name}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="last_name"
                  value={addressFormData?.last_name}
                  placeholder="Last Name"
                  className="form_group"
                  onChange={handleAddressChange}
                />
                {error.lastName && (
                  <div className="error_Validtaion">{error.last_name}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="number"
                  name="mobile_no"
                  placeholder="Mobile Number"
                  className="form_group"
                  value={addressFormData?.mobile_no}
                  // disabled={isValidMobile(email) ? true : false}
                  onChange={handleAddressChange}
                />
                {error.mobile && (
                  <div className="error_Validtaion">{error.mobile_no}</div>
                )}
              </div>

              <div className="col-md-4 padding-right">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  className="form_group"
                  value={addressFormData?.email}
                  // disabled={validateEmail(email)? true : false}
                  onChange={handleAddressChange}
                />
                {error.email && (
                  <div className="error_Validtaion">{error.email}</div>
                )}
              </div>
              <div className="col-md-4 padding-right">
                <input
                  type="text"
                  name="address2"
                  placeholder="land mark"
                  className="form_group"
                  value={addressFormData?.address2}
                  onChange={handleAddressChange}
                />
                {error.address2 && (
                  <div className="error_Validtaion">{error.address2}</div>
                )}
              </div>
              <div className="col-md-4">
                <input
                  type="Pincode"
                  name="pincode"
                  className="form_group"
                  placeholder="Pine code"
                  value={addressFormData?.pincode}
                  onChange={handleAddressChange}
                />
                {error.pincode && (
                  <div className="error_Validtaion">{error.pincode}</div>
                )}
              </div>

              <div className="col-md-12">
                <input
                  type="text"
                  name="address1"
                  className="form_group"
                  placeholder="Address"
                  value={addressFormData?.address1}
                  onChange={handleAddressChange}
                />
                {error.address1 && (
                  <div className="error_Validtaion">{error.address1}</div>
                )}
              </div>

              <div className="col-md-4 padding-right">
        <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select Country"
          className="form_group"
        />
        {error.country && <div className="error_Validtaion">{error.country}</div>}
      </div>
      <div className="col-md-4 padding-right">
        <Select
          options={stateOptions}
          value={selectedState}
          onChange={handleStateChange}
          placeholder="Select State"
          className="form_group"
          isDisabled={!selectedCountry}
        />
        {error.state && <div className="error_Validtaion">{error.state}</div>}
      </div>
      <div className="col-md-4 padding-right">
        <Select
          options={cityOptions}
          value={selectedCity}
          onChange={handleCityChange}
          placeholder="Select City"
          className="form_group"
          isDisabled={!selectedState}
        />
        {error.city && <div className="error_Validtaion">{error.city}</div>}
      </div>
    
            </div>
          </form>
        </Modal.Body>

        <div className="model_footer">
          <button
            className="view_cart"
            onClick={() => {
              handleUpdateAddress();
            }}
          >
            {/* <Link to="/Maindashbaord" onClick={handleRegistrationSubmit}> */}
            Save & Proceed
            {/* </Link> */}
          </button>
          {/* <button className='skip_btn'>Skip For Now</button> */}
        </div>
      </Modal>
      <ThankYoupopup isOpen={showPopup} content={content} />
    </>
  );
};

export default My_Profile;
