import Header from "../component/Header";
import Footer from "../component/Footer";
import "./cart.css";
import { Link, useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import { getSessionForLoginUser } from "../utils/helper";
import { post_Request } from "../Controller/Home_controller";
import { fetchSystemAddress } from "../Controller/API";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
const Cart_address_detail = () => {
  const { state } = useLocation();
  const { cart, subtotal } = useContext(Store);
  const User = getSessionForLoginUser("User");
  const [show, setShow] = useState(false);
  const [content,setContent]=useState(null)
  const [selectedAddress, setSelectedAddress] = useState(state);
  const userAddress1 = `${User?.firstName}, ${User?.lastName}, ${User?.mobile}, ${User?.address2}, ${User?.city}, ${User?.state}, ${User?.country}, ${User?.pincode}`;
  const userAddress2 = `${User?.firstName}, ${User?.lastName}, ${User?.mobile}, ${User?.address1}, ${User?.city}, ${User?.state}, ${User?.country}, ${User?.pincode}`;
  const [isPopupOpen,setisPopupOpen]=useState(false)
  const handleAddressSelection = (addressIndex) => {
    setSelectedAddress(addressIndex);
  };
  const generatesOrder = async () => {
    try {
      // Validate selected address
      if (!selectedAddress) {
        throw new Error("Please select a confirmed address.");
      }
      // if (!paymentMode) {
      //     throw new Error("Please select a payment mode.");
      // }
      const options = {
        url: "/order/generate-order",
        postData: {
          current_ip: await fetchSystemAddress(),
          confirmed_address: selectedAddress,
          coupon: "0",
          discount: "0",
          payment_mode: "online",
          latitude: "878989",
          longitude: "888",
          status: "A",
          created_date: "801105",
        },
      };
      await post_Request(options).then((response) => {
        if (response.status === true);
        setContent({h1:"Thank You",p:'Your order has sussesfully placed'})
        setisPopupOpen(true)
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header></Header>

      <div className="container card_main_Section">
        <div className="row">
          <div className="col-md-8">
            <p className="heading">You are logged in as:</p>
            <div id="address">
              <ul>
                <li>
                  <div className="login_address">
                    <img
                      src={process.env.PUBLIC_URL + "./img/user_dashboard.svg"}
                      alt="logo"
                      className="user_icon"
                    />
                    <p>
                      {User.firstName} {User.lastName}{" "}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="login_address">
                    <img
                      src={process.env.PUBLIC_URL + "./img/call_icon.svg"}
                      alt="logo"
                    />
                    <p>+91 {User.mobile}</p>
                  </div>
                </li>
                <li>
                  <div className="login_address">
                    <img
                      src={process.env.PUBLIC_URL + "./img/location.svg"}
                      className="email_icon"
                      alt="logo"
                    />
                    <p>{User.email}</p>
                  </div>
                </li>
              </ul>
            </div>

            <p className="heading mt-3">Select Delivery Options</p>

            <hr className="mt-2" id="address_under_line"></hr>

            <p className="heading mt-2">Address Book:</p>
            <div className="proceed_checkout" style={{ textAlign: "left" }}>
              <button
                className="mt-1"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add A New Address
              </button>
            </div>
            {show ? (
              <div className="shipping_address_detail_form">
                <div className="d-flex shipping_address">
                  <p>New Shipping Address</p>
                  <p
                    className="delete"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    DELETE
                  </p>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-4 padding-right">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-md-4 padding-right">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-md-4">
                      <input
                        type="number"
                        className="form_group"
                        placeholder="Mobile Number"
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="Address Line 1"
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="Address Line 2"
                      />
                    </div>

                    <div className="col-md-4 padding-right">
                      <select className="form_group">
                        <option>Countery</option>
                        <option>India</option>
                        <option>Dubai</option>
                      </select>
                    </div>
                    <div className="col-md-4 padding-right">
                      <select className="form_group">
                        <option>State</option>
                        <option>Up</option>
                        <option>Banglore</option>
                        <option>Pune</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <select className="form_group">
                        <option>City</option>
                        <option>Noida</option>
                        <option></option>
                      </select>
                    </div>

                    <div className="col-md-12">
                      <div className="saving_address">
                        <input type="radio" />
                        <p className="heading">Save For Future Address</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
            <div className="address">
              <div className="d-flex">
                <input
                  type="radio"
                  id="address1"
                  name="selectedAddress"
                  value={userAddress1}
                  checked={selectedAddress === userAddress1}
                  onChange={() => handleAddressSelection(userAddress1)}
                />
                <label htmlFor="address1">
                  <h1>Address 01</h1>
                </label>
                {/* <h1>Address 01</h1> */}
              </div>

              <div>
                <p> {userAddress1}</p>
              </div>
            </div>

            <div className="address">
              <div className="d-flex">
                <input
                  type="radio"
                  id="address2"
                  name="selectedAddress"
                  value={userAddress2}
                  checked={selectedAddress === userAddress2}
                  onChange={() => handleAddressSelection(userAddress2)}
                />
                <label htmlFor="address2">
                  <h1>Address 02</h1>
                </label>
              </div>
              <div>
                <p>{userAddress2}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <p className="heading">Payment Details</p>
            <hr className="heading_line"></hr>

            <div className="coupen_code">
              <input type="text" placeholder="Apply Coupon Code"></input>
              <img
                src={process.env.PUBLIC_URL + "./img/coupen.svg"}
                alt="logo"
              />
            </div>

            <div className="detail_price">
              <div className="">
                <h1>detail</h1>
                <h1>Price</h1>
              </div>

              <hr></hr>
              <div className="">
                <p>Sub Total</p>
                <p>₹ {subtotal}</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Coupon Discount</p>
                <p className="disoucnt_coupen_price">
                  <span className="discount_price">- ₹ 0 </span>
                </p>
              </div>
              <hr></hr>
              <div className="">
                <p>Delivery Charges</p>
                <p>₹ 0</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Other Charges</p>
                <p>₹ 0</p>
              </div>
            </div>
            <div className="mt-3 py-2 cart_total">
              <p>Total</p>
              <p>₹ {subtotal}</p>
            </div>
            <div className="order_item">
              <p className="heading">Items In Your Order</p>
              <hr className="heading_line"></hr>
              {cart?.map((item, index) => (
                <>
                  <div className="cart_product_deatil" key={index}>
                    <div className="cart_product_img">
                      <img
                        src={IMAGE_BASE_URL + item.product_image}
                        alt="logo"
                      />
                    </div>
                    <div className="total-product">
                      <h1>{item.product_name}</h1>
                      <p className="price mb-0">₹ {item.price}</p>
                      <p className="product_color mb-0">Color : {item.color}</p>
                      <p className="product_color">Size : {item.size}</p>
                    </div>
                  </div>
                  <hr className="mt-3"></hr>
                </>
              ))}

              <div className="proceed_checkout">
                <button>
                  <div onClick={generatesOrder}>Proceed To Pay</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
<ThankYoupopup isOpen={isPopupOpen} content={content}/>
<div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
  );
};

export default Cart_address_detail;
