import React, { useState ,useEffect,useContext} from 'react'
import { Modal } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Store } from '../../Redux/store';

function ThankYoupopup({ isOpen,content}) {

const {getCart}=useContext(Store)
    const navigate=useNavigate()
    const [showModal, setShowModal] = useState(false);
    const handleClose=()=>{setShowModal(false);  getCart();}
    const handleNavigate=()=>{
      if(content?.Link){
        navigate(content.Link)
        }else if(content?.AW){
                  navigate('/Listing')
                  handleClose()
        } else if(content.p===null){
        setShowModal(false)
    } else   navigate('/My_order')
 
    }
    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen,content]);
  return (
    <>
    <Modal show={showModal} onHide={handleClose}  className="thank_pop" id="feedback_popup" centered>
          <div className='cross py-0'>
          <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" />
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <h1>{content?.h1}</h1>
        <p className='heading mb-0'>{content?.p ?? ''}</p>


       
        </div>
      {content.p===!null?  
        <Modal.Body className="py-0">
        <div className="thank_you_popup">
            <div className="row">
         <div className="col-md-4">
          <h2>10%</h2>
          <p className="discount_voucher">Discount Voucher</p>
         </div>
         <div className="col-md-8 px-0">
            <h2  className="thank_you_heading">  Thank you</h2>
            <p className="inner_content"> for ordering from sportking Use code  <span>”THANKS”</span>   at checkout for 10% discount.</p>
       

         </div>
        </div>
        </div>
        </Modal.Body>
     :""}
         <div className='model_footer'>
          <button className='view_cart' onClick={handleNavigate} >{content?.h3?"View my orders":content?.h4?content?.h4:content?.AW?content?.AW: content?.h7?content?.h7:"Proceed To Next"}</button>
          </div> 
          
        
       
      </Modal>
    </>
  )
}

export default ThankYoupopup
