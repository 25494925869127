import React ,{useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Sportking = () =>{
    const settings = {
        dots: false,
      infinite: false, 
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
            breakpoint: 1024, // Medium devices (tablets, 768px and up)
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768, // Small devices (landscape phones, 576px and up)
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480, // Extra small devices (portrait phones, less than 576px)
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
      };
      useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://static.elfsight.com/platform/platform.js';
        script.defer = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    return(
        <>
         <div className='best_selleres sportking_Slider'>
         <div className="elfsight-app-20e75566-2866-418d-b942-1c0e2403a620" data-elfsight-app-lazy></div>
    </div>

        </>
    )
}

export default Sportking;
