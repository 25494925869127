import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from '../Config/constant';
const Mentor_features = ({brandBannerProductData}) =>{
  const navigate= useNavigate()
    const settings = {
        dots: false,
      infinite: false, 
      speed: 500,
      slidesToShow: 4.4,
      slidesToScroll: 4.4,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
            breakpoint: 1024, 
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768, 
            settings: {
                slidesToShow: 1.5,
                centerMode: false,
                slidesToScroll: 1.5
            }
        }
      
    ]
      };

    return(
        <>
         <div className='features_slides'>
      <Slider {...settings}>

      {brandBannerProductData?.featuredProduct?.map((item,index)=>(
            <div className="" key={index} 
            
            onClick={()=>{const data={ mentorLatestCol:{
              subcategory_id:item.subcategory_id,
              type:item.type,
              featured_products_type:item.title_slug
             } };navigate("/Listing", { state: data });}}
            // onClick={()=>{const featuered_product=item.title_slug;navigate('/Listing',{ state: {featuered_product }})}}
            >
            <img src={IMAGE_BASE_URL+item.image}  alt="featured Product" />
             <p>{item.title}</p>
            </div>
                            )) }

      {/* <div >
      <img src={process.env.PUBLIC_URL + "./img/product_sliders_one.png"} alt="logo" />
      <p>Weekend Essentials</p>
      </div> */}
      </Slider>
    </div>

        </>
    )
}

export default Mentor_features;
