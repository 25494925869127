import React, { useState, useEffect,useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getBannerdata } from '../Controller/Home_controller';
import { Store } from '../Redux/store';
import { useNavigate } from 'react-router-dom';
import { Skeleton } from "antd";
import { IMAGE_BASE_URL } from '../Config/constant';

const Main_slider = () => {
  const navigate=useNavigate()
  const {homeBanner}=useContext(Store)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [homebanner, setHomeBanner] = useState([]);

//   useEffect(() => {
//    const fetchData = async () => {
//      try {
//        const option = {
//          url: `/common/homepage-banner`,
//          postData: {
//            page_name: 'home'
//          }
//        };
//        const response = await getBannerdata(option); // Log the API response
//        if (response.status === true) {
//          setHomeBanner(response.result);
// // Log the updated homebanner
//        } else {
//          console.error('Error: Unable to fetch banner data');
//        }
//      } catch (error) {
//        console.error('Error:', error);
//      }
//    };
//    fetchData();
//  }, []);
 

  return (
    <div className='home_slick_slider'>
  <Slider {...settings}>
  {homeBanner && homeBanner.homeBannerData && homeBanner.homeBannerData.length > 0  ? (
    homeBanner.homeBannerData.map((banner, index) => (
      <div key={index}  >
        <img src={`${IMAGE_BASE_URL}${banner.banner_image_1}`} alt="Banner" className='slider_img' />
        <div className='slider_text'>
        {banner?.title? <div className='slider_lines'></div> :""}
          <h1>{banner.title}</h1>
          {/* <h1 className='collection'>Collection</h1> */}
          {banner?.title?    <div className='slider_lines'></div> :""}
          <p>{banner.description}</p>
          {/* <button onClick={() => {
            const data = {
              mentorLatestCol: {
                type: 'latest_arrival'
              }
            };
            navigate('/Listing', { state: data });
          }}>{banner.button_title}</button> */}
        </div>
      </div>
    ))
  ) : (
    Array.from({ length: 1 }, (_, i) => (
      <div key={i}>
        <Skeleton.Avatar
          size={1100}
          shape="square"
          active
          style={{ height: "400px" }}
        />
      </div>
    ))
  )}
</Slider>

    </div>
  );
};

export default Main_slider;
