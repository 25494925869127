import Header from "../component/Header";
import Footer from "../component/Footer";
import "./cart.css";
import { Link } from "react-router-dom";
import { useState } from "react";
const Confirmation = () =>{
    const [quantity, setQuantity] = useState(1)
    const [showInfoProductImg, setShowInfoProductImg] = useState(false);
    const handle_MouseOver = () => {
        // Set a different src for work-thumb on mouse hover
        setThumbSrc(process.env.PUBLIC_URL + './img/slider_large_img.png');
        setShowInfoProductImg(true);
      };
    
      const handleMouseLeave = () => {
        // Set back the original src on mouse leave
        setThumbSrc(process.env.PUBLIC_URL + './img/mentor_sliding_imgs.png');
        setShowInfoProductImg(false);
      };
      const [thumbSrc, setThumbSrc] = useState(process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png");

      const handleMouseOver = (event) => {
        const newSrc = event.target.dataset.thumb;
        setThumbSrc(newSrc);
      };
    const handleIncrement = () => {
      if (quantity < 20) {
        setQuantity(quantity + 1)
      }
    }

    const handleDecrement = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1)
      }
    }
    return(
        <>
        <Header></Header>
        
        <div className="container mt-3 mb-5">
            <div className="row">
                <div className="col-md-8">
                <p className="heading">Your Shopping Bag</p>
                <hr className="heading_line"></hr>

                <div className="cart_product_deatil">
                 <div className="cart_product_img">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo" />
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                 <div className="quantity">
                 <p className="product_color">Quantity : </p>
                 <p className="price mb-0 mx-1">01</p>
                 </div>
                
                 </div>
                </div>
                <hr className="heading_line  my-4"></hr>

                <div className="cart_product_deatil">
                 <div className="cart_product_img">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo" />
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                 <div className="quantity">
                 <p className="product_color">Quantity : </p>
                   <p className="price mb-0 mx-1">01</p>
                 </div>
                
                 </div>
                </div>
                <p className="heading mt-4">Enter Shipping Address</p>
                <hr className="heading_line"></hr>
                <div className="address">
                        <div className="d-flex">
                        <input type="radio" id="specifyColor" name="radio1" value="GFG"></input>
                        <h1>Address 01</h1>
                        </div>
                        <div> 
                            <p>Harpreet kaur, +91 798-269-1137, C-20/1, C Block, Phase 2, Industrial Area, Sector 62, Noida, Uttar Pradesh 201301</p>
                        </div>
                </div>
                </div>
                <div className="col-md-4">
                  <p className="heading">Payment Details</p>
                  <hr className="heading_line"></hr>

                  <div className="coupen_code">
                    <input type="text" placeholder="Apply coupon Code"></input>
                  <img src={process.env.PUBLIC_URL + "./img/coupen.svg"} alt="logo" />
                  </div>

                   <div className="detail_price">
                    <div className="">
                    <h1>detail</h1>
                    <h1>Price</h1>
                    </div>
                     
                     <hr></hr>
                    <div className="">
                    <p>Sub Total</p>
                    <p>₹ 20,000</p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p >Coupon Discount</p>
                    <p className="disoucnt_coupen_price"><span className="discount_price">- ₹ 2,000 </span></p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p>Delivery Charges</p>
                    <p>₹ 20,000</p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p>Other Charges</p>
                    <p>₹ 20,000</p>
                    </div>
                  
                    
                   </div>
                   <div className="mt-3 py-2 cart_total">
                    <p>Total</p>
                    <p>₹ 20,000</p>
                    </div>

                    <div className="proceed_checkout">
                        <button>Proceed To Checkout</button>
                    </div>
                </div>
            </div>


            
        </div>
       
        <div className="footer_top">
      <Footer ></Footer>
      </div>
        </>
    )
}

export default Confirmation;
