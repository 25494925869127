// set user profile or login a user


export function logout() {
  sessionStorage.clear(); // Clear all session data
  // Redirect the user to the login page or perform other necessary actions
}

  export function setSessionForLoginUser(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

    // Get session data
    export function getSessionForLoginUser(key) {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    }
  