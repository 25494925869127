import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import Header from "../component/Header";
import Footer from "../component/Footer";
import "./location.css";
import locations from './location.json';

const Location = () => {
  const [map, setMap] = useState(null);
  const [mapLocations, setMapLocations] = useState(locations);
  const [search, setSearch] = useState('');
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [markers, setMarkers] = useState([]);
  const infoWindowRef = useRef(null);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);

    const filtered = mapLocations.filter(location =>
      location.city.toLowerCase().includes(value) ||
      location.state.toLowerCase().includes(value) ||
      location.postal_code.includes(value)
    );
    setFilteredLocations(filtered);
  };

  const containerStyle = {
    width: '100%',
    height: '600px'
  };

  const center = {
    lat: 30.7333,
    lng: 76.7794
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (map) {
      // Clear existing markers
      markers.forEach(marker => marker.setMap(null));
      setMarkers([]);

      // Add new markers based on filtered locations
      const newMarkers = filteredLocations.map(location => {
        const { AdvancedMarkerElement } = window.google.maps.marker;

        const marker = new AdvancedMarkerElement({
          map: map,
          position: { lat: location.latitude, lng: location.longitude },
          title: location.city
        });

        marker.addListener('click', () => {
          setSelectedLocation(location);
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }
          const infoWindow = new window.google.maps.InfoWindow({
            content: `
              <div>
                <h2>${location.city}</h2>
                <p>${location.state}</p>
                <p>${location.postal_code}</p>
                <p>${location.email}</p>
              </div>
            `
          });
          infoWindow.open(map, marker);
          infoWindowRef.current = infoWindow;
        });

        return marker;
      });

      setMarkers(newMarkers);
    }
  }, [map, filteredLocations]);

  return (
    <>
      <Header />
      <div className="location_locator">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h1 className="common_heading mb-0">Find A Store Nearby Your Location</h1>
              <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
              <div className='search mb-4'>
                <input
                  placeholder='Search by country, region, or city'
                  value={search}
                  onChange={handleSearchChange}
                />
                <img src={process.env.PUBLIC_URL + "/img/search.svg"} alt="search-icon" />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="container-fluid my-4 mt-4">
          <div className="row">
            <div className="col-md-12 mb-3 mt-4">
              <LoadScript
                googleMapsApiKey="AIzaSyDWe6hkGElNl6Mu8ykJM0X26Iech1w-ohU"
                libraries={['marker']}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={8}
                  onLoad={mapInstance => setMap(mapInstance)}
                  options={{ mapId: 'DEMO_MAP_ID' }}
                />
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_top">
        <Footer />
      </div>
    </>
  );
};

export default Location;
