import Header from "../component/Header";
import Footer from "../component/Footer";
import { post_Request } from "../Controller/Home_controller";
import constant from "../Config/constant";
import { useState, useEffect } from "react";
const Privacy_policy = () => {
  const [term, set_term] = useState([]);

  const privacy_policy = async () => {
    const options = {
      url: "/common/privacy-policy",
    };
    await post_Request(options).then((response) => {
      set_term(response.result.result);
    });
  };

  useEffect(() => {
    privacy_policy();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* {term.length > 0 && (term.map((item, index) => (
                                <div key={index}>
                              
                                    <h1>Privacy Policy</h1>
                                    <p className="common_text mt-4">{item.description}</p>
                                </div>
                            )))} */}

              <h1>Privacy Policy</h1>

              <p className="common_text mt-4">
                {" "}
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from http://sportkingfashion.com/.{" "}
              </p>

              <h3 className="privacy_text_left"> Information we collect:</h3>
              <p className="text-justify">
                When you visit the Site, we automatically collect certain
                information about your device, including information about your
                web browser, IP address, time zone, and some of the cookies that
                are installed on your device. Additionally, as you browse the
                Site, we collect information about the individual web pages or
                products that you view, what websites or search terms referred
                you to the Site, and information about how you interact with the
                Site. We refer to this automatically-collected information as
                “Device Information”.
              </p>
              <p className="text-justify">
                We collect Device Information using the following technologies:
                - “Cookies” are data files that are placed on your device or
                computer and often include an anonymous unique identifier. For
                more information about cookies, and how to disable cookies,
                visit http://www.allaboutcookies.org. - “Log files” track
                actions occurring on the Site, and collect data including your
                IP address, browser type, Internet service provider,
                referring/exit pages, and date/time stamps.
              </p>
              <p className="text-justify mt-2 mb-2">
                - “Web icons”, “tags”, and “pixels” are electronic files used to
                record information about how you browse the Site.
              </p>
              <p className="text-justify mt-2 mb-2">
                Additionally when you make a purchase or attempt to make a
                purchase through the Site, we collect certain information from
                you, including your name, billing address, shipping address,
                payment information, email address, and phone number. We refer
                to this information as “Order Information”.
              </p>
              <p className="text-justify mt-2 mb-2">
                When we talk about “Personal Information” in this Privacy
                Policy, we are talking both about Device Information and Order
                Information.
              </p>

              <h3 className="privacy_text_left"> Information we collect:</h3>

              <p className="text-justify mt-2 mb-2">
                We use the Order Information that we collect generally to fulfil
                any orders placed through the Site (including processing your
                payment information, arranging for shipping and providing you
                with invoices and/or order confirmations). Additionally, we use
                this Order Information to: - Communicate with you;
              </p>

              <p className="text-justify mt-2 mb-2">
                - Screen our orders for potential risk or fraud; and - When in
                line with the preferences you have shared with us, provide you
                with information or advertising relating to our products or
                services.
              </p>
              <p className="text-justify mt-2 mb-2">
                We use the Device Information that we collect to help us screen
                for potential risk and fraud (in particular, your IP address),
                and more generally to improve and optimize our Site (for
                example, by generating analytics about how our customers browse
                and interact with the Site, and to assess the success of our
                marketing and advertising campaigns).
              </p>

              <h3 className="privacy_text_left">
                {" "}
                Sharing your personal Information
              </h3>

              <p className="text-justify mt-2 mb-2">
                We share your Personal Information with third parties to help us
                use your Personal Information, as described above. We also use
                Google Analytics to help us understand how our customers use th
              </p>
              <p className="text-justify mt-2 mb-2">
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
              </p>

              <h3 className="privacy_text_left"> Do not track:</h3>

              <p className="text-justify mt-2 mb-2">
                Please note that we do not alter our Site’s data collection and
                use practices when we see a Do Not Track signal from your
                browser.
              </p>
              <h3 className="privacy_text_left"> Data Retention:</h3>

              <p className="text-justify mt-2 mb-2">
                When you place an order through the Site, we will maintain your
                Order Information for our records unless and until you ask us to
                delete this information.
              </p>
              <h3 className="privacy_text_left"> Changes:</h3>

              <p className="text-justify mt-2 mb-2">
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
              </p>
              <h3 className="privacy_text_left">Contact us:</h3>

              <p className="text-justify mt-2 mb-2">
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by Email at customercare.sob@sportking.co.in or reach
                us @ 1800-890-4027, WhatsApp number- 708741927
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_top">
        <Footer></Footer>
      </div>
    </>
  );
};

export default Privacy_policy;
