import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ImageGallery extends Component {
  constructor() {
    super();
    this.state = {
      isHovered: false,
      activeItem: 0, // Set the activeItem to 0 for the first element
    };
  }

  handleMouseOver = (index) => {
    this.setState({
      isHovered: true,
      activeItem: index,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isHovered: false,
      activeItem: 0, // Reset the activeItem to 0 for the first element when leaving
    });
  };

  render() {
    const galleryItems = [
      {
        name: 'Mentor',
        imageUrl: process.env.PUBLIC_URL + '/img/first.jpg',
        url: '/Mentor_homepage',
        logo: process.env.PUBLIC_URL + '/img/mentorLgoNew.png'
      },
      {
        name: 'Sublime',
        imageUrl: process.env.PUBLIC_URL + '/img/second.jpg',
        url: '/Sublime',
          logo: process.env.PUBLIC_URL + '/img/media_sublime.png'
        
      },
      {
        name: 'Woodburn',
        imageUrl: process.env.PUBLIC_URL + '/img/third.jpg',
        url: '/woodburn',
      logo: process.env.PUBLIC_URL + '/img/wdNew.png'
      },
      {
        name: 'Sportking',
        imageUrl: process.env.PUBLIC_URL + '/img/fourth.jpg',
        url:'/Kid_home',
        logo: process.env.PUBLIC_URL + '/img/sprtkinglatestlogo.png'
      },
    ];

    return (
      <div id="wrap">
        <div className={`container ${this.state.isHovered ? 'hover' : ''}`}>
      
          <h1>Introducing <br /> Our Brand Collection</h1>
          <ul>
            {galleryItems.map((item, index) => (
              <li key={index}>
                <div
                  className={`container-item ${this.state.activeItem === index ? 'active' : ''}`}
                  onMouseOver={() => this.handleMouseOver(index)}
                  onMouseLeave={this.handleMouseLeave}
                >
                    <img src={item.logo} className="mentor_logo" alt="line" />
                  <Link to={item.url} className="link"> {item.name}</Link>
                  <div className="image">
                    <img src={item.imageUrl} alt="image" />
                  </div>
                </div>
                <img src={process.env.PUBLIC_URL + '/img/line_wrap.png'} className="wrap_line" alt="line" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default ImageGallery;
