import React, { useState,useRef, useEffect } from "react";
import Footer from "../component/Footer";
import Dashboard_sidebar from "./Dashboard_sidebar";
import Header from "../component/Header";
import Accordion from "react-bootstrap/Accordion";
import { useLocation, useNavigate } from "react-router-dom";
import { API_BASE_URL, IMAGE_BASE_URL } from "../Config/constant";
import { Modal } from "react-bootstrap";
import animationData from "./tick.json";
import Lottie from "lottie-react";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons'; 
import { getSessionForLoginUser } from "../utils/helper";
import { fetchSystemAddress } from "../Controller/API";
import { post_Request } from "../Controller/Home_controller";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const User = getSessionForLoginUser('User');

  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [trackingData,setTrackingData]=useState('')
  const [stars, setStars] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [review_statment, setreview_statment] = useState(false);
  const [error, setError] = useState('');
  const [isClicked, setIsClicked] = useState(false);

  const inputRef = useRef(null); 
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
      setreview_statment(false);
    //   set_cancel_confirm(false);
    //  set_cancel(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const handleStarClick = (starValue) => {
    setStars(starValue);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setDroppedFiles([...droppedFiles, ...files]);
  };



  const submitReview = async () => {
    setIsClicked(true);

    // Validation
    if (!stars) {
      setErrorMessage("Please provide a rating.");
      setIsClicked(false);
      return;
    }

    if (!reviewText) {
      setErrorMessage("Please write a review.");
      setIsClicked(false);
      return;
    }

    if (droppedFiles.length === 0) {
      setErrorMessage("Please upload at least one photo or video.");
      setIsClicked(false);
      return;
    }

    setErrorMessage(''); // Clear any previous error messages

    try {
      const current_ip = await fetchSystemAddress();
      const feedbackData = new FormData();

      feedbackData.append("star", stars);
      feedbackData.append("review", reviewText);
      feedbackData.append("products_id", state?.product_id);
      feedbackData.append("current_ip", current_ip);
      feedbackData.append("user_id", User?._id);
      droppedFiles.forEach((file) => {
        feedbackData.append("image", file);
      });

      axios
        .post(API_BASE_URL + "/order/add_product_reviews", feedbackData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "authToken": User?.token,
            "key": "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
          },
        })
        .then((response) => {
          // Handle successful submission
          setStars(0);
          setDroppedFiles([]);
          setReviewText('');
          handleClose(); // Close the modal after submission
          setreview_statment(true);
          setIsClicked(false);
        })
        .catch((error) => {
          // Handle error
          console.error("Error submitting feedback:", error);
          setIsClicked(false);
        });
    } catch (error) {
      console.error("Error fetching system address:", error);
      setIsClicked(false);
    }
  };
  const handleFileSelect = (event) => {
    const files = event.target.files;
    setDroppedFiles([...droppedFiles, ...files]);
  };

  useEffect(() => {
    const shiprocketOrderStatus = async () => {
      try {
        const response = await axios.post('https://api.sportkingfashion.com/front/v1/order/api/orders/show', {
          id: state?.shipment_order_id,
          auth: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjQ2ODY5OTUsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzE3Mzk0NDE4LCJqdGkiOiJyenB6bXdzVE11dVJYRDVFIiwiaWF0IjoxNzE2NTMwNDE4LCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcxNjUzMDQxOCwiY2lkIjo0NDk5OTgyLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.Y5yH_jo4KYhXuF4TavzS13vEWaWEQ1LFCAr71vhcoaI'
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        setTrackingData(response?.data);
      } catch (error) {
        setError(error.response ? error.response.data : error.message);
      }
    };

    shiprocketOrderStatus();
  }, []);
  return (
    <>
      <Header />
      <div className="container cart_footer mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="dashbaord">
              <h1>my Account</h1>
              <img
                src={process.env.PUBLIC_URL + "./img/address_line.svg"}
                alt="logo"
              />
              <br></br>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br> typesetting industry. Lorem Ipsum has been the industry's.</p> */}
              <button
                onClick={() => {
                  navigate("/Myprofile");
                }}
              >
                Profile Details
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <Dashboard_sidebar />
          </div>
          <div className="col-md-9">
            <div className="profile_detail">
              <h1>My Orders</h1>
              <p>
                The Most-Coveted Essentials. Curated By Us, Inspired By You.
              </p>
            </div>
            <div className="order-status ">
              <div className="row">
                <div className="col-12 col-md-8">
                  <div className="cart_product_deatil mt-0">
                    <div className="cart_product_img" id="inner-product">
                      <img
                        src={IMAGE_BASE_URL + state.product_image}
                        alt="logo"
                        style={{ width: "170px", height: "210px" }}
                      />
                    </div>
                    <div className="total-product">
                      <h1>{state.product_name}</h1>
                      <p className="price mb-0">₹{state.unitPrice}</p>
                      <p className="product_color">Qty : {state.qty}</p>
                      <p className="product_color mb-0">
                        Color : {state.color}
                      </p>
                      <p className="product_color">Size : {state.size}</p>
                     
                      <button onClick={handleShow} className="feedback_btn">Feedback</button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="detail_price mt-0">
                    <div className="">
                      <h1>detail</h1>
                      <h1>Price</h1>
                    </div>

                    <hr></hr>
                    <div className="">
                      <p>Sub Total</p>
                      <p>₹ {state.unitPrice}</p>
                    </div>
                    <hr></hr>

                    <div className="">
                      <p>Coupon Discount</p>
                      <p className="disoucnt_coupen_price">
                        <span className="discount_price">
                          -{state.discount}{" "}
                        </span>
                      </p>
                    </div>
                    <hr></hr>
                    <div className="">
                      <p>Delivery Charges</p>
                      <p>₹ 0</p>
                    </div>

                    <div className="">
                      <p>Other Charges</p>
                      <p>₹ 0</p>
                    </div>
                  </div>
                  <div className="mt-3 py-2 cart_total">
                    <p>Total</p>
                    <p>₹ {state.price}</p>
                  </div>
                </div>

                {/* <div className="col-md-5">
              <div className="tracking_order">
              <div id="order">
                <button >Return Order</button>
                </div>
                <div id="order">
                <button onClick={handleShow}>Feedback</button>
                </div>
              </div>
                
               
            </div> */}
              </div>

              <div className="row">
                <div className="col-md-12 mt-2 mb-2">
                  <strong className=" py-3 heading_order">
                    Others Details :
                  </strong>
                </div>

                <div className="col-md-3 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Order Id :</strong>
                    </div>
                    <p>{trackingData?.channel_order_id}</p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Payment Mode :</strong>
                    </div>
                    <p>{state.payment_mode}</p>
                  </div>
                </div>
                <div className="col-md-5 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Order Day & Date :</strong>
                    </div>
                    <p>{formatDate(state.created_date)}</p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Payment Status :</strong>
                    </div>
                    <p>{state?.payment_status}</p>
                  </div>
                </div>
                <div className="col-md-4 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Delivery Day & Date :</strong>
                    </div>
                    <p>Saturday, 29 Oct, 2023</p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Order Status:</strong>
                    </div>
                    <p id="deliver">{trackingData?.shipments?.status||'Processing'}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className='col-md-12'>
                    <strong className='mb-1 mt-1 heading_order' >User Details  :</strong>
            </div> */}

                <div className="col-md-3 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Name:</strong>
                    </div>
                    <p>
                      {state.firstName} {state.lastName}
                    </p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Land Mark :</strong>
                    </div>
                    <p>{state.landMark}</p>
                  </div>
                </div>
                <div className="col-md-5 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Email :</strong>
                    </div>
                    <p id="user_email_id">{state.email}</p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Address : </strong>
                    </div>
                    <p>
                      {state.address1}, {state.city}, {state.state},{" "}
                      {state.country}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-4 padding-right">
                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Mobile :</strong>
                    </div>
                    <p>+91 {state.phone}</p>
                  </div>

                  <div className="product-up">
                    <div>
                      {" "}
                      <strong>Pincode :</strong>
                    </div>
                    <p id="delive">{state.pincode}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_top">
        <Footer></Footer>
      </div>

      <Modal show={show} onHide={handleClose} id="review_popup">
      <div className="cross py-0">
        <img
          src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
          alt="logo"
          className="model_logo"
        />
        <img
          src={process.env.PUBLIC_URL + "./img/cross.svg"}
          alt="logo"
          onClick={handleClose}
          id="icon"
          className="model_logo"
        />
      </div>
      <div>
        <h1>Write A Review</h1>
        <p className="heading mb-0">Neque porro quisquam est quite</p>
        <ul>
          {[1, 2, 3, 4, 5].map((value) => (
            <li key={value} onClick={() => handleStarClick(value)}>
              <FontAwesomeIcon
                icon={value <= stars ? faStar : faStarRegular}
                size="2x"
                color="gold"
                style={{ color: "gold" }}
              />
            </li>
          ))}
        </ul>
      </div>
      <Modal.Body className="py-0">
        <div>
          <h2 className="subheading_model mx-0">Add Photo Or Video</h2>
          <label
            htmlFor="fileInput"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
              border: "1px dashed black",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              display: "inline-block", // Ensure the label takes the size of its contents
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "./img/uploading_files.svg"}
              alt="logo"
              className="model_logo"
            />
            <p className="photo_uploading">Click & drop here to upload</p>
            {droppedFiles.length > 0 && (
              <div>
                <h3 className="photo_uploading">Dropped files:</h3>
                <ul>
                  {droppedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileSelect}
            multiple
          />
        </div>

        <div className="write_review">
          <h2 className="subheading_model">Write Your Review</h2>
          <textarea
            className="textarea_address"
            placeholder="Would you like to write anything about this product?"
            value={reviewText}
            onChange={(e) => setReviewText(e.target.value)}
          />
        </div>
        {errorMessage && (
          <div className="error_message" style={{ color: 'red', marginTop: '10px' }}>
            {errorMessage}
          </div>
        )}
      </Modal.Body>
      <div className="model_footer">
        <button
          className="view_cart"
          onClick={submitReview}
          disabled={isClicked} // Disable the button if clicked
        >
          Submit Review
        </button>
      </div>
    </Modal>

      <Modal show={review_statment} onHide={handleClose} id="review_popup">
        <div className="cross py-0">
          <img
            src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
            alt="logo"
            className="model_logo"
          />

          <img
            src={process.env.PUBLIC_URL + "./img/cross.svg"}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div>
          <h1>Thank You</h1>
          <p className="heading mb-0">Your feedback is valuable for us</p>
        </div>

        <Modal.Body className="py-0">
          <div className="thank_you_popup">
            <div className="row">
              <div className="col-md-4">
              <div className="voucher_text">
                <h2>10%</h2>
                <p className="discount_voucher">Discount Voucher</p>
                </div>
           
              </div>
              <div className="col-md-8 px-0">
                <h2 className="thank_you_heading"> Thank you</h2>
                <p className="inner_content">
                  {" "}
                  for ordering from sportking Use code <span>”THANKS”</span> at
                  checkout for 10% discount.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="model_footer">
          <button className="view_cart" onClick={() => {
            const data = {
              mentorLatestCol: {
                type: 'latest_arrival'
              }
            };
            navigate('/Listing', { state: data });
          }} >Continue Shopping</button>
        </div>
      </Modal>
    </>
  );
};

export default OrderDetails;
