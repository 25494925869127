import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import Main_slider from "../Home/Main_slider";
import Bestseller from "../Home/bestseller";
import Sportking from "../Home/sportking";
import ImageGallery from "../Home/Imagegallery";
import { Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import Mentor_features from "./mentor_features";
import Kidhome_slider from "./kidhome_slider";
import Sublime_best_seller from "./sublime_best_seller";
// import Bestseller from './../Home/bestseller'
import '../Home/home.css';
import './woodbern.css';
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import { post_Request } from "../Controller/Home_controller";

const Sublime_homepage = () => {
const {sublimeBannerProductData,homeBanner,trickers}=useContext(Store);
const banner=homeBanner?.sublimeBannerData?.length > 0 ? homeBanner?.sublimeBannerData[0]:null
const [bestSellerProduct,setBestSellerProduct]=useState('') 
const navigate = useNavigate()
    const [loaded, setLoaded] = useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    const imageStyle = {
        transform: loaded ? 'translate(-630, 0)' : 'translate(0px, 0)', // Move from initial position to final position
        transition: 'transform 0.1s ease', // Smooth transition over 0.5 seconds
        width: '100%',
        objectFit: 'fill',
        opacity: loaded ? 1 : 0, // Using opacity for smoother transition
    };

    const handleLatestNavigate = (item) => {
        const data = {
          mentorLatestCol: {
            subcategory_id: item.subcategory_id,
            type: item.type,
          },
        };
        navigate("/Listing", { state: data });
      };

      const getProduct=async(product_ids)=>{
        try{
        const options={
          url: "/common/get-products",
          postData: {
              condition: { 
              status : "A",
              _id: product_ids
        },   limit     : 12,
        page     : 1
          }}
          await post_Request(options).then((response)=>{
            setBestSellerProduct(response.result.productData)
          })
        }catch(error){
        console.log(error)
        }
        }

        useEffect(() => {
          const fetchData = async () => {
            if (sublimeBannerProductData?.bestSellers) {
              const product_ids = sublimeBannerProductData.bestSellers.map(item => item.product_id);
              await getProduct(product_ids);
            }
          };
        
          fetchData(); // Call the async function immediately
        }, [sublimeBannerProductData]); // Add sublimeBannerProductData as a dependency
        console.log(sublimeBannerProductData,"sublimeBannerProductData")
      useEffect(()=>{
        window.scrollTo(0, 0);
        },[])
        const parts =  trickers[0]?.tricker.split('|');
    return (
        <>
            <Header></Header>
            <div className="sublime_homepage">
                <div className="kids_video_section pb-4">
                    <div className="container">
                        <div className="row">
                        <video className="top_banner" controls poster={IMAGE_BASE_URL+banner?.banner_image_1}>
    <source src={IMAGE_BASE_URL+banner?.video_url} type="video/mp4" />
    Your browser does not support the video tag.
  </video>

                        </div>
                    </div>
                </div>

                <div className="shop_now">
                    <div className="container">
                    <div className="row">
            <div className="col-md-12">
              <p>
                {/* New Styles on Sale: Up to 40% Off Shop All Our New Markdowns {" "} */}
                {trickers?.length > 0 ? parts[0]:''}
                | <span className="discount"> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>{trickers?.length > 0 ?parts[1]:''}</Link></span>
              </p>
            </div>
          </div>
                    </div>
                </div>

                <div className="hot_list sublime_hotlist">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-12">
                                <h1 className="common_heading arriavals mb-0">New arrivals in on-trend relaxed fits are here!</h1>
                                <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                            </div> */}
                            <div className="row sublime_text_inner">

                            <div className="col-md-6 padding-right">
  {sublimeBannerProductData?.latestArrivalData?.length > 0 ? (
    sublimeBannerProductData?.latestArrivalData
      ?.slice(0, 1)
      .map((item, index) => (
        <div className="right_side_img drop-left" key={index}>
          <img
            src={IMAGE_BASE_URL+item.image}
            alt="logo"
            style={{ ...imageStyle, display: loaded ? 'block' : 'none' }}
            onLoad={handleImageLoaded}
            className="relax"
          />
               {/* {loaded&&
          <div className="arrivales">
            <h1>Latest Arrival</h1>
            <p> The Most-Coveted Essentials. Curated</p>
          </div>
} */}
        </div>
      ))
  ) : (
    <div className="right_side_img drop-left">
      {Array.from({ length: 1 }, (_, i) => (
        <div key={i}>
          <Skeleton.Avatar
            size={500}
            shape="square"
            active
            style={{ height: "700px" }}
          />
        </div>
      ))}
    </div>
  )}
</div>

                                <div className="col-md-6">
                                            {sublimeBannerProductData?.latestArrivalData?.length > 0 ?
                sublimeBannerProductData?.latestArrivalData
                  ?.slice(1, 3)
                  .map((item, index) => (
                                    <div className="first_banner" key={index}>
                                        <img src={IMAGE_BASE_URL+item.image} />
                                        <div className="new_Arriavel_way">

                                            {/* <h1>New arrivals in on-trend relaxed fits are here!</h1> */}
                                            <button      onClick={() => {
                            handleLatestNavigate(item);
                          }} style={{marginTop:"65%"}}>Shop Now</button>
                                        </div>
                                    </div>
                  )): <div className="first_banner">
                    {Array.from({ length: 1 }, (_, i) => (
                    <div key={i}>
                      <Skeleton.Avatar
                        size={570}
                        shape="square"
                        active
                        style={{ height: "700px" }}
                      />
                    </div>
                  ))}
                    </div>}
                                    {/* <div className="first_banner mt-3">
                                        <img src={process.env.PUBLIC_URL + "./img/banner_third.png"} />
                                        <div className="new_Arriavel">

                                            <h1>New arrivals in on-trend relaxed fits are here!</h1>
                                            <button>Shop Now</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
                <div className="featuered_product" id={'section1'} >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="common_heading arriavals">Our Featured Products</h1>
                                <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                            </div>
                            <div className="col-md-12">
                                <div className="mentor_slider">
                                    <Mentor_features brandBannerProductData={sublimeBannerProductData}></Mentor_features>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="relax_section" id={'section1'} >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="common_heading arriavals mb-0">New arrivals in on-trend relaxed fits are here!</h1>
                                <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                            </div>

                        </div>

                        <div className="row">
                        <div className="col-md-6">
  {sublimeBannerProductData?.latestArrivalData?.length > 0 ? (
    sublimeBannerProductData?.latestArrivalData
      ?.slice(3,4)
      .map((item, index) => (
        <div className="right_side_img drop-left" key={index}>
          <img
            src={IMAGE_BASE_URL+item.image}
            alt="logo"
            style={{ ...imageStyle, display: loaded ? 'block' : 'none', minHeight:"400px" }}
            onLoad={handleImageLoaded}
            className="relax"
          />
             {loaded&&
          <div className="arrivales">
            <h1>Latest Arrival</h1>
            <p> The Most-Coveted Essentials. Curated </p>
          </div>
}
        </div>
      ))
  ) : 
    Array.from({ length: 1 }, (_, i) => (
        <div key={i}>
          <Skeleton.Avatar
            size={500}
            shape="square"
            active
            style={{ height: "600px" }}
          />
        </div>
      ))}
</div>
{sublimeBannerProductData?.latestArrivalData?.length > 0 ?
  sublimeBannerProductData?.latestArrivalData
    ?.slice(4)
    .map((item, index) => (
      <div className="col-md-6" key={index}>
        <div className="arrivales_sublime_right">
          <img src={IMAGE_BASE_URL + item.image} alt="latestcollection"/>
          <div className="arrvals_box_user">
            <h2>Latest Arrival</h2>
            <p>The most-coveted essentials. Curated.</p>
          </div>
        </div>
      </div>
    ))
  :
  Array.from({ length: 1 }, (_, i) => (
    <div className="col-md-6" key={i}>
      <Skeleton.Avatar
        size={500}
        shape="square"
        active
        style={{ height: "600px" }}
      />
    </div>
  ))
}

                        </div>
                    </div>
                </div>



                <div className="best_seller mb-0" id="best_seller_home">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 featuered_product">
                                <h1 className="common_heading">Our Bestsellers</h1>
                                <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                            </div>

                            <div className="col-md-6">
                                <div class="shop_now_seller">                <button onClick={() => {
    const data = {
        mentorLatestCol: {
            subcategory_id: bestSellerProduct?.length > 0 ? bestSellerProduct[0].subcategory_id : '',
            type: sublimeBannerProductData?.length > 0 ? sublimeBannerProductData?.bestSellers[0].type : 'bestsellers'
        }
    };
    navigate("/Listing", { state: data });
}}>Shop Now</button></div>
                            </div>
                        </div>

                        <div className="row">
                            {/* <Sublime_best_seller bestseller={bestSellerProduct}></Sublime_best_seller> */}
                       
                            <Bestseller bestseller={sublimeBannerProductData?.bestSellers}></Bestseller>
                        </div>
                    </div>
                </div>
            </div>



            <div className="footer_top mt-0">
      <Footer ></Footer>
      </div>
        </>
    )
}
export default Sublime_homepage;    
