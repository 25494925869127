

module.exports = {
    API_KEY : "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed",
    // API_BASE_URL : "https://moneysaverz.com:3300/front/v1",
    API_BASE_URL : "https://api.sportkingfashion.com/front/v1",
//    IMAGE_BASE_URL :'https://moneysaverz.com:3300/assets/'
// IMAGE_BASE_URL :' https://sportkingfashion.com/api/'
IMAGE_BASE_URL:"https://sportking.s3.eu-north-1.amazonaws.com/"
}
