import { logout } from "../utils/helper";
import "./maindashboard.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
const Dashboard_sidebar = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logout();
    navigate("/");
  };
  return (
    <>
      <div className="sidebar">
        <ul>
          <NavLink to="/Myprofile">
            {" "}
            <li>My Profile </li>
          </NavLink>
          <NavLink to="/Whishlist" activeClassName="active">
            <li>Wishlist</li>
          </NavLink>

          <NavLink to="/My_order">
            {" "}
            <li>My Orders</li>
          </NavLink>
          <NavLink to="/Return_Payements">
            {" "}
            <li>Return Orders</li>
          </NavLink>
          <NavLink to="/Payments">
            {" "}
            <li> Payments </li>
          </NavLink>
          <li>
            {" "}
            <div className="cursor-pointer" onClick={handleLogout}>
              Logout
            </div>{" "}
          </li>
        </ul>
      </div>
    </>
  );
};

export default Dashboard_sidebar;
