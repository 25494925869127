export const validateOTP = (otp) => {
  const enteredOTP = otp.join("");
  if (enteredOTP.length === 6) {
    // Here you can call your API with the OTP
    console.log("Valid OTP:", enteredOTP);
    return true;
    // Call your API here using enteredOTP
  } else {
    console.log("Invalid OTP");
  }
};
export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const isValidMobile = (mobile) => {
  // Regular expression to validate mobile number
  const mobileRegex = /^(?!0)[0-9]{10}$/;
  return mobileRegex.test(mobile);
};
  

export const validateRegistration = (formData) => {
  const validationErrors = {};

  // Validate firstName
  if (!formData.firstName.trim()) {
    validationErrors.firstName = "First Name is required";
  } else if (formData.firstName.trim().length < 2) {
    validationErrors.firstName = "First Name should be at least 2 characters";
  }

  // Validate lastName
  if (!formData.lastName.trim()) {
    validationErrors.lastName = "Last Name is required";
  } else if (formData.lastName.trim().length < 4) {
    validationErrors.lastName = "Last Name should be at least 4 characters";
  }

  // Validate email
  if (!formData.email.trim()) {
    validationErrors.email = "Email is required";
  } else if (!validateEmail(formData.email)) {
    validationErrors.email = "Invalid email address";
  }

  // Validate mobile
  if (!formData.mobile.trim()) {
    validationErrors.mobile = "Mobile number is required";
  } else if (!isValidMobile(formData.mobile)) {
    validationErrors.mobile = "Invalid mobile number";
  }

  // Validate dateOfBirth
  if (!formData.date_of_birth) {
    validationErrors.date_of_birth = "Date of Birth is required";
  }
 // Validate address1
 if (!formData.pincode.trim()) {
  validationErrors.address1 = "Address1 is required";
}
 // Validate address2
 if (!formData.pincode.trim()) {
  validationErrors.address2 = "Address2 is required";
}
  // Validate pincode
  if (!formData.pincode.trim()) {
    validationErrors.pincode = "Pincode is required";
  }

  // Validate country
  if (!formData.country.trim()) {
    validationErrors.country = "Country is required";
  }

  // Validate state
  if (!formData.state.trim()) {
    validationErrors.state = "State is required";
  }

  // Validate city
  if (!formData.city.trim()) {
    validationErrors.city = "City is required";
  }
  if (!formData.gender.trim()) {
    validationErrors.gender = "Gender is required";
  }

  return validationErrors;
};
