import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import Main_slider from "./Main_slider";
import Bestseller from "./bestseller";
import Sportking from "./sportking";
import ImageGallery from "./Imagegallery";
import { useEffect, useState, useContext } from "react";
import React, { useRef } from "react";
import { Skeleton } from "antd";
import "./home.css";
import { API_BASE_URL, IMAGE_BASE_URL } from "../Config/constant";
import { post_Request } from "../Controller/Home_controller";
import { Store } from "../Redux/store";

const Home = ({ onButtonClick }) => {
  const { bannerProductData,brandBannerProductData ,Collection,trickers} = useContext(Store);
  console.log(bannerProductData,"bannerProductData")
  const navigate = useNavigate();
  const [hotListProduct, setHotListProduct] = useState("");
  const [loaded, setLoaded] = useState(false);
  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const imageStyle = {
    transform: loaded ? "translate(-630, 0)" : "translate(0px, 0)", // Move from initial position to final position
    transition: "transform 0.1s ease", // Smooth transition over 0.5 seconds
    width: "100%",
    objectFit: "fill",
    opacity: loaded ? 1 : 0, // Using opacity for smoother transition
  };
  const getProduct = async (condition) => {
    try {
      const response = await post_Request({
        url: "/common/get-products",
        postData: { condition, limit: 1, page: 1 },
      });
      return response.status ? response.result : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  //   const fetchAndSetProduct = async (condition, setter) => setter(await getProduct(condition));

  //   Promise.all([
  //     fetchAndSetProduct({ "hot_list": "Yes", "status": "A" }, setHotListProduct),
  //     // fetchAndSetProduct({ "latest_arrival": "Yes", "status": "A" }, setLatestArrivalProduct)
  //   ]).then(() => console.log("API calls complete"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const parts =  trickers[0]?.tricker.split('|');
  return (
    <>
      <Header />
      <div className="main_slider">
        <div className="container" style={{minHeight:"500px"}}>
          <Main_slider />
        </div>
      </div>
      <div className="shop_now">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                {/* New Styles on Sale: Up to 40% Off Shop All Our New Markdowns {" "} */}
                {trickers?.length > 0 ? parts[0]:''}
                | <span className="discount"> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>{trickers?.length > 0 ?parts[1]:''}</Link></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="hot_list" >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 padding_right" >
              {bannerProductData?.latestArrivalData?.length > 0 ?
              <div className="right_side_img drop-left ">
                <img
                  src={
                    bannerProductData?.latestArrivalData?.length > 0
                      ? IMAGE_BASE_URL +
                        bannerProductData.latestArrivalData[0].image
                      : ""
                  }
                  alt="logo"
                  style={{ ...imageStyle, display: loaded ? "block" : "none"}}
                  onLoad={handleImageLoaded}
                  className="hm_latest"
                />
                {/* {loaded&&
                
                  <div className="arrivales ">
                    <h1>Latest Arrival</h1>
                    <p>
                      {bannerProductData?.latestArrivalData?.length > 0
                        ? bannerProductData?.latestArrivalData[0]?.title
                        : "The Most-Coveted Essentials. Curated By Us, Inspired By You."}
                    </p>
                  </div>
} */}
              </div>
:     Array.from({ length: 1 }, (_, i) => (
  <div key={i}>
    <Skeleton.Avatar
      size={500}
      shape="square"
      active
      style={{ height: "600px" }}
    />
  </div>
))}
            </div>
            <div className="col-md-12 col-lg-6 padding_left">
              <div className="hot_list_second_box drop-in">
                <h2>
                  {" "}
                  {bannerProductData?.hotListData?.length > 0
                    ? bannerProductData?.hotListData[0]?.title
                    : "Hot List"}
                </h2>
                <p>
                  {" "}
                  {bannerProductData?.hotListData?.length > 0
                    ? bannerProductData?.hotListData[0]?.description
                    : "The Most-Coveted Essentials. Curated By Us, Inspired By You."}
                </p>
                <img
                  src={
                    bannerProductData?.hotListData?.length > 0
                      ? IMAGE_BASE_URL + bannerProductData.hotListData[0].image
                      : ""
                  }
                  alt="logo"
                />
              </div>
              <div className="shop_now">
                <button
                  onClick={() => {
                    const data = {
                      mentorLatestCol: {
                        // featured_products_type:item.featured_products_type,
                        // subcategory_id: bestSellers?.subcategory_id,
                        type:
                          bannerProductData?.hotListData?.length > 0
                            ? bannerProductData?.hotListData[0]?.type
                            : "hot_list",
                      },
                    };
                    navigate("/Listing", { state: data });
                  }}
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="winter_collection" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                src={IMAGE_BASE_URL+Collection?.collection_image}
                alt="logo"
                
                style={{minHeight:"400px"}}
              />

              {/* <div className="collection_content drop-in-2">
                <h2>
                  {" "}
                  {Collection?.title
                    }
                </h2>
                <ul>
                      <React.Fragment>
                        {" "}
               
                        {Collection?.variants?.map((gender, subIndex) => (
                   
                          <li
                            key={subIndex} 
                            onClick={() => {
                              const mentorWinColl = {
                                gender_id: gender.gender_id,
                             
                                product_collection_id: Collection?._id,
                                KEY:"HeadCategory" 
                              };
                              navigate("/Listing", { state: mentorWinColl });
                            }}
                          >
                            <span>{gender.gender_name}</span>{" "}
                    
                          </li>
                        ))}
                      </React.Fragment>
                 
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="featuered_product" id={'section1'} >
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h1 className="common_heading">Our Featured Products</h1>
            <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                </div>
                <div className="col-md-12">
                    <ul>
               
                            {bannerProductData?.featuredProduct?.map((item,index)=>(
                                         <li key={index} onClick={()=>{  const data = {
                                            mentorLatestCol: {
                                                featured_products_type:item.title_slug,
                                                // type:item.type,
                                                // subcategory_id: bestSellers?.subcategory_id,
                                                type: item.type
                                            }
                                        };navigate('/Listing',{ state: data})}}> 
                                        <img src={IMAGE_BASE_URL+item.image} className="product_img"></img>

                            <p >{item.title} </p>
                            </li>
                            )) }

                    </ul>
                </div>

            </div>
       </div>
       </div>

      
       <div className="monster">
        <ImageGallery></ImageGallery>
       </div> 
      <div className="mentor_section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
      </div>

      <div className="brand_collection">
        <div className="container">
          <div className="row">
            <div className="col-md-12 featuered_product">
              <h1 className="common_heading">Our New Brand Collection </h1>
              <p className="common_text">
                The Most-Coveted Essentials. Curated By Us, Inspired By You.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-3 padding_right">
                <ul>
                  {bannerProductData?.newBrandCollection
                    ?.slice(0, 2)
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          alt="logo"
                          className="brandimages"
                          onClick={() => {
                            const data = {
                              mentorLatestCol: {
                                // featured_products_type:item.featured_products_type,
                                // subcategory_id: bestSellers?.subcategory_id,
                                type:
                                  item?.title_slug !== ""
                                    ? item?.title_slug
                                    : "new_brand_collection",
                              },
                            };
                            navigate("/Listing", { state: data });
                          }}
                        />
                      </li>
                    ))}
                  {/* <li> <img src={process.env.PUBLIC_URL + "./img/brand_two.png"} alt="logo"  className="brandimages" /></li> */}
                </ul>
              </div>
              <div className="col-md-6 col-lg-3 padding_right">
                <ul>
                  {bannerProductData?.newBrandCollection
                    ?.slice(2, 4)
                    .map((item, index) => (
                      <li key={index}>
                        {" "}
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          alt="logo"
                          className="brandimages"
                          onClick={() => {
                            const data = {
                              mentorLatestCol: {
                                // featured_products_type:item.featured_products_type,
                                // subcategory_id: bestSellers?.subcategory_id,
                                type:
                                  item?.title_slug !== ""
                                    ? item?.title_slug
                                    : "new_brand_collection",
                              },
                            };
                            navigate("/Listing", { state: data });
                          }}
                        />
                      </li>
                    ))}
                  {/* <li> <img src={process.env.PUBLIC_URL + "./img/brand_two.png"} alt="logo"  className="brandimages" /></li> */}
                </ul>
              </div>

              <div className="col-md-12 col-lg-6 padding_left padding_right">
                <div className="brand_collection_content">
                  {bannerProductData?.newBrandCollection
                    ?.slice(4)
                    .map((item, index) => (
                      <img
                        src={IMAGE_BASE_URL + item.image}
                        alt="logo"
                        className="brand__img drop-right"
                        onClick={() => {
                          const data = {
                            mentorLatestCol: {
                              // featured_products_type:item.featured_products_type,
                              // subcategory_id: bestSellers?.subcategory_id,
                              type:
                                item?.title_slug !== ""
                                  ? item?.title_slug
                                  : "new_brand_collection",
                            },
                          };
                          navigate("/Listing", { state: data });
                        }}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="best_seller pb-0" id="best_seller_home">
        <div className="container">
            <div className="row">
                <div className="col-md-6 featuered_product">
                <h1 className="common_heading bestseller">Our Bestsellers</h1>
            <p className="common_text">The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                </div>
                {/* bannerProductData?.winterCollection?.length > 0
      ? bannerProductData?.winterCollection[0].title */}
            <div className="col-md-6">
              <div class="shop_now_seller">
                <button
                  onClick={() => {
                    const data = {
                      mentorLatestCol: {
                        // subcategory_id: bestSellers?.subcategory_id,
                        type:
                          bannerProductData?.bestSellers?.length > 0
                            ? bannerProductData?.bestSellers[0].home_slug
                            : "",
                      },
                    };
                    navigate("/Listing", { state: data });
                  }}
                >
                  Shop Now
                </button>
              </div>
            </div>
          </div>

          {/* <div
            className="row"
            onClick={() => {
              const data = {
                mentorLatestCol: {
                  // subcategory_id: bestSellers?.subcategory_id,
                  type:
                    bannerProductData?.bestSellers?.length > 0
                      ? bannerProductData?.bestSellers[0].home_slug
                      : "",
                },
              };
              navigate("/Listing", { state: data });
            }}
          > */}
            <Bestseller bestseller={brandBannerProductData?.bestSellers}></Bestseller>
          {/* </div> */}
        </div>
      </div>
      <div className="sportking">
        <div className="container">
          <div className="row">
            <div className="heading_home">
              {/* <h1 className="common_heading">#sportkingindia</h1> */}
              <p className="common_text">
                Opt outside with <Link to="#">@sportkingindia</Link> and members
                like you on Instagram.
              </p>
            </div>

            <div className="col-md-12">
              <Sportking></Sportking>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
  );
};
export default Home;
