import Footer from '../component/Footer'
import Header from '../component/Header'
import Dashboard_sidebar from './Dashboard_sidebar'
import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import './maindashboard.css'
import { IMAGE_BASE_URL } from '../Config/constant'
import { Store } from '../Redux/store'
import { addToCart, removeWishlist } from '../Controller/commonController'
import ThankYoupopup from '../component/PopupModals/thankYoupopup'
import { getSessionForLoginUser } from '../utils/helper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Whishlist = () => {
  const { wishlistData, getCart, wishlist } = useContext(Store)
  console.log(wishlistData,"wishlistData")
  const navigate = useNavigate()
  const User = getSessionForLoginUser('User')
  const [showInfoProductImg, setShowInfoProductImg] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [content, setContent] = useState('')
  const [selectedColor, setSelectedColor] = useState(null)
  const [productDetailsSize, setProductDetailsSize] = useState(null)

  const handleColorSelect = (productId, colorId, color) => {
    const temp = {
      productId: productId,
      colorId: colorId
    }
    setSelectedColor(temp)
    setProductDetailsSize(color)
  }
  const handle_MouseOver = () => {
    // Set a different src for work-thumb on mouse hover
    setThumbSrc(process.env.PUBLIC_URL + './img/slider_large_img.png')
    setShowInfoProductImg(true)
  }

  const handleMouseLeave = () => {
    // Set back the original src on mouse leave
    setThumbSrc(process.env.PUBLIC_URL + './img/mentor_sliding_imgs.png')
    setShowInfoProductImg(false)
  }

  // on color click change images
  const [thumbSrc, setThumbSrc] = useState(
    process.env.PUBLIC_URL + './img/mentor_sliding_imgs.png'
  )

  const handleMouseOver = event => {
    const newSrc = event.target.dataset.thumb
    setThumbSrc(newSrc)
  }
  const [toastShown, setToastShown] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleRemoveWishlist = async item => {

    try {
      const data = {
        product_id: item?.product_id?._id??item._id
      }
      const response = await removeWishlist(data)
      if (response) {
        setContent({ h1: 'Item removed from wishlist.', p: null ,h7:"Ok"})

        if (!toastShown) {
          toast('Item removed from wishlist');
          setToastShown(true);  // Set the toastShown state to true
        }
        // setShowPopup(true)
        wishlist()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddtoCart = async item => {
    try {
      const data = {
        color_name: item.product_color,
        selling_price: item.product_price,
        product_image: item.product_image
      }
      const response = await addToCart(item.product_id, item.product_size, data)
      if (response) {
        const data = {
          product_id: item.product_id._id
        }
        await removeWishlist(data)
        wishlist()
        setContent({ h1: 'Added to Cart', p: null,h4:"Proceed To Checkout",Link:"/add_new_address" })
        toast.success('Added to Cart');
        // setShowPopup(true)
        getCart()
      }
    } catch (error) {
      console.error('Error adding to cart:', error)
      // Handle error here, e.g., show error message
    }
  }
useEffect(()=>{
if(!(wishlistData?.length > 0)){
  navigate('/')
}

},[wishlistData])
  return (
    <>
      <Header></Header>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='dashbaord'>
              <h1>my Account</h1>
              <img
                src={process.env.PUBLIC_URL + './img/address_line.svg'}
                alt='logo'
              />
              <br></br>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and <br></br>{' '}
                typesetting industry. Lorem Ipsum has been the industry's.
              </p> */}
              <button onClick={()=>{User?navigate('/Myprofile'):navigate('/Listing')}}>Shop Now</button>
            {/* {errorLogin?<p style={{color:"red"}}>{errorLogin}</p>:''} */}
            </div>
          </div>
        </div>

        <div className='mb-5'>
          <div className='container'>
            <div className='row'>
            {User? 
              <div className='col-md-3'>
          
                <Dashboard_sidebar></Dashboard_sidebar>
            
              </div>
                :''}
              <div className='col-md-9'>
                <div className='profile_detail'>
                  <h1>Wishlist Products</h1>
                  <p>
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                  </p>
                </div>

                <div className='product-listing '>
                  <div className='mt-3'>
                    <div className='row'>
                      {/* <div className="col-md-4">

                                                <div className='best_seller_slider'>
                                                    <div className="thumbnail-img"
                                                        onMouseOver={handle_MouseOver} onMouseLeave={handleMouseLeave}
                                                        style={{ cursor: showInfoProductImg ? 'pointer' : 'default' }}>
                                                        <Link to="/Product_detail">
                                                            <img id="work-thumb" src={thumbSrc} alt="logo" className='products_img' />
                                                            {showInfoProductImg && (
                                                                <img src={process.env.PUBLIC_URL + "./img/slider_large_img.png"} className="info-product-img" alt="product" />
                                                            )}
                                                        </Link>

                                                    </div>
                                                    <div className='social_icon'>
                                                    <button id="edit">   <FontAwesomeIcon icon={faTrash}  /></button>
                                                        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo" />
                                                    </div>
                                                    <div className='color_box'>
                                                        <ul>
                                                            <li data-thumb={process.env.PUBLIC_URL + "./img/slider_large_img.png"} onClick={handleMouseOver}></li>
                                                            <li data-thumb={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} onClick={handleMouseOver}></li>
                                                            {/* <li data-thumb="https://via.placeholder.com/150x100/0000ff" onMouseOver={handleMouseOver}></li> */}
                      {/* </ul>
                                                        <p>( Green )</p>
                                                    </div>
                                                    <p className='sportking_seller'>sportking  |  Mentor Addition</p>
                                                    <p className='besiser_down'>Beiser Short Down Jacket</p>
                                                    <h1 className='price'>₹2,075.00</h1>
                                                </div>

                                            </div>  */}

                      {wishlistData.length > 0 &&
                        wishlistData?.map((item, index) => (
                          <div className='col-md-4' key={index}>
                            <div
                              className='best_seller_slider ' 
                              //   onClick={() => {
                              //     const data =
                              //       productDetailsSize ??
                              //       item?.product_id?.color_size_details[0];
                              //     const temp = item?.product_id;
                              //     navigate("/Product_detail", {
                              //       state: { temp, data },
                              //     });
                              //   }}
                            >
                              <img
                              className='whistlist_img'
                                src={
                                  IMAGE_BASE_URL +
                                  (item?.product_id?.color_size_details &&
                                  item.product_id.color_size_details.length >
                                    0 &&
                                  item.product_id.color_size_details[0]
                                    .images &&
                                  item.product_id.color_size_details[0].images
                                    .length > 0
                                    ? item.product_id.color_size_details[0]
                                        .images[0]
                                    : item?.product_image?item?.product_image:"")
                                }
                                alt='logo'
                              />
                              <div className='social_icon'>
                                <button id='edit'>
                                  {' '}
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => {
                                      handleRemoveWishlist(item)
                                    }}
                                  />
                                </button>

                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    './img/solar_bag-3-linear.svg'
                                  }
                                  alt='logo'
                                  onClick={() => {
                                    handleAddtoCart(item)
                                  }}
                                />
                              </div>
                              <div className='color_box'>
                                <ul>
                                  {item?.product_id?.color_size_details?.map(
                                    (color, colorIndex) => (
                                      <div
                                        key={colorIndex}
                                        className='color-square cursor-pointer '
                                        style={{
                                          backgroundColor: color.color_code,
                                          border:
                                            selectedColor !== null &&
                                            color &&
                                            color?._id ===
                                              selectedColor.colorId &&
                                            item._id === selectedColor.productId
                                              ? '1px solid black' // Apply black border for the selected color
                                              : colorIndex === 0 &&
                                                item._id !==
                                                  selectedColor?.productId
                                              ? '1px solid black' // Apply red border for the default color at index 0
                                              : 'none' // Apply no border for other colors
                                        }}
                                        onClick={() =>
                                          handleColorSelect(
                                            item._id,
                                            color._id,
                                            color
                                          )
                                        }
                                      ></div>
                                    )
                                  )}
                                  {/* <li data-thumb="https://via.placeholder.com/150x100/0000ff" onMouseOver={handleMouseOver}></li> */}
                                </ul>
                                <p>
                                  {/* (
                                  {item?.product_id?.color_size_details
                                    ?.map((color) => color.color_name)
                                    .join(" , ")}
                                  ) */}
                                  ({item.product_color})
                                </p>
                              </div>
                              <p className='sportking_seller'>
                                {item?.product_id?.product_title??item?.product_title}
                              </p>
                              <p className='besiser_down'>
                                {item?.product_id?.product_name??item?.product_name}
                              </p>
                              <h1 className='price'>
                                ₹{' '}{item.product_price}
                                {/* {item?.product_id?.color_size_details?.length >
                                  0 ?
                                  item?.product_id?.color_size_details[0]
                                    .selling_price:item?.product_price} */}
                              </h1>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div className="footer_top">
      <Footer ></Footer>
      </div>
      {/* <ThankYoupopup isOpen={showPopup} content={content} /> */}
      <ToastContainer />  
    </>
  )
}

export default Whishlist
