import Header from "../component/Header";
import Footer from "../component/Footer";
import Mentor_addition_slider from "./Mentor_addtion_slider";
import "./Listing.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { post_Request } from "../Controller/Home_controller";
import { IMAGE_BASE_URL } from "../Config/constant";
import { Skeleton } from "antd";
import { Store } from "../Redux/store";
import {
  addToCart,
  addWishlist,
  removeWishlist,
} from "../Controller/commonController";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiHeart } from "react-icons/ci";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import { post_RequestSearch } from "../Controller/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Listing = () => {
  // on hover change img
  const { homeBanner, getCart, wishlist, wishlistData } = useContext(Store);
  const { state } = useLocation();
  const [content, setContent] = useState("");
  const [showSizepopup, setShowSizepopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [currentpage, set_current] = useState(1);
  const [totalpage, set_totalpage] = useState(10);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [productSieColor, setProductSieColor] = useState(null);
  const [tempData, setTempData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const images_url = "https://sportking.s3.eu-north-1.amazonaws.com/image/";
  const handleClose = () => {
    setShowModal(false);
    setShowSizepopup(false);
    // handleNavigate();
    getCart();
    setSelectedSize(null);
    setError("");
  };

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (selectedSize === null) {
      setError("Please Select Size");
    } else {
      setSelectedSize(null);
      if (tempData?._id) {
        handleWishlist(tempData?._id);
        setShowSizepopup(false)
        return
      }
      handleCart();
      setShowSizepopup(false);
    }
  };
  const [showInfoProductImg, setShowInfoProductImg] = useState(false);
  const [productListData, setProductListData] = useState(state?.responseData??"");
  const [colorIndex, setColorIndex] = useState("");
  const [filtrationData, setFitrationData] = useState("");
  const [productBanner, setProductBanner] = useState("");
  const handle_MouseOver = () => {
    // Set a different src for work-thumb on mouse hover
    setThumbSrc(process.env.PUBLIC_URL + "./img/slider_large_img.png");
    setShowInfoProductImg(true);
  };
  const handleMouseLeave = () => {
    // Set back the original src on mouse leave
    setThumbSrc(process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png");
    setShowInfoProductImg(false);
  };

  // on color click change images
  const [thumbSrc, setThumbSrc] = useState(
    process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"
  );

  const handleMouseOver = (event) => {
    const newSrc = event.target.dataset.thumb;
    setThumbSrc(newSrc);
  };

  // sticky dropdown_collections

  const [isSticky, setIsSticky] = useState(false);

  const getFiltrationData = async () => {
    try {
      const endpoints = [
        ...(state?.KEY === "HeadCategory" ? [] : ["/common/get-categories"]),
        "/common/get-Colors",
        "/common/get-Sizes",
        "/common/get-Collections",
        ...(state?.KEY === "HeadCategory" ? [] : ["/common/get-gender"]),
      ];

      const allData = [];

      await Promise.all(
        endpoints.map(async (url) => {
          try {
            const { status, result } = await post_Request({
              url: url,
              postData: { header_show: "Yes" },
            });
            if (status) {
              allData.push(result);
            }
          } catch (error) {
            console.error(`Error fetching data:`, error);
          }
        })
      );

      // Set all fetched data at once
      setFitrationData(allData);
    } catch (error) {
      console.error("Error in fetching data:", error);
    }
  };

  const getProducts = async () => {
    try {
      const options = {
        url: "/common/get-products",
        postData: {
          condition: {
            status: "A",
            ...(state?.mentorLatestCol
              ? {
                  subcategory_id: state.mentorLatestCol.subcategory_id,
                  [state?.mentorLatestCol.type]: "Yes",
                  ...(state?.mentorLatestCol.featured_products_type
                    ? {
                        featured_products_type:
                          state.mentorLatestCol.featured_products_type,
                      }
                    : {}),
                }
              : {}),

            ...(state?.gender_id ? { gender_id: state.gender_id } : {}),
            ...(state?.brand ? { subcategory_slug: state.brand } : {}),
            ...(state?.product_collection_id
              ? { product_collection_id: state.product_collection_id }
              : {}),
            ...(state?.category_id ? { category_id: state.category_id } : {}),
            ...(state?.category_id && state?.subcategory_id && state?._id ? { subcategory_id: state?._id } : {}),
            // [state._id ? 'subcategory_id':state.featuered_product?'featured_products_type':state]: state._id ? state._id:state.featuered_product?state.featuered_product:'Yes'
          },
          limit: 24,
          page: 1,
        },
      };
      await post_Request(options).then((response) => {
        setProductListData(response.result.productData);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
      getFiltrationData();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state?.searched) {
          setProductListData(state?.responseData)
          window.scrollTo(0, 0);
      return;
    }
    
  }, [productListData,state]);

  useEffect(() => {
    if (!state?.searched) {
    getProducts()
    }
  }, [state]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [productDetailsSize, setProductDetailsSize] = useState(null);

  const handleColorSelect = (productId, colorId, color, colorIndex) => {
    setColorIndex((prevState) => ({
      ...prevState,
      productId: productId,
      index: colorIndex,
    }));

    const temp = {
      productId: productId,
      colorId: colorId,
    };
    setSelectedColor(temp);
    setProductDetailsSize(color);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCart = async (productDetails, data) => {
    setProductSieColor(data);
    if (selectedSize === null) {
      const temp = { productDetails, data };
      setTempData(temp);
      setShowSizepopup(true);
    } else {
      try {
        const response = await addToCart(
          productDetails || tempData.productDetails,
          selectedSize,
          productSieColor
        );
        if (response) {
          setContent({ h1: "Added to Cart", p: null });
          // setShowPopup(true);
          toast.success("Added to Cart");
          getCart();
          setSelectedSize(null);
          setError("");
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
        // Handle error here, e.g., show error message
      }
    }
  };
  // wishlist
  const handleWishlist = async (id) => {
    if (selectedSize === null) {
      setError("Please Select Size");
      return;
    }
    const response = await addWishlist(id, productSieColor, selectedSize);
    if (response.status === true) {
      wishlist();
      setContent({ h1: "Added to Wishlist", p: null, h7: "Ok" });
      // setShowPopup(true);
      toast.success("Added to Wishlist!");
    }
  };

  const handleRemoveWishlist = async (item) => {
    try {
      const data = {
        product_id: item._id,
      };
      const response = await removeWishlist(data);
      if (response) {
        setContent({ h1: "Item removed from wishlist.", p: null, h7: "Ok" });
        // setShowPopup(true)
        toast("Item removed from wishlist");
        wishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleHeartClick = (item, data, status) => {
    if (status === true) {
      handleRemoveWishlist(item);
      return;
    }
    setProductSieColor(data);
    setTempData(item);
    if (selectedSize === null) {
      // setError("Please Select Size");
      setShowSizepopup(true);
      return;
    }
    handleWishlist(item._id);
  };
  // end wishlist
  const [filtrationCriteria, setFiltrationCriteria] = useState("");

  const handleSelectChange = (event, item) => {
    const categories_id = item?.result?.sub_caterogies;
    const temp = categories_id.find((item) => item.name === event.target.value);

    const selectedValue = event.target.value;
    const selectedCategory = item.result.category;
    let selectedKey = selectedCategory.toLowerCase(); // Convert category to lowercase

    // Adjust key if needed
    if (
      selectedKey === "category" ||
      selectedKey === "collection" ||
      selectedKey === "gender"
    ) {
      selectedKey =
        selectedKey === "category"
          ? "category_id"
          : selectedKey === "collection"
          ? "product_collection_id"
          : selectedKey === "gender"
          ? "gender_id"
          : selectedKey;
      const categoryId = temp?._id;
      setFiltrationCriteria((prevCriteria) => ({
        ...prevCriteria,
        [selectedKey]: categoryId,
      }));
    } else if (selectedKey === "color" || selectedKey === "size") {
      selectedKey = selectedKey === "color" ? "color_name" : selectedKey;
      setFiltrationCriteria((prevCriteria) => ({
        ...prevCriteria,
        [selectedKey]: selectedValue !== "" ? selectedValue : null,
      }));
    }

    // Remove the field if the first option is selected
    if (event.target.selectedIndex === 0) {
      setFiltrationCriteria((prevCriteria) => {
        const { [selectedKey]: deletedKey, ...rest } = prevCriteria;
        return rest;
      });
    }
  };
  // Filtration criteria
  const filteredProducts =
    productListData?.length > 0 &&
    productListData.filter((product) => {
      // Check if all filtration criteria match the product
      return Object.keys(filtrationCriteria).every((key) => {
        // Handle special case for color_name and size filtering
        if (key === "color_name" || key === "size") {
          // Check if any color_name or size matches
          if (key === "color_name") {
            return product.color_size_details.some(
              (detail) => detail[key] === filtrationCriteria[key]
            );
          } else {
            return product.color_size_details.some((detail) =>
              // console.log(detail,"details")
              detail.sizes.some(
                (sizeDetail) => sizeDetail.size === filtrationCriteria.size
              )
            );
          }
        } else {
          // Check if other criteria match
          return product[key] === filtrationCriteria[key];
        }
      });
    });
  // Use the filteredProducts as needed

  // pagination
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalpage) {
      set_current(pageNumber);
    }
  };

  const handlePrevPage = () => {
    if (currentpage > 1) {
      handlePageChange(currentpage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentpage < totalpage) {
      handlePageChange(currentpage + 1);
    }
  };

  // end

  const isProductInWishlist = (productId) => {
    return (
      (wishlistData &&
        wishlistData?.some((item) => item?.product_id?._id === productId)) ||
      false
    );
  };

  const handleSerach = async () => {
    const key = "Women";
    try {
      const options = {
        url: `/common/search?productName=${key}`,
      };
      const response = await post_RequestSearch(options);

      const responseData = response.result.data;
      if (responseData.length > 0) {
        navigate("/Listing", {
          state: { responseData: responseData, searched: true },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [showNoDataMessage, setShowNoDataMessage] = useState('');

  useEffect(() => {
    setProductListData(null)
    setFitrationData(null)
    const timer = setTimeout(() => {

        setShowNoDataMessage(true);

    
    }, 3000);

    // Cleanup the timer if the component is unmounted before 4 seconds
    return () => clearTimeout(timer);
  }, []);

  function createSlug(name) {
    // Step 1: Replace spaces with a placeholder (e.g., a temporary character that won't interfere)
    const placeholder = '_placeholder_';
    const interim = name
      .toLowerCase()                    // Convert to lowercase
      .replace(/ /g, placeholder)       // Replace spaces with a placeholder
      .replace(/-/g, '--')              // Replace single hyphens with double hyphens
      .replace(new RegExp(placeholder, 'g'), '-') // Replace the placeholder with single hyphens
      // .replace(/[^a-z0-9-]+/g, '')      // Remove all non-alphanumeric characters except hyphens
      .trim();                          // Trim leading and trailing spaces
  
    return interim;
  }
  
  // Example Usage
  console.log(createSlug("Printed L.Grey Co-Ord Set")); // "printed-l--grey-co--ord-set"
  
  const desiredOrder = ["S", "M", "L", "XL", "XXL"]; // Define the desired order

const sortedSizes = productSieColor?.sizes.sort((a, b) => {
  const indexA = desiredOrder.indexOf(a.size);
  const indexB = desiredOrder.indexOf(b.size);

  // Sort based on the desired order if present, otherwise natural order
  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  } else if (indexA !== -1) {
    return -1;
  } else if (indexB !== -1) {
    return 1;
  } else {
    return a.size.localeCompare(b.size); // Natural order for sizes not in desiredOrder
  }
});

  return (
    <>
      <Header />
      <div className="listing-main">
        <div className="container">
          <div className="row ">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h1
                dangerouslySetInnerHTML={{
                  __html:
                    homeBanner?.listBannerData &&
                    homeBanner.listBannerData.length > 0
                      ? homeBanner.listBannerData[0]?.title.replace(
                          /\n/g,
                          "<br/>"
                        )
                      : "",
                }}
              ></h1>

              {/* <h1>Women Latest <br></br>Collection</h1> */}
              {/* <div className="slider_lines"></div> */}
              {/* <p>{homeBanner?.listBannerData && homeBanner?.listBannerData[0]?.description}</p> */}
              {/* <button onClick={handleSerach}>
                {homeBanner?.listBannerData &&
                  homeBanner?.listBannerData[0]?.button_title}
              </button> */}
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>

      <div className="drop_down_listing">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className={`drop_down_collection ${isSticky ? "sticky" : ""}`}
              >
                {filtrationData &&
                  filtrationData?.map((item, index) => (
                    <>
                      <select
                        key={index}
                        onChange={(e) => handleSelectChange(e, item)}
                      >
                        <option value="">{item?.result?.category}</option>
                        {item?.result?.sub_caterogies.map((InsideItem, index) => (
                          <option key={index}>{InsideItem.name}</option>
                        ))}
                      </select>
                      <img
                        src={process.env.PUBLIC_URL + "./img/straight_line.png"}
                        alt="logo"
                      />
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-listing ">
        <div className="container">
          <div className="row">
            {productListData?.length > 0 ? (
              filteredProducts?.length > 0 ? (
                filteredProducts.slice(0, 12).map((item, index) => (
                  <div className="col-md-6 col-lg-3  col-6 " key={index} >
                    <div className="best_seller_slider">
                      <div
                        className="thumbnail-img"
                        style={{
                          cursor: showInfoProductImg ? "pointer" : "default",
                        }}
                      >
                        <img
                          id="work-thumb"
                          src={
                            IMAGE_BASE_URL +
                            (colorIndex?.productId === item._id
                              ? item.color_size_details[colorIndex.index]
                                  .images[0]
                              : item.color_size_details[0].images[0])
                          }
                          alt="logo"
                          className="products_img cursor-pointer"
                          onClick={() => {
                            const data =
                              productDetailsSize ?? item.color_size_details[0];
                              const decodedSlug = createSlug(item.product_name);
                            navigate(`/Product_detail/${decodedSlug}`, {
                              state: { item, data },
                            });
                          }}
                          style={{minHeight:"240px"}}
                        />
                        {showInfoProductImg && (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./img/slider_large_img.png"
                            }
                            className="info-product-img"
                            alt="product"
                          />
                        )}
                      </div>
                      <div className="social_icon">
                        <div className="select_product heart">
                          {isProductInWishlist(item._id) ? (
                            <FontAwesomeIcon
                              icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                              style={{
                                color: "#df1b22",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const data =
                                  productDetailsSize ??
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          ) : (
                            <CiHeart
                              className="heart_icon profile_header"
                              onClick={() => {
                                const data =
                                  productDetailsSize ??
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          )}
                        </div>
                        <img
                          src={process.env.PUBLIC_URL + "./img/solar_bag.svg"}
                          alt="logo"
                          onClick={() => {
                            const data =
                              productDetailsSize ?? item.color_size_details[0];
                            handleCart(item, data);
                          }}
                        />
                      </div>
                      <div className="color_box">
                        <ul>
                          {item.color_size_details.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="color-square cursor-pointer "
                              style={{
                                backgroundColor: color.color_code,
                                border:
                                  selectedColor !== null &&
                                  color &&
                                  color?._id === selectedColor.colorId &&
                                  item._id === selectedColor.productId
                                    ? "1px solid black"
                                    : colorIndex === 0 &&
                                      item._id !== selectedColor?.productId
                                    ? "1px solid black"
                                    : "none",
                              }}
                              onClick={() =>
                                handleColorSelect(
                                  item._id,
                                  color._id,
                                  color,
                                  colorIndex
                                )
                              }
                            ></div>
                          ))}
                        </ul>
                        <p>
                          (
                          {item.color_size_details.map((color, index) => {
                            const capitalizedColorName = color.color_name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ");

                            return index === 0
                              ? capitalizedColorName
                              : `,${capitalizedColorName}`;
                          })}
                          )
                        </p>
                      </div>
                      <p className="sportking_seller">{item.product_title}</p>
                      <p className="besiser_down">{item.product_name}</p>
                      <h1 className="price">
                        ₹
                        {colorIndex.productId === item._id
                          ? item.color_size_details[colorIndex.index]
                              .sizes[0]?.selling_price
                          : item.color_size_details[0].sizes[0]?.selling_price}
                      </h1>
                    </div>
                  </div>
                ))
              ) : (
                <div className="data-match">
                  <p>No Data Matches</p>
                </div>
              )
            ) : (
              showNoDataMessage ? (
                // Show "No data available" message after 4 seconds
                <div className="data-match"><p> No data available </p></div>
              ) :  (
              Array.from({ length: 10 }, (_, i) => (
                <div className="col-md-3" key={i}>
                  <Skeleton.Avatar
                    size={250}
                    shape="square"
                    active
                    style={{ height: "250px" }}
                  />
                  <Skeleton className="mt-3" paragraph={{ rows: 2 }} />
                </div>
             ))
            )
          )}
          </div>
        </div>
      </div>
      {!showNoDataMessage ?
      <div className="mentor_sliding  listling_slider py-5" id={"section1"}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="heading">
                {productListData?.length > 0 && productListData[0].product_title}
              </p>
              <h1>
                {productListData?.length > 0 && productListData[0].product_name}
              </h1>
              <p className="heading">
                ₹{" "}
                {productListData?.length > 0 &&
                  productListData[0].color_size_details[0]?.sizes[0]?.selling_price}
              </p>
            </div>
          </div>
        </div>

        <div className="mentor_sliding_container profile_header mt-4">
          <Mentor_addition_slider
            product={
              productListData?.length > 0 &&
              productListData[0].color_size_details
            }
            details={productListData?.length > 0 && productListData[0]}
            handleCart={handleCart}
            handleHeartClick={handleHeartClick}
            isProductInWishlist={isProductInWishlist}
          />
        </div>
      </div>
 :''} 
      {/* // filtering by product list */}
      <div className="product-listing ">
        <div className="container">
          <div className="row">
            {productListData?.length > 0 ? (
              filteredProducts.length > 0 ? (
                filteredProducts.slice(12).map((item, index) => (
                  <div className="col-md-6 col-lg-3 col-6" key={index}>
                    <div className="best_seller_slider">
                      <div
                        className="thumbnail-img"
                        style={{
                          cursor: showInfoProductImg ? "pointer" : "default",
                        }}
                      >
                        <img
                          id="work-thumb"
                          src={
                            IMAGE_BASE_URL +
                            (colorIndex?.productId === item._id
                              ? item.color_size_details[colorIndex.index]
                                  .images[0]
                              : item.color_size_details[0].images[0])
                          }
                          alt="logo"
                          className="products_img cursor-pointer"
                          onClick={() => {
                            const data =
                              productDetailsSize ?? item.color_size_details[0];
                              const decodedSlug = createSlug(item.product_name);
                            navigate(`/Product_detail/${decodedSlug}`, {
                              state: { item, data },
                            });
                          }}
                        />
                        {showInfoProductImg && (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./img/slider_large_img.png"
                            }
                            className="info-product-img"
                            alt="product"
                          />
                        )}
                      </div>
                      <div className="social_icon">
                        <div className="select_product heart">
                          {isProductInWishlist(item._id) ? (
                            <FontAwesomeIcon
                              icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                              style={{
                                color: "#df1b22",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const data =
                                  productDetailsSize ??
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          ) : (
                            <CiHeart
                              className="heart_icon profile_header"
                              onClick={() => {
                                const data =
                                  productDetailsSize ??
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          )}
                        </div>
                        <img
                          src={process.env.PUBLIC_URL + "./img/solar_bag.svg"}
                          alt="logo"
                          onClick={() => {
                            const data =
                              productDetailsSize ?? item.color_size_details[0];
                            handleCart(item, data);
                          }}
                        />
                      </div>
                      <div className="color_box">
                        <ul>
                          {item.color_size_details.map((color, colorIndex) => (
                            <div
                              key={colorIndex}
                              className="color-square cursor-pointer "
                              style={{
                                backgroundColor: color.color_code,
                                border:
                                  selectedColor !== null &&
                                  color &&
                                  color?._id === selectedColor.colorId &&
                                  item._id === selectedColor.productId
                                    ? "1px solid black"
                                    : colorIndex === 0 &&
                                      item._id !== selectedColor?.productId
                                    ? "1px solid black"
                                    : "none",
                              }}
                              onClick={() =>
                                handleColorSelect(
                                  item._id,
                                  color._id,
                                  color,
                                  colorIndex
                                )
                              }
                            ></div>
                          ))}
                        </ul>
                        <p>
                          (
                          {item.color_size_details.map((color, index) => {
                            const capitalizedColorName = color.color_name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ");

                            return index === 0
                              ? capitalizedColorName
                              : `,${capitalizedColorName}`;
                          })}
                          )
                        </p>
                      </div>
                      <p className="sportking_seller">{item.product_title}</p>
                      <p className="besiser_down">{item.product_name}</p>
                      <h1 className="price">
                        ₹
                        {colorIndex.productId === item._id
                          ? item.color_size_details[colorIndex.index]
                              .sizes[0]?.selling_price
                          : item.color_size_details[0].sizes[0]?.selling_price}
                      </h1>
                    </div>
                  </div>
                ))
              ) : (
                <div className="data-match">
                  {/* <p>No Data Matches</p> */}
                </div>
              )
            ) : 
            ( ''
              // Array.from({ length: 10 }, (_, i) => (
              //   <div className="col-md-3" key={i}>
              //     <Skeleton.Avatar
              //       size={250}
              //       shape="square"
              //       active
              //       style={{ height: "250px" }}
              //     />
              //     <Skeleton className="mt-3" paragraph={{ rows: 2 }} />
              //   </div>
              // ))
            )
            }
          </div>
        </div>
      </div>

      <div className="footer_top">
        <Footer></Footer>
      </div>

      <Modal
        show={showSizepopup}
        onHide={handleClose}
        id="feedback_popup"
        className="select-size"
        centered
      >
        <div className="cross py-0">
          <img
            src={`${images_url}ball_icon.svg`}
            alt="logo"
            className="model_logo"
          />

          <img
            src={`${images_url}cross.svg`}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div>
          <h1>Please Select a Size</h1>
          <div className="product-size">
          <ul>
    {productSieColor &&
      sortedSizes.map((size, index) => (
        (size.productshow === 'true' || size.productshow === true) ? (
          <li
            key={index}
            className={`cursor-pointer ${size.selling_price === '0' ? 'blurred' : ''}`}
            style={{
              border: selectedSize === size.size ? "2px solid black" : "",
            }}
            onClick={() => {
              setSelectedSize(size.size);
              setProductSieColor(prev => ({ ...prev, selling_price: size.selling_price }));
            }}
          >
            {size.size} {" "} (₹ {size.selling_price})
          </li>
        ) : null
      ))}
  </ul>
            {error ? (
              <p className="error_Validtaion" style={{ color: "red" }}>
                {error}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {content.p === !null ? (
          <Modal.Body className="py-0">
            <div className="thank_you_popup">
              <div className="row">
                <div className="col-md-4">
                  <h2>10%</h2>
                  <p className="discount_voucher">Discount Voucher</p>
                </div>
                <div className="col-md-8 px-0">
                  <h2 className="thank_you_heading"> Thank you</h2>
                  <p className="inner_content">
                    {" "}
                    for ordering from sportking Use code <span>
                      ”THANKS”
                    </span>{" "}
                    at checkout for 10% discount.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          ""
        )}
        <div className="model_footer">
          <button className="view_cart" onClick={handleNavigate}>
            {content?.h3 ? "View my orders" : "Proceed To Next"}
          </button>
        </div>
      </Modal>
      <ThankYoupopup isOpen={showPopup} content={content} />
      <ToastContainer />
    </>
  );
};

export default Listing;
