import Header from "../component/Header";
import Footer from "../component/Footer";
import "./cart.css";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect, useRef } from "react";
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import { post_Request } from "../Controller/Home_controller";
import { getSessionForLoginUser } from "../utils/helper";

const Cart = () => {
  const { cart, getCart, updateCartItemQuantity, subtotal } = useContext(Store);
  const User = getSessionForLoginUser("User");
  const [errorMsgCp, seterrorMsgCp] = useState(null);
  const navigate = useNavigate("");
  const [cooupnCode, setCoupnCode] = useState("");
  const [responseOfCopuonCode, setResponseOfCopuonCode] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [showInfoProductImg, setShowInfoProductImg] = useState(false);
  const handle_MouseOver = () => {
    // Set a different src for work-thumb on mouse hover
    setThumbSrc(process.env.PUBLIC_URL + "./img/slider_large_img.png");
    setShowInfoProductImg(true);
  };

  const handleMouseLeave = () => {
    // Set back the original src on mouse leave
    setThumbSrc(process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png");
    setShowInfoProductImg(false);
  };
  const [thumbSrc, setThumbSrc] = useState(
    process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"
  );

  const handleMouseOver = (event) => {
    const newSrc = event.target.dataset.thumb;
    setThumbSrc(newSrc);
  };
  const handleIncrement = (item) => {
    updateCartItemQuantity(item, 1).then((res) => {});
    if (quantity < 20) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = (item) => {
    updateCartItemQuantity(item, -1).then((res) => {});
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  const [errorCoi, setErrorCoi] = useState(null);
  const handleChangeCode = async (e) => {
    setErrorCoi("");
    setResponseOfCopuonCode("");
    setCoupnCode(e.target.value);
  };
  const headerRef = useRef();
  const openLoginModal = () => {
    if (headerRef.current) {
      headerRef.current.openModel();
    }
    // headerRef.current.openCompanyModel();
  };
  const handleCoupon = async (code) => {
    if (!User) {
      // setErrorCoi("Please Login First !")
      openLoginModal();
    } else if (!code) {
      setErrorCoi("Please enter a coupon code");
    }
    try {
      const options = {
        url: "/users/validate-coupon",
        postData: {
          promo_code: code,
        },
      };
      const response = await post_Request(options);
      setResponseOfCopuonCode(response);
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };
  // const discountPrice=(subtotal)=>{
  const discountPercentage = parseInt(
    responseOfCopuonCode?.result?.result.discount
  );
  const discountedAmount = Math.round(
    subtotal - subtotal * (discountPercentage / 100)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header ref={headerRef} />

      <div className="container card_main_Section">
        <div className="row">
          <div className="col-md-8">
            <p className="heading">Your Shopping Cart</p>

            <hr className="heading_line"></hr>

            {/* <div className="cart_product_deatil">
                 <div className="cart_product_img">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo" />
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                 <div className="quantity">
                 <p className="product_color">Quantity : </p>
                 <div className="quantity-btn">
                    <button onClick={handleDecrement} className="symbol">-</button>
                    <button id="quantity">{quantity}</button>
                    <button onClick={handleIncrement} className="symbol">+</button>
                 </div>
                 </div>
                
                 </div>
                </div>
                <hr className="heading_line  my-4"></hr> */}
            {cart?.map((item, index) => (
              <div className="cart_product_deatil" key={index}>
                <div className="cart_product_img">
                  <img src={IMAGE_BASE_URL + item.product_image} alt="logo" />
                </div>
                <div className="total-product">
                  <h1>{item.product_name}</h1>
                  <p className="price mb-0">₹{item.price}</p>
                  <p className="product_color mb-0">Color : {item.color}</p>
                  <p className="product_color">Size : {item.size}</p>
                  <div className="quantity">
                    <p className="product_color">Quantity : </p>
                    <div className="quantity-btn">
                      <button
                        onClick={() => {
                          handleDecrement(item);
                        }}
                        className="symbol"
                      >
                        -
                      </button>
                      <button id="quantity">{item.qty}</button>
                      <button
                        onClick={() => {
                          handleIncrement(item);
                        }}
                        className="symbol"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <hr className="heading_line  my-4"></hr>
          </div>
          <div className="col-md-4">
            <p className="heading">Payment Details</p>
            <hr className="heading_line mb-3"></hr>

            <div className="d-flex coupen_code">
              <input
                type="text"
                placeholder="Apply coupon Code"
                value={cooupnCode}
                onChange={handleChangeCode}
              ></input>
              <button
                onClick={() => {
                  handleCoupon(cooupnCode);
                }}
                style={{
                  marginTop: "0px",
                  border: "none",
                  padding: "8px 20px 10px 20px",
                }}
              >
                Apply{" "}
              </button>
              <img
                src={process.env.PUBLIC_URL + "./img/coupen.svg"}
                alt="logo"
              />
            </div>
            {responseOfCopuonCode?.statusCode === "ALREADYREDEEMerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                {" "}
                Coupon has already been redeemed
              </p>
            ) : responseOfCopuonCode?.statusCode === "promocodeerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                Please enter valid promocode
              </p>
            ) : responseOfCopuonCode?.result?.result ? (
              <p style={{ color: "#008000", fontSize: "13px" }}>
                {" "}
                Coupon Applied! {
                  responseOfCopuonCode?.result?.result.discount
                }{" "}
                OFF
              </p>
            ) : (
              ""
            )}
            {errorCoi ? (
              <p style={{ color: "red", fontSize: "13px" }}>{errorCoi}</p>
            ) : (
              ""
            )}
            <div className="detail_price">
              <div className="">
                <h1>detail</h1>
                <h1>Price</h1>
              </div>

              <hr></hr>
              <div className="">
                <p>Sub Total</p>
                <p>₹ {subtotal}</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Coupon Discount</p>
                <p className="disoucnt_coupen_price">
                  <span className="discount_price">
                    {" "}
                    {responseOfCopuonCode?.result?.result.discount
                      ? discountedAmount - subtotal
                      : "- ₹ 0"}{" "}
                  </span>
                </p>
              </div>
              <hr></hr>
              <div className="">
                <p>Delivery Charges</p>
                <p>₹ 0</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Other Charges</p>
                <p>₹ 0</p>
              </div>
            </div>
            <div className="mt-3 py-2 cart_total">
              <p>Total</p>
              <p>₹ {discountedAmount ? discountedAmount : subtotal}</p>
            </div>
            {errorMsgCp ? (
              <p style={{ color: "red", fontSize: "13px" }}>{errorMsgCp}</p>
            ) : (
              ""
            )}
            <div className="proceed_checkout">
              <button
                onClick={async () => {
                  try {
                    if (
                      responseOfCopuonCode.statusCode ===
                        "ALREADYREDEEMerror" ||
                      (responseOfCopuonCode.statusCode === "promocodeerror" &&
                        cooupnCode)
                    ) {
                      seterrorMsgCp(
                        "Please use the correct coupon code or remove the erroneous coupon code."
                      );
                      return;
                    }
                    const temp = {
                      code: cooupnCode,
                      statusCode: responseOfCopuonCode,
                    };
                    navigate("/add_new_address", { state: { temp } });
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                Proceed To Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="best_seller">
        <div className="container">
            <div className="row">
                <div className="col-md-6 featuered_product">
                <h1 className="common_heading">Other Similar Products</h1>
          
                </div>

                <div className="col-md-6">
                <div class="shop_now_seller"><Link to="" id="view_all">View All</Link></div>
                </div>
            </div>

            <div className="product-listing ">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <Link to="/Product_detail">
                <div className='best_seller_slider'>
                <div className="thumbnail-img"
               onMouseOver={handle_MouseOver} onMouseLeave={handleMouseLeave}
         style={{ cursor: showInfoProductImg ? 'pointer' : 'default' }}>
           
        <img id="work-thumb" src={thumbSrc} alt="logo" className='products_img' />
        {showInfoProductImg && (
    <img src={process.env.PUBLIC_URL + "./img/slider_large_img.png"} className="info-product-img" alt="product" />
)}
     
    
    </div>
        <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo"  />
        </div>
        <div className='color_box'>
        <ul>
            <li data-thumb={process.env.PUBLIC_URL + "./img/slider_large_img.png"}   onClick={handleMouseOver}></li>
            <li data-thumb={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} onClick={handleMouseOver}></li>
        </ul>
        <p>( Green )</p>
        </div>
        <p className='sportking_seller'>sportking  |  Mentor Addition</p>
        <p className='besiser_down'>Beiser Short Down Jacket</p>
        <h1 className='price'>₹2,075.00</h1>
        </div>
        </Link>
                </div>
                <div className="col-md-3">
                <div className='best_seller_slider'>
        <img src={process.env.PUBLIC_URL + "./img/best_sellers.png"} alt="logo" className='products_img' />
        <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo"  />
        </div>
        <div className='color_box'>
        <ul>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <p>( Green )</p>
        </div>
        <p className='sportking_seller'>sportking  |  Mentor Addition</p>
        <p className='besiser_down'>Beiser Short Down Jacket</p>
        <h1 className='price'>₹2,075.00</h1>
        </div>
                </div>
                <div className="col-md-3">
                <div className='best_seller_slider'>
        <img src={process.env.PUBLIC_URL + "./img/best_sellers.png"} alt="logo" className='products_img' />
        <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo"  />
        </div>
        <div className='color_box'>
        <ul>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <p>( Green )</p>
        </div>
        <p className='sportking_seller'>sportking  |  Mentor Addition</p>
        <p className='besiser_down'>Beiser Short Down Jacket</p>
        <h1 className='price'>₹2,075.00</h1>
        </div>
                </div>
                <div className="col-md-3">
                <div className='best_seller_slider'>
        <img src={process.env.PUBLIC_URL + "./img/best_sellers.png"} alt="logo" className='products_img' />
        <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo"  />
        </div>
        <div className='color_box'>
        <ul>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <p>( Green )</p>
        </div>
        <p className='sportking_seller'>sportking  |  Mentor Addition</p>
        <p className='besiser_down'>Beiser Short Down Jacket</p>
        <h1 className='price'>₹2,075.00</h1>
        </div>
                </div>
            </div>
        </div>
     </div>
        </div>
       </div> */}
      <div className="footer_top">
        <Footer></Footer>
      </div>
    </>
  );
};

export default Cart;
