import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { IMAGE_BASE_URL } from "../Config/constant";
import Zoom from "./Zoom";
const Customer_slider = ({ productSieColor }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //autoplay: true,
    autoplaySpeed: 2000,
    vertical: true,
    touchMove: true,
    verticalSwiping: true,
    responsive: [
      {
          breakpoint: 1024, 
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 768, 
          settings: {
              slidesToShow: 1,
              centerMode: false,
              slidesToScroll: 1
          }
      }
    
  ]
  };

  return (
    <div className="best_selleres" id="customer_slider">
    <Slider {...settings}>
      {productSieColor?.images?.map((image, index) => (
        <div key={index} className="best_seller_slider">
          <Zoom imgSrc={IMAGE_BASE_URL + image} />
        </div>
      ))}
    </Slider>
  </div>
  );
};

export default Customer_slider;
