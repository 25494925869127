import Header from "../component/Header";
import Footer from "../component/Footer";
import "./maindashboard.css";
import Dashboard_sidebar from "./Dashboard_sidebar";
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import animationData from './tick.json';
import Lottie from 'lottie-react';
import { post_Request } from "../Controller/Home_controller";
import { IMAGE_BASE_URL } from "../Config/constant";
import { useNavigate } from "react-router-dom";
import { getSessionForLoginUser } from "../utils/helper";

const  My_order = () => {
  const navigate=useNavigate();
  const User = getSessionForLoginUser('User');
    const [show, setShow] = useState(false);
    const [orderlist,setOrderList]=useState(null)
    const handleClose = () => {
        setShow(false);
        setreview_statment(false);
        set_cancel_confirm(false);
       set_cancel(false);
    }
    const handleShow = () =>{
      setShow(true);
    }
    // drag & drop file
    const [droppedFiles, setDroppedFiles] = useState([]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const files = Array.from(e.dataTransfer.files);

    // Update state with the dropped files
    setDroppedFiles(files);
  };

  const dropZoneStyles = {
  
    borderRadius: '4px',
    padding: '11px 20px 20px 20px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#ECECEC',
  };

  // thanks you popup

const [review_statment,setreview_statment] = useState(false);
const thank_popup = () =>{
    setreview_statment(true);
    setShow(false);
}
// cancellation  popup

const [cancel,set_cancel] = useState(false);

const cancel_event = () =>{
  set_cancel(true);
}
//
const [cancel_confirm, set_cancel_confirm] = useState(false);
const cancel_confirm_event = () =>{
  set_cancel_confirm(true);
  set_cancel(false);
}
const getOrderList=async()=>{
  try{
    const options = {
        url: "/order/get-order-list",
        postData:{
          user_id:User._id
        }
      };
      await post_Request(options).then((response)=>{
      setOrderList(response.result.result)
      })
}catch(error){
console.error(error)
}
}

useEffect(()=>{
getOrderList()
},[])
return(
    <>
    <Header></Header>
    <div className="container mt-3 mb-5">
        <div className="row">
            <div className="col-md-12">
                <div className="dashbaord">
                    <h1>my Account</h1>
                    <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" /><br></br>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br> typesetting industry. Lorem Ipsum has been the industry's.</p> */}
                    <button onClick={()=>{navigate('/Listing')}}>Shop Now</button>
                </div>

            </div>
        </div>
        <div className="row">
            <div  className="col-md-3">
            <Dashboard_sidebar></Dashboard_sidebar>
            </div>
            <div className="col-md-9">
                 {/* <div className="profile_detail">
                    <h1>My Orders</h1>
                    <p>The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>

                 </div> */}
                 {/* <div className="order-status row">
                    <div className="col-md-3">
                        <div className="order_img">
                            <div>
                            <p>+02</p>
                            </div>
                     <img src={process.env.PUBLIC_URL + "./img/product_two.png"} alt="logo" />
                            
                      
                        </div>
                      
                    </div>
                    <div className="col-md-2 padding-right">
                    <div className="product-up">
                    <span>Order Id</span>
                      <p>5615165413</p>
                    </div>
                   

                       <div className="product-up">
                       <span>Amount</span>
                      <p>₹ 20,000</p>
                       </div>
                     
                    </div>
                    <div className="col-md-4 padding-right">
                    <div className="product-up">
                    <span>Order Date</span>
                    <p>Saturday, 29 Oct, 2023</p>
                    </div>
                        
                    <div className="product-up">
                       <span>Payment Status</span>
                      <p>COD ( Cash on Delivery )</p>
                       </div>

                    </div>
                    <div className="col-md-3 padding-right">
                    <div className="product-up">
                    <span>Delivery Date</span>
                    <p>Saturday, 29 Oct, 2023</p>
                    </div>

                    <div className="product-up">
                         <span>Status</span>
                        <p id="deliver">Delivered</p>
                    </div>

                    </div>
                    
                    
                 </div> */}

                 {/* <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>View Purchased Items</Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <div className="col-md-7 col-12">
            <div className="cart_product_deatil mt-0">
                 <div className="cart_product_img" id="inner-product">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo"/>
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                
                 </div>
                </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="tracking_order">
              <div id="order">
                <button >Return Order</button>
                </div>
                <div id="order">
                <button onClick={handleShow}>Feedback</button>
                </div>
              </div>
                
               
            </div>
           
          </div>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion> */}

    <Accordion defaultActiveKey="1" className="mt-3">
      <Accordion.Item eventKey="1">
        <Accordion.Header>View Purchased Items</Accordion.Header>
        <Accordion.Body>
          {orderlist &&  [...orderlist].reverse().map((orders,index)=>(
          <ul key={index} className="mb-2">
            <li id="first_section">
            <div className="cart_product_deatil mt-0">
                 <div className="cart_product_img" id="inner-product">
                 <img src={IMAGE_BASE_URL+orders.product_image} alt="logo"/>
                 </div>
                 <div className="total-product">
                 <h1>{orders.product_name}</h1>
                 <p className="price mb-0">₹{orders.price}</p>
                 <p className="product_color mb-0">Color : {orders.color}</p>
                 <p className="product_color">Size : {orders.size}</p>
                
                 </div>
                </div>
            </li>
            <li className="tracking_order">
            <div id="order">
                <button onClick={()=>{navigate('/order_details',{state:orders})}}>View Details</button>
                </div>
                <div id="order">
                <button onClick={handleShow}>Cancel Order</button>
                </div>
                
            </li>
            <li>
          
            </li>
          </ul>
                    ))}
     
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>

            </div>
     
        </div>
    </div>
    <div className="footer_top">
      <Footer ></Footer>
      </div>
    
    <Modal show={show} onHide={handleClose}  id="review_popup">
          <div className='cross py-0 '>
          <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" />
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <h1>Write A Review</h1>
        <p className='heading mb-0'>Neque porro quisquam est quite</p>
        <ul>
            <li> <FontAwesomeIcon icon={faStar} size="2x"/></li>
            <li> <FontAwesomeIcon icon={faStar} size="2x"  /></li>
            <li> <FontAwesomeIcon icon={faStar} size="2x" /></li>
            <li> <FontAwesomeIcon icon={faStar} size="2x" /></li>
            <li> <FontAwesomeIcon icon={faStar} size="2x" /></li>
           </ul>
        </div>
      
        <Modal.Body className="py-0">
        <div className="add_photo">
        <h2 className="subheading_model mx-0">Add Photo Or Video</h2>
        <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={dropZoneStyles}
    >
         <img src={process.env.PUBLIC_URL + "./img/uploading_files.svg"} alt="logo" className="model_logo" />
      <p>Click here to upload</p>
      {droppedFiles.length > 0 && (
        <div>
          <h3>Dropped files:</h3>
          <ul>
            {droppedFiles.map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
      
        </div>

        <div className="">
        <h2 className="subheading_model">Write Your Review</h2>
        <textarea className="textarea_address" placeholder="Would you like to write anything about this product?"></textarea>
        </div>
        
      
              
        </Modal.Body>
     
         <div className='model_footer'>
          <button className='view_cart' onClick={thank_popup}>Submit Review</button>
          </div> 
          
        
       
      </Modal>


      <Modal show={review_statment} onHide={handleClose}  id="review_popup">
          <div className='cross py-0'>
          <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" />
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <h1>Thank You</h1>
        <p className='heading mb-0'>Your feedback is valuable for us</p>
       
        </div>
      
        <Modal.Body className="py-0">
        <div className="thank_you_popup">
            <div className="row">
         <div className="col-md-4">
         <div className="voucher_text">
                <h2>10%</h2>
                <p className="discount_voucher">Discount Voucher</p>
                </div>
           
         </div>
         <div className="col-md-8 px-0">
            <h2  className="thank_you_heading">  Thank you</h2>
            <p className="inner_content"> for ordering from sportking Use code  <span>”THANKS”</span>   at checkout for 10% discount.</p>
       

         </div>
        </div>
        </div>
        </Modal.Body>
     
         <div className='model_footer'>
          <button className='view_cart' onClick={cancel_event}>Continue Shopping</button>
          </div> 
          
        
       
      </Modal>

      <Modal show={cancel} onHide={handleClose}  id="review_popup">
          <div className='cross py-0'>
          <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" />
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <h1>Cancellation</h1>
        <p className='heading mb-0'>Help us to understand the reason</p>
       
        </div>
      
        <Modal.Body className="py-0">
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>
         <div className="cancel_section">
            <input type="radio"></input>
            <p>Neque porro quisquam est qui</p>
         </div>

           <div className="">
        <h2 className="subheading_model">Write Your Reason</h2>
        <textarea className="textarea_address" placeholder="Write your reason here..."></textarea>
        </div>

        </Modal.Body>
     
         <div className='model_footer'>
          <div className="proceed_checkout mb-5">
          <button onClick={cancel_confirm_event}>Cancel Order</button>
          </div>
          
          </div> 
          
        
       
      </Modal>

      <Modal show={cancel_confirm} onHide={handleClose}  id="review_popup">
          <div className='cross py-0'>
          <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" />
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>
        <Lottie
  animationData={animationData}
  loop={true}
  autoplay={true}
  speed={1.5}
/>

        <h1>Cancellation Confirm</h1>
        <p className='heading mb-0'>Your Order is Cancelled confirmation mail can be sent to your register email.</p>
       
        </div>
      
        <Modal.Body className="py-0">
         

        </Modal.Body>
     
         <div className='model_footer'>
          <div className="proceed_checkout mb-5">
          <button >Back To Orders</button>
          </div>
          
          </div> 
          
        
       
      </Modal>


    </>
)
}
export default My_order;
