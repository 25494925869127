import Footer from "../component/Footer";
import Header from "../component/Header";
import { useEffect, useState } from "react";
import "./maindashboard.css";
import { Link, useNavigate } from "react-router-dom";

const Maindashboard = () =>{
const navigate=useNavigate()
const [whislist,setwhislist] = useState([
    { id: 1,
     heading: 'Wish list' ,
     Image:'img/product_five.png',
     content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
     buttoncontent:'View List',
     link:'/Whishlist'
    },
    {id: 2,
        heading: 'My Orders' ,
        Image:'img/product_five.png',
        content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        buttoncontent:'View Orders',
        link:'/My_order'
    },
  
    // Add more items as needed
  ]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return(
        <>
        <Header></Header>
         <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="dashbaord">
                    <h1>my Account</h1>
                    <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" /><br></br>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br> typesetting industry. Lorem Ipsum has been the industry's.</p> */}
                    <button><Link to="/Myprofile">Profile Details</Link></button>
                    </div>
                   
                </div>
            </div>
            <div className="row">
            {whislist.map(item => (
    <div className="col-md-6" key={item.id}>
        <div className="dashboard_product_list">
            <div className="product_list_inner-content">
            <h1>{item.heading}</h1>
            <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" /><br />
            <p dangerouslySetInnerHTML={{ __html: item.content }} />
            <button><Link to={item.link} style={{textDecoration:'none',color:"inherit"}}>{item.buttoncontent}</Link></button>
            </div>
            <div className="item_img">
            <img src={process.env.PUBLIC_URL + item.Image} alt="logo" />
            </div>
           
        </div>
    </div>
))}

               
                
            </div>
            <div className="row mb-5">
                <div className="col-md-4">
                    <div className="address_boook">
                    <h1>Address Book</h1>
                    <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                
                    <button onClick={()=>{navigate('/Myprofile')}}>Manage Address</button>
                    </div>
               
                </div>
                <div className="col-md-4">
                <div className="address_boook">
                <h1>Return Orders</h1>
                <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo"  />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                   
                    <button onClick={()=>{navigate('/Return_Payements')}}> View Orders</button>
                    </div>
               
                </div>
                <div className="col-md-4">
                <div className="address_boook">
                <h1>Payments</h1>
                <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" 
                 />
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                   
                    <button onClick={()=>{navigate('/Payments')}}>View List</button>
                    </div>
                </div>
            </div>
         </div>
         <div className="footer_top">
      <Footer ></Footer>
      </div>
        </>
    )
}

export default Maindashboard;
