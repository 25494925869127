import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import Bestseller from "../Home/bestseller";
import Mentor_features from "./mentor_features";
import React,{ useContext, useEffect, useState } from "react";

import "../Home/home.css";
import "./woodbern.css";
import { Store } from "../Redux/store";
import { post_Request } from "../Controller/Home_controller";
import { IMAGE_BASE_URL } from "../Config/constant";

const Mentor_homepage = () => {
  const { brandBannerProductData ,homeBanner,Collection,trickers} = useContext(Store);
  const navigate = useNavigate();
  const banner=homeBanner?.mentorBannerData?.length > 0 ? homeBanner?.mentorBannerData[0]:null
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState("");
  // const [bestSellers,setBestSeller]=useState(brandBannerProductData?.bestsellers?.length > 0 ? brandBannerProductData?.bestSellers[0] :'')
  const handleImageLoaded = () => {
    setLoaded(true);
  };


    const imageStyle = {
        transform: loaded ? 'translate(-630, 0)' : 'translate(0px, 0)', // Move from initial position to final position
        transition: 'transform 0.1s ease', // Smooth transition over 0.5 seconds
        width: '100%',
        objectFit: 'fill',
        opacity: loaded ? 1 : 0, // Using opacity for smoother transition
    };

  const getProductIds = async (arr) => {
    const productData = [];
    arr.forEach((obj) => {
      productData.push(obj.product_id);
    });
    return productData;
  };

  const getProductData = async (arrayOfProductIds) => {
    try {
      const options = {
        url: "/common/get-products",
        postData: {
          condition: {
            status: "A",
            _id: arrayOfProductIds,
          },
          limit: 12,
          page: 1,
        },
      };
      await post_Request(options).then((response) => {
        setData(response.result.productData);
      });
    } catch (error) {
      console.error(error);
    }
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (brandBannerProductData?.bestSellers?.length > 0) {
  //       const arrayOfProductIds = await getProductIds(brandBannerProductData?.bestSellers);
  //       if (arrayOfProductIds) {
  //         await getProductData(arrayOfProductIds);
  //       }
  //       setBestSeller(brandBannerProductData?.bestSellers[0])
  //     }
  //   };

  //   fetchData();
  // }, [brandBannerProductData?.bestSellers?.length]);
  useEffect(()=>{
    window.scrollTo(0, 0);
    },[])

    const parts =  trickers[0]?.tricker.split('|');
  return (
    <>
      <Header></Header>
      <div className="mentor_homeage">
      <div className="main_slider">
        <div className="container">
        <video className="top_banner" controls poster={IMAGE_BASE_URL+banner?.banner_image_1}>
    <source src={IMAGE_BASE_URL+banner?.video_url} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
        </div>
      </div>

      <div className="shop_now">
        <div className="container">
        <div className="row">
            <div className="col-md-12">
              <p>
                {/* New Styles on Sale: Up to 40% Off Shop All Our New Markdowns {" "} */}
                {trickers?.length > 0 ? parts[0]:''}
                | <span className="discount"> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>{trickers?.length > 0 ?parts[1]:''}</Link></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="features mt-5">
                <div className="container_features">

                    
                    <div className="row">
                  
                        <div className="col-md-4">
                            <div className="right_side">
                                <img src="./img/rightbg.png" ></img>
                                <div className="content">
                                    <h2>Our Featured Products</h2>
                                    <p>The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Features_woodbern></Features_woodbern>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="hot_list">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="common_heading mb-0 arriavals">
                New arrivals in on-trend relaxed fits are here!
              </h1>
              <p className="common_text">
                The Most-Coveted Essentials. Curated By Us, Inspired By You.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 padding-right">
              {brandBannerProductData?.latestArrivalData?.length > 0 &&
                brandBannerProductData?.latestArrivalData
                  ?.slice(0, 1)
                  .map((item, index) => (
                    <img
                      src={IMAGE_BASE_URL + item.image}
                      className="top_banner"
                      key={index}
                      onClick={()=>{const data={ mentorLatestCol:{
                        subcategory_id:item.subcategory_id,
                        type:item.type,
                       } };navigate("/Listing", { state: data });}}
                    />
                  ))}
            </div>

            <div className="col-md-12 col-lg-6 padding_left">
              <ul className="mentor_section_img">
                {brandBannerProductData?.latestArrivalData?.length > 0 &&
                  brandBannerProductData?.latestArrivalData

                    ?.slice(1)
                    .map((item, index) => (
                      <li key={index}>
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          className="top_banner"
                          onClick={()=>{const data={ mentorLatestCol:{
                            subcategory_id:item.subcategory_id,
                            type:item.type,
                           } };navigate("/Listing", { state: data });}} ></img>
                      </li>
                    ))}
                {/* <li><img src={process.env.PUBLIC_URL + "./img/mentor_third.png"} className="top_banner"></img></li>
                                 <li><img src={process.env.PUBLIC_URL + "./img/mentor_fourth.png"} className="top_banner"></img></li>
                                 <li><img src={process.env.PUBLIC_URL + "./img/mentor_one.png"} className="top_banner"></img></li>
                                 <li><img src={process.env.PUBLIC_URL + "./img/mentor_second.png"} className="top_banner"></img></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1 className="common_heading my-0 arriavals">
              Our Featured Products
            </h1>
            <p className="common_text">
              The Most-Coveted Essentials. Curated By Us, Inspired By You.
            </p>
          </div>
          <div className="col-md-12">
            <div className="mentor_slider">
              <Mentor_features
                brandBannerProductData={brandBannerProductData}
              ></Mentor_features>
            </div>
          </div>
        </div>
      </div>
      <div className="winter_collection" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                src={IMAGE_BASE_URL+Collection?.collection_image}
                alt="logo"
                style={{minHeight:"400px"}}
                
              />

              {/* <div className="collection_content drop-in-2">
                <h2>
                  {" "}
                  {Collection?.title
                    }
                </h2>
                <ul>
                      <React.Fragment>
                        {" "}
                    
                        {Collection?.variants?.map((gender, subIndex) => (
                          
                          <li
                            key={subIndex} 
                            onClick={() => {
                              const mentorWinColl = {
                                gender_id: gender.gender_id, 
                              
                                product_collection_id: Collection?._id,
                                KEY:"HeadCategory"
                              };
                              navigate("/Listing", { state: mentorWinColl });
                            }}
                          >
                            <span>{gender.gender_name}</span>{" "}
                   
                          </li>
                        ))}
                      </React.Fragment>
                 
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="winter_collection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img
                src={
                  IMAGE_BASE_URL +
                  (brandBannerProductData?.winterCollection?.length > 0
                    ? brandBannerProductData?.winterCollection[0].image
                    : "")
                }
                alt="logo"
              />

              <div className="collection_content drop-in-2">
                <h2>
                  {" "}
                  {brandBannerProductData?.winterCollection?.length > 0
                    ? brandBannerProductData?.winterCollection[0].title
                    : ""}
                </h2>
                <ul>
          
{brandBannerProductData?.winterCollection?.length > 0 && brandBannerProductData?.winterCollection?.map((item, index) => (
  <React.Fragment key={index}> 
    {item.variants.map((gender, subIndex) => (  
      <li
        key={subIndex}
        onClick={() => {
          const mentorWinColl = {
            gender_id: gender.gender_id,
            brand: item.brand_name, 
            product_collection_id: item.collection_id,
            KEY:"HeadCategory"
          };
          navigate("/Listing", { state: mentorWinColl });
        }}
      >
        <span>{gender.gender_name}</span>
      </li>
    ))}
  </React.Fragment>
))}


          
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="best_seller mentor_seller" id="best_seller_home">
        <div className="container">
          <div className="row">
            <div className="col-md-6 featuered_product">
              <h1 className="common_heading bestseller">Our Bestsellers</h1>
              <p className="common_text">
              The Most-Coveted Essentials. Curated By Us, Inspired By You.
              </p>
            </div>

            <div className="col-md-6">
              <div class="shop_now_seller">
                <button
                  // onClick={() => {
                  //   navigate("/Listing", { state: "bestsellers" });
                  // }}
                  // onClick={()=>{ const data={ mentorLatestCol:{
                  //   subcategory_id:bestSellers?.subcategory_id,
                  //   type:bestSellers?.type
                  //  } };navigate("/Listing", { state: data });}}
                className="shop_now_seller">
                  Shop Now
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <Bestseller bestseller={brandBannerProductData?.bestSellers}></Bestseller>
          </div>
        </div>
      </div>
      </div>
      <div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
  );
};
export default Mentor_homepage;
