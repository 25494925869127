import Header from "../component/Header";
import Footer from "../component/Footer";
import "./cart.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect,useRef } from "react";
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import {
  getSessionForLoginUser,
  setSessionForLoginUser,
} from "../utils/helper";
import { validateAddAddress } from "../component/Validation/addressValidation";
import { fetchSystemAddress } from "../Controller/API";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
import { post_Request } from "../Controller/Home_controller";
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';

const Add_new_address = () => {
  const { getCart, cart, subtotal, addressList,getaddressList } = useContext(Store);
  const { state } = useLocation();
  const [cooupnCode, setCoupnCode] = useState(state?.temp?.code ?? null);

  const [responseOfCopuonCode, setResponseOfCopuonCode] = useState("");
  const navigate = useNavigate();
  const User = getSessionForLoginUser("User");
  const [show, setShow] = useState(User?false:true);
  
  const [error, setError] = useState(null);
  const [content, setContent] = useState("");
  const [isCouponUsed,setIsCouponUsed]=useState(cooupnCode===null?false:true)
  const [discountper,setDiscountper]=useState(null)
  const [errorCoi,setErrorCoi]=useState(null)
  const [isPopupOpen, setisPopupOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [saveAddress, setSaveAddress] = useState(false);
  const [radioButtonFormData, setRadiButtonFormData] = useState(null);
  const userAddress1 = `${User?.firstName}, ${User?.lastName}, ${User?.mobile}, ${User?.address2}, ${User?.city}, ${User?.state}, ${User?.country}, ${User?.pincode}`;
  const userAddress2 = `${User?.firstName}, ${User?.lastName}, ${User?.mobile}, ${User?.address1}, ${User?.city}, ${User?.state}, ${User?.country}, ${User?.pincode}`;
  //open modal 
  const headerRef = useRef();

  // country state city
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const countryOptions = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    label: country.name,
  }));

  const stateOptions = selectedCountry
    ? State.getStatesOfCountry(selectedCountry.value).map(state => ({
        value: state.isoCode,
        label: state.name,
      }))
    : [];

  const cityOptions = selectedState
    ? City.getCitiesOfState(selectedCountry.value, selectedState.value).map(city => ({
        value: city.name,
        label: city.name,
      }))
    : [];

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);
    setFormData({ ...formData, country: country ? country.label : '', state: '', city: '' });
  };

  const handleStateChange = (state) => {
    setSelectedState(state);
    setSelectedCity(null);
    setFormData({ ...formData, state: state ? state.label : '', city: '' });
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
    setFormData({ ...formData, city: city ? city.label : '' });
  };
  // end
  const openLoginModal = () => {
    if (headerRef.current) {
      headerRef.current.openModel();
    }
    // headerRef.current.openCompanyModel();
  };
  // end
  const handleAddressSelection = async (item) => {
     setShow(false);
    setError("");
    const formData = {
      user_id: User?._id,
      current_ip: await fetchSystemAddress(),
      ...(isCouponUsed?{isCouponUsed:'Y'}:{}),
      coupon: cooupnCode??'',
      discount: discountper?discountper:state?.temp?.statusCode?.result?.result?.discount?state?.temp?.statusCode?.result?.result?.discount:'0',
      payment_mode: "Online",
      latitude: "67256",
      longitude: "84739",
      firstName: item === 1 || item === 2 ? User.firstName : item.first_name,
      lastName: item === 1 || item === 2 ? User.lastName : item.last_name,
      phone: item === 1 || item === 2 ? User.mobile : item.mobile_no,
      email: item === 1 || item === 2 ? User.email : item.email,
      address1:
        item === 1 ? User.address1 : item === 2 ? User.address2 : item.address1,
      landMark: item === 1 || item === 2 ? User.address2 : item.landMark??User?.address2,
      pincode: item === 1 || item === 2 ? User.pincode : item.pincode,
      country: item === 1 || item === 2 ? User.country : item.country,
      state: item === 1 || item === 2 ? User.state : item.state,
      city: item === 1 || item === 2 ? User.city : item.city,
    };

    setRadiButtonFormData(formData);
    setSelectedAddress(item._id || item);
  };

  const handleSaveAddressToggle = () => {
    setSaveAddress((prevSaveAddress) => !prevSaveAddress);
  };
  const handleCheckout = () => {
    if (!selectedAddress) {
      setError("Address Is Required!");
    } else {
      navigate("/Cart_address_detail", { state: selectedAddress });
    }
  };
  const excludedFields = [
    "discount",
    "payment_mode",
    "latitude",
    "longitude",
    "user_id",
    "current_ip",
    "coupon",
  ];
  const [formData, setFormData] = useState({
    user_id: User?._id,
    current_ip: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address1: "",
    landMark: '',
    pincode: "",
    country: "",
    state: "",
    city: "",
    isCouponUsed:isCouponUsed?'Y':"N",
    coupon: cooupnCode??'',
    discount: discountper?discountper:state?.temp?.statusCode?.result?.result?.discount?state?.temp?.statusCode?.result?.result?.discount:'0',
    payment_mode: "online",
    latitude: "67256",
    longitude: "84739",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error message when input changes
    });
    if (value) {
      // setShow(true);
      setSelectedAddress(null);
    }
  };

  const handleSubmit = async () => {
    if(state?.temp?.statusCode?.result?.result?.discount || discountper){
      setIsCouponUsed(true)
    }
    const validationErrors = validateAddAddress(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0 || radioButtonFormData) {
      const options = {
        url: "/order/generate-order",
        postData: radioButtonFormData?.firstName
          ? radioButtonFormData
          : formData,
      };

      try {
        const response = await post_Request(options);
        const UserData = response.result.result.userData;
        if (response.status === true) {
          setContent({
            h1: "Thank You",
            p: "Your order has successfully been placed.",
            h3: "View my orders"
          });
          setisPopupOpen(true);
          getCart();
          if (UserData && !User) {
            setSessionForLoginUser("User", UserData);
            sessionStorage.setItem("TOKEN", UserData.token);
          }
          if (saveAddress === true) {
            const addressOptions = {
              url: "/users/add-address",
              postData: {
                first_name: formData.firstName || radioButtonFormData.firstName,
                last_name: formData.lastName || radioButtonFormData.lastName,
                mobile_no: formData.phone || radioButtonFormData.phone,
                email: formData.email || radioButtonFormData.email,
                pincode: formData.pincode || radioButtonFormData.pincode,
                country: formData.country || radioButtonFormData.country,
                state: formData.state || radioButtonFormData.state,
                city: formData.city || radioButtonFormData.city,
                address1: formData.address1 || radioButtonFormData.address1,
                address2: formData.landMark || radioButtonFormData.address2,
                platform: "Web",
                latitude: formData.latitude,
                longitude: formData.longitude,
                current_ip: formData.current_ip,
              },
            };
            await post_Request(addressOptions).then((response) => {
              if (response.status == true) {
               
              }
            });
          }
       
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setError("Address Is Required!");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch system address asynchronously
        const systemAddress = await fetchSystemAddress();
        setFormData((prevState) => ({
          ...prevState,
          current_ip: systemAddress,
        }));
      } catch (error) {
        console.error("Error fetching system address:", error);
        // Handle error if needed
      }
    };
    if (
      Object.keys(formData)
        .filter((key) => !excludedFields.includes(key))
        .some((key) => !!formData[key])
    ) {
      // setShow(true);
      setSelectedAddress(null);
    }
    fetchData(); 
    if(state?.temp?.code){
      handleCoupon()
    }
    window.scrollTo(0, 0)
    getaddressList()
  }, []);
  const handleChangeCode = async (e) => {
    setErrorCoi('')
    setCoupnCode(e.target.value);
  };
  const [errorMsgCp,seterrorMsgCp]=useState(null)
  const handleCoupon = async (code) => {
    const Checkuser=getSessionForLoginUser("User")
    if(!Checkuser){
      // setErrorCoi("Please Login First !")
      openLoginModal()
    }
    if (!code && !state?.temp?.code) {
      setErrorCoi("Please enter a coupon code");
    }
    
    try {
      const options = {
        url: "/users/validate-coupon",
        postData: {
          promo_code: code??state?.temp?.code,
        },
      };
      const response = await post_Request(options);
      setResponseOfCopuonCode(response);
      if(response?.result?.result?.discount){
        setDiscountper(response.result.result.discount)
        setFormData((prev) => ({
          ...prev,
          discount: response.result.result.discount,
          coupon: cooupnCode,
          isCouponUsed:"Y"
        }));  
         
      }
      
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };
  const discountPercentage = parseInt(responseOfCopuonCode?.result?.result.discount);
  const discountedAmount = Math.round(subtotal - (subtotal * (discountPercentage / 100)));


  // razor pay

  const [amount, setAmount] = useState(0);
  const [orderId, setOrderId] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const openRazorpayPopup =async () => {
    const validationErrors = validateAddAddress(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0 || radioButtonFormData) {
      if(state?.temp?.statusCode?.result?.result?.discount || discountper){
        setIsCouponUsed(true)  
      }
      try {
        const options = {
          key: 'rzp_test_f2FovlSQrHRgeN',
          amount: (discountedAmount ? discountedAmount : subtotal)*100, // Replace with the amount you want to charge
          currency: 'INR',
          name: 'Sportking',
          description: 'Test Payment',
          prefill: {
            name: formData?.firstName,
            email: 'john@example.com',
            contact: '9999999999',
          },
          theme: {
            color: '#3399cc',
          },
          handler: function (response) {
            // alert(`Payment successful. Payment ID: ${response.razorpay_payment_id}`);
            handleSubmit()
            setPaymentSuccess(true);
          },
        };
    
        const rzp = await new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error('Error opening Razorpay popup:', error);
        alert('Error in opening Razorpay popup. Please try again later.');
      }
    }else {
      if(User){
        setError("Address Is Required!");
      }

    }
 
  };
  // end rZOR PYA
  return (
    <>
           <Header  ref={headerRef}/>

      <div className="container mb-5 card_main_Section">
        <div className="row">
          <div className="col-md-8">
            {User ? (
              <>
                <p className="heading">You are logged in as:</p>
                <div id="address">
                  <ul>
                    <li>
                      <div className="login_address">
                        <img
                          src={
                            process.env.PUBLIC_URL + "./img/user_dashboard.svg"
                          }
                          alt="logo"
                          className="user_icon"
                        />
                        <p>
                          {User?.firstName} {User?.lastName}{" "}
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="login_address">
                        <img
                          src={process.env.PUBLIC_URL + "./img/call_icon.svg"}
                          alt="logo"
                        />
                        <p>+91 {User?.mobile}</p>
                      </div>
                    </li>
                    <li>
                      <div className="login_address">
                        <img
                          src={process.env.PUBLIC_URL + "./img/location.svg"}
                          className="email_icon"
                          alt="logo"
                        />
                        <p>{User?.email}</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <p className="heading mt-3">Select Delivery Options</p>
              </>
            ) : (
              ""
            )}
            <hr className="mt-2" id="address_under_line"></hr>
            <p className="heading mt-2">
              {User ? "Address Book:" : "Enter Shipping Address"}
            </p>
            <div className="proceed_checkout" style={{ textAlign: "left" }}>
              {User ? (
                <button
                  className="mt-1"
                  onClick={() => {
                    setSelectedAddress(null)
                    setRadiButtonFormData(null)
                    setShow(true);
                  }}
                >
                  Add A New Address
                </button>
              ) : (
                ""
              )}
            </div>
            {show ? (
              <div className="shipping_address_detail_form">
                <div className="d-flex shipping_address">
                  {User ? <p>New Shipping Address</p> : ""}
                  {User ? (
                    <p
                      className="delete"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
              <img src={process.env.PUBLIC_URL + './img/bin.png'}alt="delete" style={{width:"23px"}} />
                    </p>
                  ) : (
      ''
                  )}
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-4 padding-right">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName && (
                        <p className="error_Validtaion">{errors.firstName}</p>
                      )}
                    </div>
                    <div className="col-md-4 padding-right">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName && (
                        <p className="error_Validtaion">{errors.lastName}</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form_group"
                        placeholder="Mobile Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <p className="error_Validtaion">{errors.phone}</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="email"
                        name="email"
                        className="form_group"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p className="error_Validtaion">{errors.email}</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="landMark"
                        className="form_group"
                        placeholder="Land Mark"
                        value={formData.landMark}
                        onChange={handleChange}
                      />
                      {errors.landMark && (
                        <p className="error_Validtaion">{errors.landMark}</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="number"
                        name="pincode"
                        className="form_group"
                        placeholder="Pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                      />
                      {errors.pincode && (
                        <p className="error_Validtaion">{errors.pincode}</p>
                      )}
                    </div>

                    <div className="col-md-12">
                      <input
                        type="text"
                        name="address1"
                        className="form_group"
                        placeholder="Address"
                        value={formData.address1}
                        onChange={handleChange}
                      />
                      {errors.address1 && (
                        <p className="error_Validtaion">{errors.address1}</p>
                      )}
                    </div>
                    <div className="col-md-4 padding-right mt-4">
        <Select
          options={countryOptions}
          value={selectedCountry}
          onChange={handleCountryChange}
          placeholder="Select Country"
      
        />
        {errors.country && <p className="error_Validtaion">{errors.country}</p>}
      </div>
      <div className="col-md-4 padding-right mt-4">
        <Select
          options={stateOptions}
          value={selectedState}
          onChange={handleStateChange}
          placeholder="Select State"

          isDisabled={!selectedCountry}
        />
        {errors.state && <p className="error_Validtaion">{errors.state}</p>}
      </div>
      <div className="col-md-4 mt-4">
        <Select
          options={cityOptions}
          value={selectedCity}
          onChange={handleCityChange}
          placeholder="Select City"

          isDisabled={!selectedState}
        />
        {errors.city && <p className="error_Validtaion">{errors.city}</p>}
      </div>

                    <div className="col-md-12">
                      <div
                        className="saving_address"
                        onClick={handleSaveAddressToggle}
                      >
                        <input type="radio" checked={saveAddress} readOnly />
                        <p className="heading">Save For Future Address</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}

            {User ? (
              <>
                <div className="address">
                  <div className="">
                    <input
                      type="radio"
                      id="address1"
                      name="selectedAddress"
                      value={1}
                      checked={selectedAddress === 1}
                      onChange={() => handleAddressSelection(1)}
                    />
                    <label htmlFor="address1">
                      <h1>Address 01</h1>
                    </label>
                    {/* <h1>Address 01</h1> */}
                  </div>

                  <div>
                    <p> {userAddress1}</p>
                  </div>
                </div>

                <div className="address">
                  <div className="">
                    <input
                      type="radio"
                      id="address2"
                      name="selectedAddress"
                      value={2}
                      checked={selectedAddress === 2}
                      onChange={() => handleAddressSelection(2)}
                    />
                    <label htmlFor="address2">
                      <h1>Address 02</h1>
                    </label>
                  </div>
                  <div>
                    <p>{userAddress2}</p>
                  </div>
                </div>
                {addressList &&
                  addressList.map((item, index) => (
                    <div className="address">
                      <div className="">
                        <input
                          type="radio"
                          id="address2"
                          name="selectedAddress"
                          value={item}
                          checked={selectedAddress === item._id}
                          onChange={() => handleAddressSelection(item)}
                        />
                        <label htmlFor="address2">
                          <h1>Address 0{3 + index}</h1>
                        </label>
                      </div>
                      <div>
                        <p>
                          {item.first_name} {item.last_name} {item.mobile_no}{" "}
                          {item.address2} {item.address1} {item.state}{" "}
                          {item.city} {item.country} {item.pincode}
                        </p>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div className="proceed_checkout" style={{ textAlign: "left" }}>
                <button className="mt-4" onClick={openRazorpayPopup }>
                  Save & Continue
                </button>
              </div>
            )}
            {error && <p className="error_Validtaion">{error}</p>}
          </div>

          <div className="col-md-4">
            <p className="heading">Payment Details</p>
            <hr className="heading_line"></hr>
            <div className="d-flex coupen_code">
            <input
    type="text"
    placeholder="Apply Coupon Code"
    value={cooupnCode}
    onChange={handleChangeCode}
    readOnly={state === null ? false : true}
  />
  {state===null? 
              <button
                style={{  marginTop: "0px", border: "none" ,    padding: "8px 20px 10px 20px"}}
                onClick={() => {
                  handleCoupon(cooupnCode);
                }}
              >
                Apply{" "}
              </button>
:''}
              <img
                src={process.env.PUBLIC_URL + "./img/coupen.svg"}
                alt="logo"
              />
            </div>
            {responseOfCopuonCode.statusCode === "promocodeerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                {responseOfCopuonCode.message}
              </p>
            ) : state?.temp?.statusCode?.statusCode === "ALREADYREDEEMerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                {" "}
                Coupon has already been redeemed
              </p>
            ) : responseOfCopuonCode.statusCode === "ALREADYREDEEMerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                {" "}
                Coupon has already been redeemed
              </p>
            ) : state?.temp?.statusCode?.statusCode === "promocodeerror" ? (
              <p style={{ color: "red", fontSize: "13px" }}>
                Please enter valid promocode
              </p>
            ) : state?.temp?.statusCode?.result?.result ? (
              <p style={{ color: "#008000", fontSize: "13px" }}>
                {" "}
                Coupon Applied!{" "}
                {state?.temp?.statusCode?.result?.result.discount} OFF
              </p>
            ) : responseOfCopuonCode?.result?.result ? (
              <p style={{ color: "#008000", fontSize: "13px" }}>
                {" "}
                Coupon Applied! {
                  responseOfCopuonCode?.result?.result.discount
                }{" "}
                OFF
              </p>
            ) : (
              ""
            )}
               {errorCoi?<p style={{color:"red",fontSize:"13px"}}>{errorCoi}</p>:''}
            <div className="detail_price">
              <div className="">
                <h1>detail</h1>
                <h1>Price</h1>
              </div>

              <hr></hr>
              <div className="">
                <p>Sub Total</p>
                <p>₹ {subtotal}</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Coupon Discount</p>
                <p className="disoucnt_coupen_price">
                  <span className="discount_price">
                    {" "}
                    {state?.temp?.statusCode?.result?.result.discount
                      ? discountedAmount - subtotal
                      : responseOfCopuonCode?.result?.result.discount? (discountedAmount-subtotal) : "- ₹ 0"}{" "}
                  </span>
                </p>
              </div>
              <hr></hr>
              <div className="">
                <p>Delivery Charges</p>
                <p>₹ 0</p>
              </div>
              <hr></hr>
              <div className="">
                <p>Other Charges</p>
                <p>₹ 0</p>
              </div>
            </div>
            <div className="mt-3 py-2 cart_total">
              <p>Total</p>
              <p>₹ {discountedAmount ? discountedAmount : subtotal}</p>
            </div>
            <div className="order_item">
              <p className="heading">Items In Your Order</p>
              <hr className="heading_line"></hr>
              {cart?.map((item, index) => (
                <>
                  <div className="cart_product_deatil" key={index}>
                    <div className="cart_product_img">
                      <img
                        src={IMAGE_BASE_URL + item.product_image}
                        alt="logo"
                      />
                    </div>
                    <div className="total-product">
                      <h1>{item.product_name}</h1>
                      <p className="price mb-0">₹ {item.price}</p>
                      <p className="product_color mb-0">Color : {item.color}</p>
                      <p className="product_color">Size : {item.size}</p>
                      <p className="product_color">Quantity : {item.qty}</p>
                    </div>
                  </div>
                  <hr className="mt-3"></hr>
                </>
              ))}
{errorMsgCp? <p style={{color:"red",fontSize:"13px"}}>{errorMsgCp}</p>:""}
              {User ? (
                <div className="proceed_checkout">
                  <button>
                    <div onClick={openRazorpayPopup}> Proceed To Pay</div>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <ThankYoupopup isOpen={isPopupOpen} content={content} />
      <div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
  );
};

export default Add_new_address;
