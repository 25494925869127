import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Skeleton } from "antd";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_BASE_URL } from "../Config/constant";
import { useNavigate } from "react-router-dom";


const Features_woodbern = ({ featuredProduct }) => {
  const navigate= useNavigate()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2.2,
    autoplay: true,
    autoplaySpeed: 3000,
responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1.5
            }
        }
        // Add more breakpoints and settings as needed
    ]
  };
  const handleNavigate = (item) => {
    const data = {
      mentorLatestCol: {
        subcategory_id: item.subcategory_id,
        type: item.type,
        featured_products_type: item.title_slug,
      },
    };
    navigate("/Listing", { state: data });
  };
  return (
    <>
      <div className="features_slides">
        <Slider {...settings}>
          {featuredProduct?.length > 0
            ? featuredProduct?.map((item, index) => (
                <div
                  className=""
                  key={index}
                  onClick={() => {
                    handleNavigate(item);
                  }}
                >
                  <img src={IMAGE_BASE_URL + item.image} alt="logo" />
                  <p>{item.title}</p>
                </div>
              ))
            : Array.from({ length: 2 }, (_, i) => (
                <div key={i}>
                  <Skeleton.Avatar
                    size={350}
                    shape="square"
                    active
                    style={{ height: "500px" }}
                  />
                  <Skeleton paragraph={{ rows: 0 }} active className="mt-4" />
                </div>
              ))}

              
        </Slider>
      </div>
    </>
  );
};

export default Features_woodbern;
