import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faTwitter, faYoutube ,faFacebook, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { postRequest } from '../Controller/API';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaArrowCircleUp } from 'react-icons/fa';
import {validateEmail} from "./Validation/loginValidation";
import { post_Request } from "../Controller/Footer_Controller";
import Accordion from 'react-bootstrap/Accordion';
import { post_RequestSearch } from '../Controller/Login';
library.add(faInstagram, faTwitter, faYoutube,faFacebook,faWhatsapp);


const Footer = ()=>{
    const navigate = useNavigate()
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState("");
    const [error, setError] = useState('');
    const [form, setForm] = useState({
        email: "", // Initialize email as an empty string
      });
    const elementStyle = {
        marginRight: '10px',
    };

    const handleRegistrationChange = (e) => {
        const { name, value } = e.target;
        setError('')
        setEmail(value); // Update email state
        setForm({ ...form, [name]: value });
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        
        // Check if email is valid
        if (!validateEmail(email)) {
          setError('Enter a valid email');
          return;
        }
      
        try {
          const options = {
            url: '/common/newsletter-subscriptions',
            postData: {
              email: email,
            },
          };
          const response = await post_Request(options);
          if (response.status === true) {
            // Subscription successful
            setEmail('')
            setForm(prev => ({
              ...prev,
              email: null
            }));
            
            toast.success('Subscribed!');
           
            const { email, status } = response.result;
            // Optionally, you can display a success message or perform any other actions

          } else {
            // Handle other cases such as API errors
            if (response.message === "You have already subscribed.") {
              setError( "You have already subscribed.");
            } else {
              // Display other API error messages
              console.error("API Error:", response.error);
            }
          }
        } catch (error) {
          console.error("API Error:", error);
        }
      };

      const [isVisible, setIsVisible] = useState(false);

      useEffect(() => {
          window.addEventListener('scroll', handleScroll);
          return () => {
              window.removeEventListener('scroll', handleScroll);
          };
      }, []);
  
      const handleScroll = () => {
          const scrollTop = window.pageYOffset;
          if (scrollTop > 300) { // Change this value to adjust when the button appears
              setIsVisible(true);
          } else {
              setIsVisible(false);
          }
      };
  
      const scrollToTop = () => {
          window.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      };
       
      const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

      useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth <= 768);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      
      const handleSerach= async(key)=>{

        try{
          const options = {
              url: `/common/search?productName=${key}`,
            };
          const response = await post_RequestSearch(options)
      
      const responseData= response.result.data;
      // if(responseData.length > 0){
        const KEY="HeadCategory";
        navigate('/Listing', { state: { responseData: responseData, "searched": true, KEY:KEY } });
      // }
      }catch(error){
      console.error(error)
      }
      }
      const handleNavigate=(key)=>{
        handleSerach(key)
      }
  
    return(
        <>
        <div className='' >
        <div className="footer_deliver_source">
            <div className="">
                <ul>
                <li> <img src={process.env.PUBLIC_URL + "../img/tracking.jpg"} alt="logo"  className='delivery_icon'/></li>
                    <li> <img src={process.env.PUBLIC_URL + "../img/footer_lines.svg"} alt="logo" className='footer_line'/></li>
                    <li><strong>Order Tracking</strong> <br></br> 24*7 Live Tracking</li>
                </ul>
            </div>
            <div className="">
                <ul>
               
                <li> <img src={process.env.PUBLIC_URL + "../img/group.png"} alt="logo"  className='delivery_icon'/></li>
                 
                    <li> <img src={process.env.PUBLIC_URL + "../img/footer_lines.svg"} alt="logo"  className='footer_line'/></li>
                    <li><strong>Support :</strong> <br></br>Monday to Friday : 10am to 5pm</li>
                </ul>
            </div>
            <div className="">
                <ul>
                <li> <img src={process.env.PUBLIC_URL + "../img/footerimg.jpg"} alt="logo"  className='delivery_icon'/></li>
                    <li> <img src={process.env.PUBLIC_URL + "../img/footer_lines.svg"} alt="logo"  className='footer_line'/></li>
                    <li><strong>15 days:</strong> <br></br> Return/Exchange Policy</li>
                </ul>
            </div>
            <div className="">
                <ul>
                <li> <img src={process.env.PUBLIC_URL + "../img/delivery.jpeg"} alt="logo"  className='delivery_icon'/></li>
                   
                    <li> <img src={process.env.PUBLIC_URL + "../img/footer_lines.svg"} alt="logo"  className='footer_line'/></li>
                    <li><strong>Free Shipping:</strong> <br></br>On All Orders Above Rs. 599 <br></br>and COD also Available</li>
                </ul>
            </div>
        </div>
        </div>

         <div>
      {isMobileView ? (
       


       <div className="footer">
        <div className="container">
           <div className="row">
               <div className="footer_linking">
                   <ul >
                   <div className="footer--link">
                   <img src={process.env.PUBLIC_URL + "../img/footerlogo.svg"} alt="logo"/>
               {/* <div className="footer_lines"></div> */}
               <div className="follow_us">
               <h1 className="heading_footer">FOLLOW US ON:</h1>
               <div className="footer_lines"></div>
               <ul>
                    <li><Link to="https://www.facebook.com/sportkingindia"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></Link></li>
                    <li><Link to="https://www.instagram.com/sportkingindia/"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /> </Link></li>
                    {/* <li><Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fsportkingindia"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></Link></li> */}
                    {/* <li><Link to="#"><FontAwesomeIcon icon={faYoutube} /> </Link></li> */}
                </ul>
               </div>
              


                   </div>
                   </ul>
                   <div className='mobile_footer'>
                   <ul>
                   <div className="footer--link">
                   <h1 className="heading_footer">QUICK LINKS</h1>
                   <div className="footer_lines"></div>
                   <ul>
                   <li><Link to="/blog">Blogs</Link></li>
                  <li><Link to='/shipping_return_policy' className='foothglinkcolor'> Shipping & Return Policy </Link></li>
                  <li><Link to='/Privacy_policy' className='foothglinkcolor'> Privacy Policy </Link></li>
                  <li> <Link to='/terms_conditions' className='foothglinkcolor'> Terms & Conditions </Link></li>
                  
                       </ul>

                   </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">COLLECTIONS</h1>
               <div className="footer_lines"></div>
                   <ul>
                   <li onClick={() => {
            const data = {
              mentorLatestCol: {
                type: 'latest_arrival'
              }
            };
            const KEY='HeadCategory'
            navigate('/Listing', { state: data,KEY });
          }}><Link  >Latest Arrivals</Link></li>
                   <li onClick={()=>handleNavigate('Men')}><Link to="#">Men Collection</Link></li>
                   <li onClick={()=>handleNavigate('Women')}><Link to="#">Women Collection</Link></li>
                   <li onClick={()=>handleNavigate('Kid')}><Link to="#">Kids Collection</Link></li>
                   </ul>
               </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">Contact Us</h1>
               <div className="footer_lines"></div>
                   <ul>
                   <li>
    <a href="https://wa.me/9178144138415" target="_blank">
    <img src={process.env.PUBLIC_URL + "../img/whatsapp.png"} className='icons whatsapp_icons' alt="logo" /> 7814138415
    </a>
</li>




                   <li>
  <a href="tel:18008904027">
    <img src={process.env.PUBLIC_URL + "../img/call_icon.svg"} className='icons' alt="logo" />1800-890-4027
  </a>
</li>
<li className='d-flex'>
<a href="mailto:customercare.sob@sportking.co.in" style={{textDecoration:"none", color:"#C9C9C9"}}>
<img src={process.env.PUBLIC_URL + "../img/location.svg"} className='icons' style={elementStyle}  alt="logo" />customercare.sob@sportking.co.in
  </a>
</li>

<li>
  <a 
    href="https://stores.sportkingfashion.com/?lat=28.4915663&long=77.0769417&shared=1" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <img 
      src={process.env.PUBLIC_URL + "../img/emial.svg"} 
      className='icons' 
      style={elementStyle} 
      alt="logo" 
    /> 
    Store locator
  </a>
</li>

                   </ul>
                   </div>
                   </ul>
                  
                   </div>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">NEWSLETTER</h1>
               <div className="footer_lines"></div>
               <form onSubmit={handleLogin}>
               <ul className='footer_link'>
                   <li> 
                       <input 
                       type="email"
                       name="email"
                       onChange={handleRegistrationChange}
                   className="newsletter" placeholder="Enter Email Address"></input>
                 
                      </li>
                   <div className="error_Validtaion">{error}</div>
                   <li><button type="submit">Submit</button></li>
                   </ul>
               </form>
                   
                   </div>
                   </ul>
               </div>
               

           </div>
          
        </div>
        <hr></hr>
        <div className="continer">
        <div className="row">
        <div className="col-md-12">
                    <p className="sportliking_footer" >All Right Reserved | The Sportking | © {currentYear}</p>
                </div>
           </div>
           <div className={`back-to-top ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
               <FaArrowCircleUp className="arrow-icon" />
           </div>
        </div>
        
        </div>
      ) : (
        <div className="footer">
        <div className="container">
           <div className="row">
               <div className="footer_linking">
                   <ul >
                   <div className="footer--link">
                   <img src={process.env.PUBLIC_URL + "../img/footerlogo.svg"} alt="logo"/>
               {/* <div className="footer_lines"></div> */}
               <div className="follow_us">
               <h1 className="heading_footer">FOLLOW US ON:</h1>
               <div className="footer_lines"></div>
               <ul>
                    <li><Link to="https://www.facebook.com/sportkingindia"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></Link></li>
                    <li><Link to="https://www.instagram.com/sportkingindia/"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /> </Link></li>
                    {/* <li><Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fsportkingindia"  target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></Link></li> */}
                    {/* <li><Link to="#"><FontAwesomeIcon icon={faYoutube} /> </Link></li> */}
                </ul>
               </div>
              


                   </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
                   <h1 className="heading_footer">QUICK LINKS</h1>
                   <div className="footer_lines"></div>
                   <ul>
                   <li><Link to="/blog">Blogs</Link></li>
                  <li><Link to='/shipping_return_policy' className='foothglinkcolor'> Shipping & Return Policy </Link></li>
                  <li><Link to='/Privacy_policy' className='foothglinkcolor'> Privacy Policy </Link></li>
                  <li> <Link to='/terms_conditions' className='foothglinkcolor'> Terms & Conditions </Link></li>
                  
                       </ul>

                   </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">COLLECTIONS</h1>
               <div className="footer_lines"></div>
                   <ul>
                   <li onClick={() => {
            const data = {
              mentorLatestCol: {
                type: 'latest_arrival'
              }
            };
            navigate('/Listing', { state: data });
          }}><Link  >Latest Arrivals</Link></li>
                   <li onClick={()=>handleNavigate('Men')}><Link to="#">Men Collection</Link></li>
                   <li onClick={()=>handleNavigate('Women')}><Link to="#">Women Collection</Link></li>
                   <li onClick={()=>handleNavigate('Kid')}><Link to="#">Kids Collection</Link></li>
                   </ul>
               </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">Contact Us</h1>
               <div className="footer_lines"></div>
                   <ul>
                   <li>
    <a href="https://wa.me/9178144138415" target="_blank">
    <img src={process.env.PUBLIC_URL + "../img/whatsapp.png"} className='icons whatsapp_icons' alt="logo" />  7814138415
    </a>
</li>




                   <li>
  <a href="tel:18008904027">
    <img src={process.env.PUBLIC_URL + "../img/call_icon.svg"} className='icons' alt="logo" />  1800-890-4027
  </a>
</li>
<li className='d-flex'>
<a href="mailto:customercare.sob@sportking.co.in" style={{textDecoration:"none", color:"#C9C9C9"}}>
<img src={process.env.PUBLIC_URL + "../img/location.svg"} className='icons' style={elementStyle}  alt="logo" />customercare.sob@sportking.co.in
  </a>
</li>

<li>
  <a 
    href="https://stores.sportkingfashion.com/?lat=28.4915663&long=77.0769417&shared=1" 
    target="_blank" 
    rel="noopener noreferrer"
  >
    <img 
      src={process.env.PUBLIC_URL + "../img/emial.svg"} 
      className='icons' 
      style={elementStyle} 
      alt="logo" 
    /> 
    Store locator
  </a>
</li>

                   </ul>
                   </div>
                   </ul>
                   <ul>
                   <div className="footer--link">
               <h1 className="heading_footer">NEWSLETTER</h1>
               <div className="footer_lines"></div>
               <form onSubmit={handleLogin}>
               <ul>
                   <li> 
                       <input 
                       type="email"
                       name="email"
                       value={email}
                       onChange={handleRegistrationChange}
                   className="newsletter" placeholder="Enter Email Address"></input>
                 
                      </li>
                   <div className="error_Validtaion">{error}</div>
                   <li><button type="submit">Submit</button></li>
                   </ul>
               </form>
                   
                   </div>
                   </ul>
               </div>
               

           </div>
          
        </div>
        <hr></hr>
        <div className="continer">
        <div className="row">
        <div className="col-md-12">
                    <p className="sportliking_footer" >All Right Reserved | The Sportking | © {currentYear}</p>
                </div>
           </div>
           <div className={`back-to-top ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
               <FaArrowCircleUp className="arrow-icon" />
           </div>
        </div>
        
        </div>

      )}
    </div>
    <ToastContainer />
        </>
    )
}

export default Footer;
