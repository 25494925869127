import React, { useContext,useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IMAGE_BASE_URL } from '../Config/constant';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToCart, addWishlist, removeWishlist } from "../Controller/commonController";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CiHeart } from "react-icons/ci";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import { Store } from '../Redux/store';

const Kidhome_slider = ({bestseller}) => {
  const { homeBanner, getCart, wishlist, wishlistData } = useContext(Store);
  const settings = {
    dots: false,
  infinite: false, 
  arrow:false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1300, 
      settings: {
          slidesToShow: 3,
          slidesToScroll: 1
      }
  },
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
    // Add more breakpoints and settings as needed
]
  };
// 
const [selectedSize, setSelectedSize] = useState(null);
const [productSieColor, setProductSieColor] = useState(null);
const [productDetailsSize, setProductDetailsSize] = useState(null);
const [tempData, setTempData] = useState("");
const [showSizepopup, setShowSizepopup] = useState(false);
const [content, setContent] = useState("");
const [error, setError] = useState(null);
const [showModal, setShowModal] = useState(false);

const handleClose = () => {
  setShowModal(false);
  setShowSizepopup(false);
  handleNavigate();
  getCart();
  setSelectedSize(null);
  setError("");
};

const handleNavigate = () => {
  if (selectedSize === null) {
    setError("Please Select Size");
  } else {
    setSelectedSize(null);
    if (tempData?._id) {
      handleWishlist(tempData?._id);
    }
    handleCart();
    setShowSizepopup(false);
  }
};


const isProductInWishlist = (productId) => {
  return (
    (wishlistData &&
      wishlistData?.some((item) => item?.product_id?._id === productId)) ||
    false
  );
};
const handleCart = async (productDetails, data) => {

  setProductSieColor(data);
  if (selectedSize === null) {
    const temp = { productDetails, data };
    setTempData(temp);
    setShowSizepopup(true);
  } else {
    try {
      const response = await addToCart(
        productDetails || tempData.productDetails,
        selectedSize,
        productSieColor
      );
      if (response) {
        setContent({ h1: "Added to Cart", p: null });
        // setShowPopup(true);
        toast.success("Added to Cart");
        getCart();
        setSelectedSize(null);
        setError("");
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      // Handle error here, e.g., show error message
    }
  }
};
const handleWishlist = async (id) => {
  if (selectedSize === null) {
    setError("Please Select Size");
    return;
  }
  const response = await addWishlist(id, productSieColor, selectedSize);
  if (response.status === true) {
    wishlist();
    setContent({ h1: "Added to Wishlist", p: null, h7: "Ok" });
    // setShowPopup(true);
    toast.success("Added to Wishlist!");
  }
};

const handleHeartClick = (item, data, status) => {

  if (status === true) {
    handleRemoveWishlist(item);
    return;
  }
  setProductSieColor(data);
  setTempData(item);
  if (selectedSize === null) {
    // setError("Please Select Size");
    setShowSizepopup(true);
    return;
  }
  handleWishlist(item._id);
};

const handleRemoveWishlist = async (item) => {
  try {
    const data = {
      product_id: item._id,
    };
    const response = await removeWishlist(data);
    if (response) {
      setContent({ h1: "Item removed from wishlist.", p: null, h7: "Ok" });
      // setShowPopup(true)
      toast("Item removed from wishlist");
      wishlist();
    }
  } catch (error) {
    console.error(error);
  }
};


// 
  return (
    <div className='best_selleres'>
      <Slider {...settings}>
      {bestseller && bestseller?.length > 0 ? bestseller?.map((item,index)=>(
        <div className='best_seller_slider' key={index}>
        <img src={IMAGE_BASE_URL+item.color_size_details[0].images[0]} alt="logo" className='products_img' />
        <div className="social_icon">
                        <div className="select_product heart">
                          {isProductInWishlist(item._id) ? (
                            <FontAwesomeIcon
                              icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                              style={{
                                color: "#df1b22",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                const data =
                                
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          ) : (
                            <CiHeart
                              className="heart_icon profile_header"
                              onClick={() => {
                                const data =
                                
                                  item.color_size_details[0];
                                const status = isProductInWishlist(item._id);
                                handleHeartClick(item, data, status);
                              }}
                            />
                          )}
                        </div>
                        <img
                          src={process.env.PUBLIC_URL + "./img/solar_bag.svg"}
                          alt="logo"
                          onClick={() => {
                            const data =
                            item.color_size_details[0];
                            handleCart(item, data);
                          }}
                        />
                      </div>
        <p className='sportking_seller'>{item.product_title}</p>
        <p className='besiser_down'>{item.product_name}</p>
        <h1 className='price'>₹{item.color_size_details[0].selling_price}</h1>
        </div>
            )) : '' }
     
        {/* <div className='best_seller_slider'>
        <img src={process.env.PUBLIC_URL + "./img/kid_slides_three.png"} alt="logo" className='products_img'/>
        <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag-3-linear.svg"} alt="logo"  />
        </div>
        <p className='sportking_seller'>sportking  |  Mentor Addition</p>
        <p className='besiser_down'>Beiser Short Down Jacket</p>
        <h1 className='price'>₹2,075.00</h1>
        </div> */}
       
      </Slider>
      <Modal
        show={showSizepopup}
        onHide={handleClose}
        id="feedback_popup"
        className="select-size"
        centered
      >
        <div className="cross py-0">
          <img
            src={process.env.PUBLIC_URL + "./img/ball_icon.svg"}
            alt="logo"
            className="model_logo"
          />

          <img
            src={process.env.PUBLIC_URL + "./img/cross.svg"}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div>
          <h1>Please Select a Size</h1>
          <div className="product-size">
            <ul>
              {productSieColor &&
                productSieColor?.size[0].split(",").map((size, index) => (
                  <li
                    key={index}
                    className="cursor-pointer "
                    style={{
                      // backgroundColor: 'red',
                      border: selectedSize === size ? "2px solid black" : "",
                    }}
                    onClick={() => setSelectedSize(size)}
                  >
                    {size}
                  </li>
                ))}
            </ul>
            {error ? (
              <p className="error_Validtaion" style={{ color: "red" }}>
                {error}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {content.p === !null ? (
          <Modal.Body className="py-0">
            <div className="thank_you_popup">
              <div className="row">
                <div className="col-md-4">
                  <h2>10%</h2>
                  <p className="discount_voucher">Discount Voucher</p>
                </div>
                <div className="col-md-8 px-0">
                  <h2 className="thank_you_heading"> Thank you</h2>
                  <p className="inner_content">
                    {" "}
                    for ordering from sportking Use code <span>
                      ”THANKS”
                    </span>{" "}
                    at checkout for 10% discount.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          ""
        )}
        <div className="model_footer">
          <button className="view_cart" onClick={handleNavigate}>
            {content?.h3 ? "View my orders" : "Proceed To Next"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Kidhome_slider;
