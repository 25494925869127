import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IMAGE_BASE_URL } from '../Config/constant';

const Product_detail_slider = ({reviews,pImage}) =>{
  const settings = {
    dots: false,
    infinite: true, 
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    responsive: [
        {
            breakpoint: 1024, 
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 768, 
            settings: {
                slidesToShow: 1,
                centerMode: false,
                slidesToScroll: 1
            }
        }
    ]
};

    

    return(
        <>
         <div className='customer_slider'>
      <Slider {...settings}>
        {reviews.length > 0 ? reviews?.map((item,index)=>(
            <div key={index}>
      <div className="customer_sliding">
       <div>

       <img src={process.env.PUBLIC_URL + "/img/upper_arrow.png"} alt="logo" className='upper_arrow' />
       {/* <img src={process.env.PUBLIC_URL + "./img/listing_img_first.png"} alt="logo" className='product_img_sliding_img' /> */}
       <img src={IMAGE_BASE_URL+(item?.image??pImage)} alt="product_img" className='product_img_sliding_img'/>
       </div>
       <div>
      <p>{item.review}</p>
        <h2>{item?.user_id?.firstName} {item?.user_id?.lastName}</h2>
       </div>
      </div>
      </div>
        // )):<p>No Reviews Related to this product</p>}
        )): "" }        
      {/* <div>
      <div className="customer_sliding">
       <div>

       <img src={process.env.PUBLIC_URL + "./img/upper_arrow.png"} alt="logo" className='upper_arrow' />
       <img src={process.env.PUBLIC_URL + "./img/listing_img_first.png"} alt="logo" className='product_img_sliding_img' />
       
       </div>
       <div>
        <p>Boasting an ultra-lightweight nylon and longue saison lining, the Gloas down jacket is designed to provide supreme warmth, without being heavy. The hooded puffer is enhanced.</p>
        <h2>Harpreet Kaur</h2>
       </div>
      </div>
      </div> */}
      </Slider>
    </div>

        </>
    )
}

export default Product_detail_slider;
