import { API_BASE_URL , API_KEY} from "../Config/constant";
import {postRequest} from "../Controller/API";


export const post_Request = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}${options.url}`,
            postData : options.postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res.data.response};
        } else{
            return {status : false, message:res.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function