import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Bestseller from "../Home/bestseller";
import { useState, useEffect,useContext } from "react";
import React, { useRef } from "react";
import Customer_slider from "./Customer_slider";
import Product_detail_slider from "./Product_detail_slider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./product.css";
import { addToCart, addWishlist, removeWishlist } from "../Controller/commonController";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import { Modal } from "react-bootstrap";
import { CiHeart } from "react-icons/ci";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { post_Request } from "../Controller/Home_controller";
import { useParams } from 'react-router-dom';
import { post_RequestSearch } from "../Controller/Login";

const Prodcut_deatil = () => {
  const {getCart,wishlist,wishlistData}=useContext(Store)
  const navigate=useNavigate()
  const { state } = useLocation();
  const { slug } = useParams();

  function slugToName(slug) {
    // Step 1: Replace double hyphens with a placeholder
    const placeholder = '_placeholder_';
    const interim = slug.replace(/--/g, placeholder);
  
    // Step 2: Split by single hyphens, capitalize each word, and join with a space
    const result = interim
      .split('-')                       // Split by single hyphens
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(' ');                       // Join words with a single space
  
    // Step 3: Replace the placeholder with single hyphens
    return result.replace(new RegExp(placeholder, 'g'), '-');
  }
  

  const[content,setContent]=useState('')
  const[showPopup,setShowPopup]=useState(false)
  const [openSizeChart,setOpenSizeChart]=useState(false)
  const [isHeartClicked, setIsHeartClicked] = useState(false);
  const [reviews,setReview]=useState('')
  const [svgColor, setSvgColor] = useState("#fff");
  const [thumbSrc, setThumbSrc] = useState(
    process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"
  );
  const [productDetails, setProductDetails] = useState(state?.item || state?.temp );
  const [productSieColor, setProductSizeColor] = useState(state?.data ?? null);
  const images_url = "https://sportking.s3.eu-north-1.amazonaws.com/image/";
  const [selectedSize, setSelectedSize] = useState(null);
  const [error, setError] = useState("");
  const handleMouseOver = (event) => {
    const newSrc = event.target.dataset.thumb;
    setThumbSrc(newSrc);
  };  // onclick change select-product
  const handleClose=()=>{
    setOpenSizeChart(false)
  }
  const handleHeartClick = () => {
    if(!isProductInWishlist(productDetails)){
    if (selectedSize === null) {
      setError("Please Select Size");
      return
    }
  }
    handleWishlist();
    setIsHeartClicked(!isHeartClicked);
  };
  const [selectedColor, setSelectedColor] = useState(productSieColor);
  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setProductSizeColor(color);
  };

  const handleSerach= async()=>{
    try{
      const options = {
          url: `/common/search?productName=${slugToName(slug)}`,
        };
      const response = await post_RequestSearch(options)
  
  const responseData= response.result.data;
  
  if(responseData.length > 0){
    // navigate('/Listing', { state: { responseData: responseData, "searched": true } });
    const data=response.result.data;
    setProductDetails(data[0]);
    setProductSizeColor(data[0]?.color_size_details[0])
  }else
  {

    // setNoDataMatches(true)
  }
  }catch(error){
  console.error(error)
  }
  }
  

  const getReviews = async () => {
    try {
      const options = {
        url: "/order/get_product_reviews",
        postData: {
          product_id: state?.item?._id,
        },
      };
      await post_Request(options).then((response) => {
        setReview(response.result.result);
      });
    } catch (error) {
      console.error(error);
    }
  };


useEffect(() => {
  if (!state) {
    handleSerach()
  }
}, []);
  useEffect(() => {
    getReviews()
    window.scrollTo(0, 0);
  }, []);
  const [check,setCheck]=useState(true)

  const handleRemoveWishlist = async item => {
    try {
      const data = {
        product_id: item._id
      }
      const response = await removeWishlist(data)
      if (response) {
        setContent({ h1: 'Item removed from wishlist.', p: null ,h7:"Ok"})
        toast('Item removed from wishlist.');
        // setShowPopup(true)
        wishlist()
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleWishlist = async () => {
    if(!isProductInWishlist(productDetails)){
      setCheck(false)
      if (selectedSize === null) {
        setError("Please Select Size");
        return
      }
      const response=await addWishlist(productDetails._id,productSieColor,selectedSize)
        if(response.status===true){
          wishlist()
          setContent({ h1: "Added to Wishlist", p: null , h7:"Ok" });
          toast.success('Added to Wishlist!');
          // setShowPopup(true);
        }
    }else{
      handleRemoveWishlist(productDetails)

    }
  };

  const handleAddtoCart = async () => {
    if (selectedSize === null) {
      setError("Please Select Size");
    } else {
      try {
        const response=await addToCart(productDetails, selectedSize, productSieColor)
        if(response){
          setContent({ h1: "Added to Cart", p: null, h4:"Proceed To Checkout",Link:"/add_new_address" });
          toast.success("Added to Cart");
          // setShowPopup(true);
          getCart()
        }

      } catch (error) {
        console.error("Error adding to cart:", error);
        // Handle error here, e.g., show error message
      }
    }
  };
  const handleSizeSelect = async (size) => {
    setError('')
    setSelectedSize(size);
  };
  const modifiedDescriptionContent = productDetails?.product_description;
  const descriptionContent = modifiedDescriptionContent?.replace(/<span style="(.*?)">(.*?)<\/span>/g, '<p style="$1">$2</p>');
 
  const isProductInWishlist = (productDetails) => {
    return wishlistData&& wishlistData?.some((item) => item?.product_id?._id === productDetails?._id) || false;
  };

  const desiredOrder = ["S", "M", "L", "XL", "XXL"]; // Define the desired order

  const sortedSizes = productSieColor?.sizes.sort((a, b) => {
    const indexA = desiredOrder.indexOf(a.size);
    const indexB = desiredOrder.indexOf(b.size);
  
    // Sort based on the desired order if present, otherwise natural order
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return a.size.localeCompare(b.size); // Natural order for sizes not in desiredOrder
    }
  });

  return (
    <>
      <Header/>

      <div className="product-inner-details">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <Customer_slider
                productSieColor={productSieColor}
              ></Customer_slider>
            </div>
            <div className="col-md-8">
              <div className="product_cart_Detail">
                <div className="select_product heart">
                  {isProductInWishlist(productDetails) ? 
                  <FontAwesomeIcon
                    icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                    style={{
                      color:  "#df1b22" ,
                      cursor: "pointer",
                    }}
                    onClick={handleHeartClick}
                  />:
                          <CiHeart className="heart_icon profile_header"           onClick={handleHeartClick} />
                  }
                </div>
                <div className="product_main_heading">
                  <p>{productDetails?.product_title}</p>
                  <h2>{productDetails?.product_name}</h2>
                  <p className="price">₹ {productSieColor?.selling_price??productSieColor?.sizes[0]?.selling_price}</p>
                  <p className="product_color">
                    Color : {productSieColor?.color_name}
                  </p>
                </div>
                <div className="color_box">
                  <ul>
                    {productDetails?.color_size_details?.map((color, index) => (
                      <div
                        key={index}
                        className="color-square cursor-pointer "
                        style={{
                          backgroundColor: color.color_code,
                          border:
                            color?._id === selectedColor?._id
                              ? "1px solid black"
                              : "none", // Apply no border for other colors
                        }}
                        onClick={() => handleColorSelect(color)}
                      ></div>
                    ))}
                    {/* <li data-thumb="https://via.placeholder.com/150x100/0000ff" onMouseOver={handleMouseOver}></li> */}
                  </ul>
                  <p>
                    (
                    {productDetails?.color_size_details
                      ?.map((color) => color.color_name)
                      .join(" , ")}
                    )
                  </p>
                </div>
                <div className="product_size_heading">
                  <p>Size :</p>
                  <p className="size_guide" onClick={()=>{setOpenSizeChart(true)}}>See Size Guide</p>
                </div>
                <div className="product-size">
                <ul>
    {sortedSizes?.map((size, index) => (
      (size.productshow === 'true' || size.productshow === true) ? (
        <li
          key={index}
          className={`cursor-pointer ${size.selling_price === '0' ? 'blurred' : ''}`} 
          style={{
            border: selectedSize === size.size ? "2px solid black" : "",
          }}
          onClick={() => {handleSizeSelect(size.size); setProductSizeColor(prev => ({ ...prev, selling_price: size.selling_price }));}}
        >
          {size.size}
        </li>
      ) : null
    ))}
  </ul>
                  {error ? <p className="error_Validtaion">{error}</p> : ""}
                </div>
                <div className="inline-btn">
                  <button onClick={handleAddtoCart}>
                  Add To Cart
                    {/* <Link to="/Cart">Add To Cart</Link> */}
                  </button>
                  <button onClick={()=>{
                    navigate('/Location')
                  }} >Find In Store</button>
                </div>
                <div className="description_heading">
                  <h2 className="common_heading">Description :</h2>
                  <p dangerouslySetInnerHTML={ {__html:descriptionContent}} />
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our_customer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="common_heading">See What Our Customer Says</h1>
              <p className="common_text">
              The Most-Coveted Essentials. Curated By Us, Inspired By You.
                                           
              </p>
            </div>
          </div>
        </div>
        <Product_detail_slider reviews={reviews} pImage={productSieColor?.images[0]}></Product_detail_slider>
      </div>
      <div className="best_seller mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 featuered_product">
              <h1 className="common_heading bestseller">Other similar products</h1>
              <p className="common_text">
              The Most-Coveted Essentials. Curated By Us, Inspired By You.
                                           
              </p>
            </div>

            <div className="col-md-6">
              <div className="shop_now_seller ">
                <button className='profile_header' onClick={()=>{navigate('/Listing',{state:'latest_arrival'})}}>Shop Now</button>
              </div>
            </div>
          </div>

          <div className="row" id='product_details_best_seller'>
            <Bestseller></Bestseller>
          </div>
        </div>
      </div>
      <div className="footer_top">
      <Footer ></Footer>
      </div>
      {/* <ThankYoupopup isOpen={showPopup} content={content}/> */}

      <Modal show={openSizeChart} onHide={handleClose}  id="feedback_popup" centered className="sizecart">
          <div className='cross py-0'>
          <img src={`${images_url}ball_icon.svg`} alt="logo" className="model_logo" />
         
          <img  src={`${images_url}cross.svg`} alt="logo"  onClick={handleClose} id="icon" className="model_logo" />
          
          </div>
        <div>


       
        </div>
 
        <Modal.Body className="py-0">
 
    <img src={IMAGE_BASE_URL+state?.item.size_image} alt="sizechart" style={{width:"100%",padding:"0",margin:"0"}}/>
 
        </Modal.Body>
    
        
       
      </Modal>
      <ToastContainer />
    </>
  );
};
export default Prodcut_deatil;
