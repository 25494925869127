import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import Main_slider from "../Home/Main_slider";
import Bestseller from "../Home/bestseller";
import Sportking from "../Home/sportking";
import ImageGallery from "../Home/Imagegallery";
import { useContext, useEffect, useState ,useRef} from "react";
import { Skeleton } from "antd";
import { Store } from "../Redux/store";
import Kidhome_slider from "./kidhome_slider";
import { Modal } from "react-bootstrap";
import "../Home/home.css";
import "./woodbern.css";
import { IMAGE_BASE_URL } from "../Config/constant";
import { post_Request } from "../Controller/Home_controller";

const Kid_homepage = () => {
  const [woodbunrPopup,setWoodbunrPopup]=useState(true)
  const handleClose =()=>{
    // setWoodbunrPopup(false)
    navigate('/')
  }
  const navigate = useNavigate();
  const { kidsBannerProductData,homeBanner,trickers } = useContext(Store);
  const banner=homeBanner?.kidBannerData?.length > 0 ? homeBanner?.kidBannerData[0]:null
  console.log(banner,"99")
  const [loaded, setLoaded] = useState(false);
  const [bestSellerProduct,setBestSellerProduct]=useState('') 
  
  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const imageStyle = {
    transform: loaded ? "translate(-630, 0)" : "translate(0px, 0)", // Move from initial position to final position
    transition: "transform 0.1s ease", // Smooth transition over 0.5 seconds
    width: "100%",
    objectFit: "fill",
    opacity: loaded ? 1 : 0, // Using opacity for smoother transition
  };

const getProduct=async(product_ids)=>{
try{
const options={
  url: "/common/get-products",
  postData: {
      condition: { 
      status : "A",
      _id: product_ids
},   limit     : 12,
page     : 1
  }}
  await post_Request(options).then((response)=>{
    setBestSellerProduct(response.result.productData)
  })
}catch(error){
console.log(error)
}
}
useEffect(() => {
  const fetchData = async () => {
    const product_ids = await kidsBannerProductData?.bestSellers?.map(item => item.product_id);
    await getProduct(product_ids);
  };

  fetchData(); // Call the async function immediately
  
}, []);

useEffect(()=>{
  window.scrollTo(0, 0);
  },[])
  const parts =  trickers[0]?.tricker.split('|');
  return (
    <>
      <Header></Header>
      <div  className={`kid_homepage content-wrapper ${woodbunrPopup ? 'blur' : ''}`}> 
        <div className="kids_video_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-3 col-12">
              <video className="top_banner" controls poster={IMAGE_BASE_URL + banner?.banner_image_1}>
    <source src={IMAGE_BASE_URL+banner?.video_url} type="video/mp4" />
    Your browser does not support the video tag.
</video>

              </div>
              <div className="col-md-12  col-lg-9 col-12">
                <img
                  // src={process.env.PUBLIC_URL + "./img/banner_img.png"}
                  src={IMAGE_BASE_URL + banner?.banner_image_2}
                  alt="logo"
                />
                {/* <div className="kid_banner_section">
                  <h1>
                  New Arrivals In On-Trend Relaxed Fits Are Here!
                  </h1>
                  <p>
                  The Most-Coveted ,<br></br> Essentials. Curated by Us, <br></br>Inspired By You.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="shop_now">
          <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                {/* New Styles on Sale: Up to 40% Off Shop All Our New Markdowns {" "} */}
                {trickers?.length > 0 ? parts[0]:''}
                | <span className="discount"> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>{trickers?.length > 0 ?parts[1]:''}</Link></span>
              </p>
            </div>
          </div>
          </div>
        </div>

        <div className="relax_section" id={"section1"}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="common_heading arriavals mb-0">
                New Arrivals In On-Trend Relaxed Fits Are Here!
                </h1>
                <p className="common_text">
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-6">
                {kidsBannerProductData?.latestArrivalData?.length > 0 ?  (
                  kidsBannerProductData?.latestArrivalData
                    ?.slice(0, 1)
                    .map((item, index) => (
                      <div className="right_side_img drop-left" key={index}>
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          alt="latestkids"
                          style={{
                            ...imageStyle,
                            display: loaded ? "block" : "none",
                          }}
                          onLoad={handleImageLoaded}
                          className="relax"
                        />
                        {/* { loaded&&
                        <div className="arrivales">
                          <h1>Latest Arrival</h1>
                          <p>The Most-Coveted Essentials. Curated 
                                            .</p>
                        </div>
} */}
                      </div>
                    ))
                ) : (
                  <div className="right_side_img drop-left">
                    {Array.from({ length: 1 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={500}
                          shape="square"
                          active
                          style={{ height: "600px" }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-md-12 col-lg-6">
                {kidsBannerProductData?.latestArrivalData?.length > 0
                  ? kidsBannerProductData?.latestArrivalData
                      ?.slice(1, 2)
                      .map((item, index) => (
                        <div className="arrivales_sublime_right" key={index}>
                          <img
                            src={IMAGE_BASE_URL + item.image}
                            alt="latest kids2"
                          />
                          <div className="arrvals_box_user">
                            <h2>Latest Arrival</h2>
                            <p>The Most-Coveted Essentials. Curated 
                                            By Us, Inspired By You.</p>
                          </div>
                        </div>
                      ))
                  : Array.from({ length: 1 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={500}
                          shape="square"
                          active
                          style={{ height: "600px" }}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>

        <div className="featuered_product" id={"section1"}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="common_heading arriavals">
                  Our Featured Products
                </h1>
                <p className="common_text">
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                </p>
              </div>
              {kidsBannerProductData?.featuredProduct?.length > 0
                ? kidsBannerProductData?.featuredProduct?.map((item, index) => (
                    <div
                      className="col-md-3 col-6"
                      key={index}
                      onClick={() => {
                        const data = {
                          mentorLatestCol: {
                            subcategory_id: item.subcategory_id,
                            type: item.type,
                            featured_products_type: item.title_slug,
                          },
                        };
                        navigate("/Listing", { state: data });
                      }}
                    >
                      <img
                        src={IMAGE_BASE_URL + item.image}
                        alt="Kids featured product"
                      />
                      <p className="kids_product_name">{item.title}</p>
                    </div>
                  ))
                : Array.from({ length: 10 }, (_, i) => (
                    <div className="col-md-3" key={i}>
                      <Skeleton.Avatar
                        size={250}
                        shape="square"
                        active
                        style={{ height: "250px" }}
                      />
                    </div>
                  ))}
              {/* <div className="col-md-3">
     <img src={process.env.PUBLIC_URL + "./img/kid_one.png"} alt="Kids featured product" />
      </div> */}
            </div>
          </div>
        </div>

        <div className="best_seller mb-0" id="best_seller_home">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 featuered_product">
                <h1 className="common_heading bestseller">Our Bestsellers</h1>
                <p className="common_text">
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                </p>
              </div>

              <div className="col-md-12 col-lg-6 ">
                <div class="shop_now_seller">
                <button onClick={() => {
    const data = {
        mentorLatestCol: {
            subcategory_id: bestSellerProduct?.length > 0 ? bestSellerProduct[0].subcategory_id : '',
            type: kidsBannerProductData?.length > 0 ? kidsBannerProductData?.bestSellers[0].type : 'bestsellers'
        }
    };
    navigate("/Listing", { state: data });
}}>Shop Now</button>

                </div>
              </div>
            </div>

            <div className="row">
              <Kidhome_slider bestseller={bestSellerProduct}></Kidhome_slider>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_top mt-0">
      <Footer ></Footer>
      <Modal show={woodbunrPopup} onHide={handleClose}  id="feedback_popup2" centered size="md" backdrop="static"  >
          <div className='cross py-0'>
          <img src="../img/sprtkinglatestlogo.png" alt="woodern" style={{width:"100%",padding: "9% 17% 0"}}/>
          {/* <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" /> */}
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" style={{height:"15px",marginTop:"5px",marginLeft:"-22px"}}/>
          
          </div>
        <div>


       
        </div>
 
        <Modal.Body className="py-0">
<h4 style={{paddingTop:"4%"}}> Coming Soon</h4>
<p style={{paddingBottom:"5%",paddingBottom:"10%"}}>"Stay Tuned for our upcoming collection."</p>
    {/* <img src={IMAGE_BASE_URL+state?.item.size_image} alt="sizechart" style={{width:"100%",padding:"0",margin:"0"}}/> */}
 
        </Modal.Body>
    
        
       
      </Modal>
      </div>
    </>
  );
};
export default Kid_homepage;
