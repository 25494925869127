import Header from "../component/Header";
import Footer from "../component/Footer";
import "./cart.css";

const Cart_guest_user = () =>{
  
    return(
        <>
        <Header></Header>
        
        <div className="container mt-3 mb-5">
            <div className="row">
                <div className="col-md-8">
                <p className="heading mt-3">Enter Shipping Address</p>
                <hr className="mt-2" id="address_under_line"></hr>
                

                <div>
                    <form>
                <div className="row mt-1">
                    <div className="col-md-4 padding-right">
                        <input type="text" className="form_group" placeholder="First Name" />
                    </div>
                    <div className="col-md-4 padding-right">
                    <input type="text" className="form_group" placeholder="Last Name" />
                    </div>
                    <div className="col-md-4">
                    <input type="number" className="form_group" placeholder="Mobile Number"/>
                    </div>

                    <div className="col-md-12">
                        <input type="text" className="form_group" placeholder="Address Line 1" />
                    </div>

                    <div className="col-md-12">
                        <input type="text" className="form_group" placeholder="Address Line 2" />
                    </div>

                    <div className="col-md-4 padding-right">
                        <select  className="form_group">
                           <option>Countery</option>
                           <option>India</option>
                           <option>Dubai</option>
                        </select>
                        
                    </div>
                    <div className="col-md-4 padding-right">
                    <select  className="form_group">
                           <option>State</option>
                           <option>Up</option>
                           <option>Banglore</option>
                           <option>Pune</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                    <select  className="form_group">
                           <option>City</option>
                           <option>Noida</option>
                           <option></option>
                        </select>
                    </div>

                    <div className="col-md-12">
                        <div className="saving_address">
                            <input type="radio" />
                            <p className="heading">Save For Future Address</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                    <div className="proceed_checkout" style={{ textAlign: 'left' }}>
                        <button className="mt-3">Save & Continue</button>
                    </div>
                    </div>
                   
                </div>

                
                    
               
                    </form>
                </div>    
                </div>
                <div className="col-md-4">
                  <p className="heading">Payment Details</p>
                  <hr className="heading_line"></hr>

                  <div className="coupen_code">
                    <input type="text" placeholder="Apply coupon Code"></input>
                  <img src={process.env.PUBLIC_URL + "./img/coupen.svg"} alt="logo" />
                  </div>

                   <div className="detail_price">
                    <div className="">
                    <h1>detail</h1>
                    <h1>Price</h1>
                    </div>
                     
                     <hr></hr>
                    <div className="">
                    <p>Sub Total</p>
                    <p>₹ 20,000</p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p>Coupon Discount</p>
                    <p className="disoucnt_coupen_price"><span className="discount_price">- ₹ 2,000 </span></p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p>Delivery Charges</p>
                    <p>₹ 20,000</p>
                    </div>
                    <hr></hr>
                    <div className="">
                    <p>Other Charges</p>
                    <p>₹ 20,000</p>
                    </div>
                  
                    
                   </div>
                   <div className="mt-3 py-2 cart_total">
                    <p>Total</p>
                    <p>₹ 20,000</p>
                    </div>
                   <div className="order_item">
                    <p className="heading">Items In Your Order</p>
                <hr className="heading_line"></hr>

                <div className="cart_product_deatil">
                 <div className="cart_product_img">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo" />
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                
                 </div>
                </div>
                <hr className="mt-3"></hr>
                <div className="cart_product_deatil">
                 <div className="cart_product_img">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo" />
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                
                 </div>
                </div>

               
                </div>
                </div>
            </div>


            
        </div>
     
        <div className="footer_top">
      <Footer ></Footer>
      </div>
        </>
    )
}

export default Cart_guest_user;
