import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import Bestseller from "../Home/bestseller";
import { Modal } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import Features_woodbern from "./features_woodbern";
import "../Home/home.css";
import "./woodbern.css";
import { Skeleton } from "antd";
import { Store } from "../Redux/store";
import { IMAGE_BASE_URL } from "../Config/constant";
import { post_Request } from "../Controller/Home_controller";

const Woodburn = () => {
  const navigate = useNavigate();
  const { woodBurnBannerProductData, homeBanner,trickers } = useContext(Store);
  const banner =
    homeBanner?.WoodburnBannerData?.length > 0
      ? homeBanner?.WoodburnBannerData[0]
      : null;
  const [bestSellerProduct, setBestSellerProduct] = useState("");
  const [woodbunrPopup,setWoodbunrPopup]=useState(true)
  const handleClose =()=>{
    // setWoodbunrPopup(false)
    navigate('/')
  }
  const [loaded, setLoaded] = useState(false);
  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const imageStyle = {
    transform: loaded ? "translate(-630, 0)" : "translate(0px, 0)", // Move from initial position to final position
    transition: "transform 0.1s ease", // Smooth transition over 0.5 seconds
    width: "100%",
    objectFit: "fill",
    opacity: loaded ? 1 : 0, // Using opacity for smoother transition
  };
  const handleLatestNavigate = (item) => {
    const data = {
      mentorLatestCol: {
        subcategory_id: item.subcategory_id,
        type: item.type,
      },
    };
    navigate("/Listing", { state: data });
  };
  const getProduct = async (product_ids) => {
    try {
      const options = {
        url: "/common/get-products",
        postData: {
          condition: {
            status: "A",
            _id: product_ids,
          },
          limit: 12,
          page: 1,
        },
      };
      await post_Request(options).then((response) => {
        setBestSellerProduct(response.result.productData);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const product_ids = await woodBurnBannerProductData?.bestSellers?.map(
        (item) => item.product_id
      );
      await getProduct(product_ids);
    };

    fetchData(); // Call the async function immediately
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const parts =  trickers[0]?.tricker.split('|');
  return (
    <>
      <Header></Header>
      <div  className={`woodburn content-wrapper ${woodbunrPopup ? 'blur' : ''}`}>
        <div className="main_slider">
          <div className="container">
            <video
              className="top_banner"
              controls
              poster={IMAGE_BASE_URL + banner?.banner_image_1}
            >
              <source
                src={IMAGE_BASE_URL + banner?.video_url}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="shop_now">
          <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                {/* New Styles on Sale: Up to 40% Off Shop All Our New Markdowns {" "} */}
                {trickers?.length > 0 ? parts[0]:''}
                | <span className="discount"> <Link to='/Listing' style={{textDecoration:'none',color:"inherit"}}>{trickers?.length > 0 ?parts[1]:''}</Link></span>
              </p>
            </div>
          </div>
          </div>
        </div>

        <div className="features woodburn">
          <div className="container_features">
            <div className="row">
              <div className="col-md-4">
                <div className="right_side">
                  <img src="./img/rightbg.png"></img>
                  <div className="content">
                    <h2>Our Featured Products</h2>
                    <p>
                      The Most-Coveted Essentials. Curated By Us, Inspired By
                      You.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <Features_woodbern
                  featuredProduct={woodBurnBannerProductData.featuredProduct}
                ></Features_woodbern>
              </div>
            </div>
          </div>
        </div>

        <div className="hot_list">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="common_heading arriavals mb-0">
                  New arrivals in on-trend relaxed fits are here!
                </h1>
                <p className="common_text">
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                </p>
              </div>
              {woodBurnBannerProductData?.latestArrivalData?.length > 0 ? (
                woodBurnBannerProductData?.latestArrivalData
                  ?.slice(0, 1)
                  .map((item, index) => (
                    <div className="col-md-6 padding-right" key={index}>
                      <div className="right_side_img drop-left ">
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          alt="logo"
                          style={{
                            ...imageStyle,
                            display: loaded ? "block" : "none",
                          }}
                          onLoad={handleImageLoaded}
                        />
                        {/* {loaded && (
                          <div className="arrivales">
                            <h1>Latest Arrival</h1>
                            <p> The Most-Coveted Essentials.</p>
                          </div>
                        )} */}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="col-md-6 padding-right">
                  <div className="right_side_img drop-left">
                    {Array.from({ length: 1 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={500}
                          shape="square"
                          active
                          style={{ height: "600px" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="col-md-6">
                {woodBurnBannerProductData?.latestArrivalData?.length > 0
                  ? woodBurnBannerProductData?.latestArrivalData
                      ?.slice(1, 2)
                      .map((item, index) => (
                        <>
                          <img
                            src={IMAGE_BASE_URL + item.image}
                            alt="logo"
                            key={index}
                          />
                          <div className="shop_now">
                            {loaded && (
                              <button
                                onClick={() => {
                                  handleLatestNavigate(item);
                                }}
                              >
                                Shop Now
                              </button>
                            )}
                          </div>
                        </>
                      ))
                  : Array.from({ length: 1 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={500}
                          shape="square"
                          active
                          style={{ height: "600px" }}
                        />
                      </div>
                    ))}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 padding-right">
                {woodBurnBannerProductData?.latestArrivalData?.length > 0 &&
                  woodBurnBannerProductData?.latestArrivalData
                    ?.slice(2, 3)
                    .map((item, index) => (
                      <div className="latest_arrivals_section" key={index}>
                        <img
                          src={IMAGE_BASE_URL + item.image}
                          alt="latest arrival"
                        ></img>
                        <h1>Latest Arrival</h1>
                        <p> The Most-Coveted Essentials. Curated </p>
                      </div>
                    ))}
                {!woodBurnBannerProductData?.latestArrivalData?.length > 0 && (
                  <div className="latest_arrivals_section">
                    {Array.from({ length: 1 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={570}
                          shape="square"
                          active
                          style={{ height: "800px" }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="col-md-6">
                {woodBurnBannerProductData?.latestArrivalData?.length > 0 ? (
                  woodBurnBannerProductData?.latestArrivalData
                    ?.slice(3, 5)
                    .map((item, index) => (
                      <div className="first_banner" key={index}>
                        <img src={IMAGE_BASE_URL + item.image} />
                        <div className="new_Arriavel_way">
                          <h1>
                            New arrivals in on-trend relaxed fits are here!
                          </h1>
                          <button
                            onClick={() => {
                              handleLatestNavigate(item);
                            }}
                          >
                            Shop Now
                          </button>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="first_banner">
                    {Array.from({ length: 2 }, (_, i) => (
                      <div key={i}>
                        <Skeleton.Avatar
                          size={570}
                          shape="square"
                          active
                          style={{ height: "340px" }}
                          className="mb-3"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="arrivals">
          <div className="container">
            <div className="row">
              {woodBurnBannerProductData?.latestArrivalData?.length > 0 ? (
                woodBurnBannerProductData?.latestArrivalData
                  ?.slice(5)
                  .map((item, index) => (
                    <div className="col-md-12" key={index}>
                      <img
                        src={IMAGE_BASE_URL + item.image}
                        alt="New Arrival"
                      ></img>
                      <div className="new_Arriavel">
                        <h1>New arrivals in on-trend relaxed fits are here!</h1>
                        <p>
                          The Most-Coveted Essentials. Curated By Us, Inspired
                          By You.
                        </p>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="col-md-12">
                  {Array.from({ length: 1 }, (_, i) => (
                    <div key={i}>
                      <Skeleton.Avatar
                        size={1110}
                        shape="square"
                        active
                        style={{ height: "500px" }}
                        className="mb-3"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="best_seller" id="best_seller_home">
          <div className="container">
            <div className="row">
              <div className="col-md-6 featuered_product">
                <h1 className="common_heading bestseller">Our Bestsellers</h1>
                <p className="common_text">
                  The Most-Coveted Essentials. Curated By Us, Inspired By You.
                </p>
              </div>

              <div className="col-md-6">
                <div class="shop_now_seller">
                  <button
                    onClick={() => {
                      const data = {
                        mentorLatestCol: {
                          subcategory_id:
                            bestSellerProduct?.length > 0
                              ? bestSellerProduct[0].subcategory_id
                              : "",
                          type:
                            woodBurnBannerProductData?.length > 0
                              ? woodBurnBannerProductData?.bestSellers[0].type
                              : "bestsellers",
                        },
                      };
                      navigate("/Listing", { state: data });
                    }}
                  >
                    Shop Now
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <Bestseller bestseller={bestSellerProduct?.bestSellers} />
            </div>
          </div>
        </div>
      </div>
      <div className="footer_top">
        <Footer></Footer>
      </div>

      <Modal show={woodbunrPopup} onHide={handleClose}  id="feedback_popup2" centered size="md" backdrop="static"  >
          <div className='cross py-0'>
          <img src="../img/wdNew.png" alt="woodern" style={{width:"100%",padding: "9% 17% 0"}}/>
          {/* <img src={process.env.PUBLIC_URL + "./img/ball_icon.svg"} alt="logo" className="model_logo" /> */}
         
          <img src={process.env.PUBLIC_URL + "./img/cross.svg"} alt="logo"  onClick={handleClose} id="icon" className="model_logo" style={{height:"15px",marginTop:"5px",marginLeft:"-22px"}}/>
          
          </div>
        <div>


       
        </div>
 
        <Modal.Body className="py-0">
<h4 style={{paddingTop:"4%"}}> Coming Soon</h4>
<p style={{paddingBottom:"5%",paddingBottom:"10%"}}>"Stay Tuned for our upcoming collection."</p>
    {/* <img src={IMAGE_BASE_URL+state?.item.size_image} alt="sizechart" style={{width:"100%",padding:"0",margin:"0"}}/> */}
 
        </Modal.Body>
    
        
       
      </Modal>
    </>
  );
};
export default Woodburn;
