import { API_BASE_URL , API_KEY} from "../Config/constant";
import {postRequest} from "./API";
/*********************************************************
* Function Name : footer email
* Description   : footer NEWSLETTER
* By            : amrit
* Date          :14-03-2024 
*********************************************************/

export const post_Request = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}${options.url}`,
            postData : options.postData
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
           
            return {status : false, message:res?.response?.data?.message}
            
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


