import Header from "../component/Header";
import Footer from "../component/Footer";
import "./maindashboard.css";
import Dashboard_sidebar from "./Dashboard_sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
const  Payments = () => {
    const navigate=useNavigate();
return(
    <>
    <Header></Header>
    <div className="container mt-3 mb-5">
        <div className="row">
            <div className="col-md-12">
                <div className="dashbaord">
                    <h1>my Account</h1>
                    <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" /><br></br>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br> typesetting industry. Lorem Ipsum has been the industry's.</p> */}
                    <button onClick={()=>{navigate('/Listing')}}>Shop Now</button>
                </div>

            </div>
        </div>
        <div className="row">
            <div  className="col-md-3">
            <Dashboard_sidebar></Dashboard_sidebar>
            </div>
            <div className="col-md-9">
                 <div className="profile_detail">
                    <h1>My Orders</h1>
                    <p>The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>

                 </div>
                  
                  <div className="row mt-3">
                  <table>
                                <tr id="headings">
                                    <th>S.No</th>
                                    <th>Date & Time</th>
                                    <th>Purchase Id</th>
                                    <th>Amount</th>
                                    <th>Product Details</th>
                                    <th>Invoice</th>
                                   
                                </tr>
                               <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload}  id="dowanload" /> Download</a></td>
                                   
                                  
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload}  id="dowanload" /> Download</a></td>
                                   
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload}  id="dowanload" /> Download</a></td>
                                   
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload}  id="dowanload" />Download</a></td>
                                 
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload} id="dowanload"  /> Download</a></td>
                                   
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload} id="dowanload" /> Download</a></td>
                                   
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td>October 10, 3:00:00pm</td>
                                    <td>#456165461</td>
                                    <td>₹ 20,000</td>
                                    <td><a href="#">View Details</a></td>
                                    <td><a href="#" className="download">  <FontAwesomeIcon icon={faDownload} id="dowanload"  /> Download</a></td>
                                    
                                </tr>

                               </tbody>
                            </table>
                  </div>

            </div>
     
        </div>
    </div>
    <div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
)
}
export default Payments;
