import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Listing.css";
import { IMAGE_BASE_URL } from "../Config/constant";
import { useNavigate } from "react-router-dom";
import { CiHeart } from "react-icons/ci";
import { FaRegHeart } from "react-icons/fa";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import { Store } from "../Redux/store";

const Mentor_addition_slider = ({
  product,
  details,
  handleCart,
  handleHeartClick,
}) => {
  const [isHeartClicked, setIsHeartClicked] = useState(true);
  const navigate = useNavigate();
  const { wishlistData } = useContext(Store);
  const settings = {
    dots: true,
    infinite: true,
    centerPadding: "20px",
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 4,
    centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          slidesToScroll: 1,
          margin: 10,
        },
      },
    ],
  };

  const myInlineStyles = {
    transform: "translate(315px, -543px) !important",
  };
  // const handleHeartClick = () => {
  //   // if (selectedSize === null) {
  //   //   setError("Please Select Size");
  //   //   return
  //   // }
  //   // handleWishlist();
  //   setIsHeartClicked(!isHeartClicked);
  // };
  const handleClick = (data) => {
    // Navigate to the product detail page with the item's data
    navigate("/Product_detail", { state: { item: details, data: data } });
  };

  const isProductInWishlist = (productId) => {
    return (
      (wishlistData &&
        wishlistData?.some((item) =>
          item?.product_id?.color_size_details.some(
            (insideItem) => insideItem._id === productId
          )
        )) ||
      false
    );
  };

  return (
    <>
      <div className="best_selleres sportking_Slider">
        <Slider {...settings}>
          {product && product.length > 0
            ? product.map((item, index) =>
                item.images.map((insideItem, id) => (
                  // onClick={()=> { const data= item[id]; navigate('/Product_detail', {state:{item,data}})}}
                  <div className="mentor_sliding" key={id} ksy={index}>
                    <img
                      src={IMAGE_BASE_URL + insideItem}
                      alt="logo"
                      className="sportking_img"
                      // onClick={()=>{const data =productListData.length > 0 &&productListData[0].color_size_details[0] ; const item=productListData[0]??'' ;navigate('/Product_detail',{state:{item,data}})}}
                      onClick={() => handleClick(item)}
                    />
                    <div className="social_icon">
                      <div>
                        {isProductInWishlist(item._id) ? (
                          <FontAwesomeIcon
                            icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                            style={{
                              color: "#df1b22",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const status = isProductInWishlist(details._id);
                              handleHeartClick(details, item, status);
                            }}
                          />
                        ) : (
                          <CiHeart
                            className="heart_icon profile_header"
                            onClick={() => {
                              const status = isProductInWishlist(details._id);
                              handleHeartClick(details, item, status);
                            }}
                          />
                        )}
                      </div>

                      <img
                        src={process.env.PUBLIC_URL + "/img/solar_bag.svg"}
                        alt="logo"
                        onClick={() => {
                          handleCart(details, item);
                        }}
                      />
                    </div>
                  </div>
                ))
              )
            : ""}

          {/* <div className="mentor_sliding_active">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className="sportking_img"/>
      <div className='social_icon' style={myInlineStyles}>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding_active">
      <img src={process.env.PUBLIC_URL + "./img/slider_large_img.png"} alt="logo"  className='sportking_img'/>
      <div className='social_icon' style={myInlineStyles}>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding_active" style={myInlineStyles}>
      <img src={process.env.PUBLIC_URL + "./img/slider_large_img.png"} alt="logo" className='sportking_img' />
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding_active" style={myInlineStyles}>
      <img src={process.env.PUBLIC_URL + "./img/slider_large_img.png"} alt="logo"  className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div>
      <div className="mentor_sliding">
      <img src={process.env.PUBLIC_URL + "./img/mentor_sliding_imgs.png"} alt="logo" className='sportking_img'/>
      <div className='social_icon'>
        <img src={process.env.PUBLIC_URL + "./img/ph_heart-bold.svg"} alt="logo"  />
        <img src={process.env.PUBLIC_URL + "./img/solar_bag.svg"} alt="logo"  />
        </div>
      </div> */}
        </Slider>
      </div>
    </>
  );
};

export default Mentor_addition_slider;
