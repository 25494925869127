import { getSessionForLoginUser } from "../utils/helper";
import { fetchSystemAddress, postRequest } from "./API";
import { post_Request } from "./Home_controller";

export const generateRandomIP = async () => {
  const num1 = Math.floor(Math.random() * 256);
  const num2 = Math.floor(Math.random() * 256);
  const num3 = Math.floor(Math.random() * 256);
  const num4 = Math.floor(Math.random() * 256);

  return `${num1}.${num2}.${num3}.${num4}`;
};
const dateString = "2024-03-12T10:15:42.214Z";
const User = getSessionForLoginUser("User");
export const addWishlist = async (
  product_id,
  productSieColor,
  selectedSize
) => {
  try {
    const options = {
      url: "/order/add-wishlist",
      postData: {
        product_id: product_id,
        ...(productSieColor
          ? { product_color: productSieColor.color_name??productSieColor?.product_color }
          : {}),
        ...(productSieColor
          ? {
              product_image:
                productSieColor?.images?.length > 0
                  ? productSieColor?.images[0]
                  : productSieColor?.image?productSieColor?.image:"",
            }
          : {}),
        ...(productSieColor ? { product_size: selectedSize } : {}),
        ...(productSieColor
          ? { product_price: productSieColor.selling_price??productSieColor?.product_price }
          : {}),
          ...(productSieColor?.product_name ? { product_name: productSieColor?.product_name } : {}),
          ...(productSieColor?.product_title ? { product_title: productSieColor?.product_title } : {}),
        ...(User?._id ? { user_id: User?._id } : {}),
        current_ip: await fetchSystemAddress(),
        // created_date: new Date(dateString)
      },
    };

    const res = await post_Request(options);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
export const addToCart = async (product, size, color, incDec) => {
  const qty = incDec ?? 1;
  const cartId = product?.cartId??'';
  const productImage = color?.images ? color?.images[0] : color?.bestSellerProductImage?color?.bestSellerProductImage:color;
  const url =
    product?.qty === 1 && incDec === -1
      ? "/order/remove-cart"
      : "/order/addtoCart";
  try {
    const options = {
      url: url,
      postData: {
        ...(cartId && { _id: cartId }),
        product_id: incDec ? product?.product_id : product?._id,
        product_name: product?.product_name,
        product_image: color.product_image ?? productImage,
        color: color?.color_name || color,
        size: size,
        qty: qty,
        price:
          color?.selling_price?.toString() ??
          product?.product_price?.toString(),
        ...(User ? { user_id: User?._id } : {}),
        current_ip: await fetchSystemAddress(),
        // created_date:new Date(dateString)
      },
    };
    const result = await post_Request(options);
    return result?.result?.result||result.result;
  } catch (error) {
    console.error(error);
  }
};

export const removeWishlist = async (data) => {
  try {
    const options = {
      url: "/order/remove-wishlist",
      postData: {
        product_id: data.product_id,
        ...(User?._id ? { user_id: User?._id } : {}),
        current_ip: await fetchSystemAddress(),
        // created_date: new Date(dateString)
      },
    };

    const res = await post_Request(options);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};
