import Header from "../component/Header";
import Footer from "../component/Footer";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { post_Request } from "../Controller/Blog_Controller";
import { IMAGE_BASE_URL } from "../Config/constant";
const Blog_deatil = () => {
  const location = useLocation();
  const { title, description, image, createdAt, category } = location.state;
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);
  const getBlogs = async () => {
    const options = {
      url: "/common/get-blogs",
      postData: {
        type: "",
        condition: {},
        select: {},
        sort: { _id: -1 },
        skip: 0,
        limit: 10,
      },
    };
    const response = await post_Request(options);
    if (response && response.result && response.result.blogData) {
      const blogs = response.result.blogData.map((blog) => ({
        ...blog,
        createdAt: new Date(blog.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      }));
      const filteredBlogData = blogs.filter(
        (blog) => blog.blog_category.blog_category_name === category
      );
      setBlog(filteredBlogData);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header></Header>
      <div className="container" id="blog_detail">
        <div className="row">
          <div className="col-md-12">
            <p className="mb-2">{createdAt}</p>
            <h1 className="mb-4">{title}</h1>
            <img src={IMAGE_BASE_URL + image} alt="blog_detail" />
          </div>
          <div className="col-md-12 mt-4">
            <div dangerouslySetInnerHTML={{ __html: description }} />

            {/* <p className="blog-inner-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <p  className="blog-inner-detail" >Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12 featuered_product mb-0">
            <h1 className="common_heading mb-0 bestseller">
              Other Similar Latest Blogs
            </h1>
          </div>

          <div className="col-md-6 col-12">
            <div className="view_all">
              <Link to="/blog">View All</Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr className="mt-3 mb-3"></hr>
          </div>
        </div>

        <div className="blog_section row mb-5">
          {blog &&
            blog.map((item, index) => (
              <div className="col-md-3" key={index}>
                <img src={IMAGE_BASE_URL + item.blog_image} alt="blog_detail" />
                <h2>{item.createdAt}</h2>
                <h2>{item.title}</h2>
              </div>
            ))}
        </div>
      </div>
      <div className="footer_top">
        <Footer></Footer>
      </div>
    </>
  );
};
export default Blog_deatil;
