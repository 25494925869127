import React, { useContext, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_BASE_URL } from "../Config/constant";
import { Store } from "../Redux/store";
import {
  addToCart,
  addWishlist,
  removeWishlist,
} from "../Controller/commonController";
import { Modal } from "react-bootstrap";
import ThankYoupopup from "../component/PopupModals/thankYoupopup";
import { CiHeart } from "react-icons/ci";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHeart as faHeartRegular,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bestseller = ({ bestseller }) => {

  const { bannerProductData, getCart, wishlist, wishlistData } =
    useContext(Store);
console.log(bannerProductData,"bannerProductData____________")
  // const bestSeller=bestseller?.length > 0 ? bestseller : bannerProductData?.bestSellers
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // add to cart or add to wishlist
  const [selectedSize, setSelectedSize] = useState(null);
  const [productSieColor, setProductSieColor] = useState(null);
  const [tempData, setTempData] = useState("");
  const [content, setContent] = useState("");
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSizepopup, setShowSizepopup] = useState(false);
  const [forWishlist, setForWishlist] = useState(false);
const [wishlistTEmpData,setWishlishtTempData]=useState('')
const images_url = "https://sportking.s3.eu-north-1.amazonaws.com/image/";
  const handleClose = () => {
    setShowSizepopup(false);
    setSelectedSize(null)
  };

  const handleNavigate = () => {
    if (selectedSize === null) {
      setError("Please Select Size");
    } else {
      setSelectedSize(null);
      if (tempData?._id) {
        handleWishlist(tempData?._id);
        setShowSizepopup(false)
        return
      }
      handleCart();
      setShowSizepopup(false);
    }
  };  

  const handleCart = async (productDetails, data) => {
    setProductSieColor(data);
    if (selectedSize === null) {
      const temp = { productDetails, data };
      setTempData(temp);
      setShowSizepopup(true);
    } else {
      try {
        const response = await addToCart(
          productDetails || tempData.productDetails,
          selectedSize,
          productSieColor
        );
        if (response) {
          setContent({ h1: "Added to Cart", p: null });
          // setShowPopup(true);
          toast.success("Added to Cart");
          getCart();
          setSelectedSize(null);
          setError("");
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
        // Handle error here, e.g., show error message
      }
    }
  };
  const handleWishlist = async (id) => {
    if (selectedSize === null) {
      setError("Please Select Size");
      return;
    }
    const response = await addWishlist(id, productSieColor, selectedSize);
    if (response.status === true) {
      wishlist();
      setContent({ h1: "Added to Wishlist", p: null, h7: "Ok" });
      // setShowPopup(true);
      toast.success("Added to Wishlist!");
    }
  };
  const handleRemoveWishlist = async (item,check) => {
    try {
      const data = {
        product_id: check===null? item.product_id:item._id,
      };
      const response = await removeWishlist(data);
      if (response) {
        setContent({ h1: "Item removed from wishlist.", p: null, h7: "Ok" });
        toast('Item removed from wishlist');
        setShowPopup(true);
        wishlist();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleHeartClick = (item, data, status) => {


    if (status === true) {
      handleRemoveWishlist(item);
      return;
    }
    setProductSieColor(data);
    setTempData(item);
    if (selectedSize === null) {
      // setError("Please Select Size");
      setShowSizepopup(true);
      return;
    }
    handleWishlist(item._id);
  };

  const isProductInWishlist = (productId) => {
    return (
      (wishlistData &&
        wishlistData?.some((item) => item?.product_id?._id === productId)) ||
      false
    );
  };

  // End

  // mentor bestselllrt
  const handleCartBest = async (productDetails, data) => {
    if(data?.size){
      setProductSieColor(data?.size);
    }
    if (selectedSize === null) {
      const temp = { productDetails, data };
      setTempData(temp);
      setShowSizepopup(true);
    } else {
      try {
        const response = await addToCart(
          productDetails || tempData.productDetails,
          selectedSize,
          tempData.data
        );
        if (response) {
          setContent({ h1: "Added to Cart", p: null });
          // setShowPopup(true);
          toast.success("Added to Cart");
          getCart();
          setSelectedSize(null);
          setError("");
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
        // Handle error here, e.g., show error message
      }
    }
  };
  const desiredOrder = ["S", "M", "L", "XL", "XXL"]; 
  const sortedSizes = productSieColor?.sizes.sort((a, b) => {
    const indexA = desiredOrder?.indexOf(a.size);
    const indexB = desiredOrder?.indexOf(b.size);
  
    // Sort based on the desired order if present, otherwise natural order
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    } else if (indexB !== -1) {
      return 1;
    } else {
      return a.size.localeCompare(b.size); // Natural order for sizes not in desiredOrder
    }
  });

  return (
    <div className="best_selleres">
      <Slider {...settings}>
        {bestseller && bestseller?.length > 0
          ? bestseller?.map((item, index) => (
              <div className="best_seller_slider" key={index}>
                <img
                  src={IMAGE_BASE_URL + item.product_id.color_size_details[0].images[0]}
                  alt="logo"
                  className="products_img"
                />
                
                <div className="social_icon">
               
                {isProductInWishlist(item.product_id._id) ? (
                   <div className="select_product heart">
                      <FontAwesomeIcon
                        icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                        style={{
                          color: "#df1b22",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const data = item.product_id.color_size_details[0];
                          const status = isProductInWishlist(item.product_id._id);

                          handleHeartClick(item.product_id,data, status);
                        }}
                      />
                      </div>
                      ):       <img
                      src={process.env.PUBLIC_URL + "/img/ph_heart-bold.svg"}
                      alt="logo"
                      onClick={() => {
                        const data = item.product_id.color_size_details[0];
                        const status = isProductInWishlist(item.product_id._id);
                        handleHeartClick(item.product_id,data, status);
                      }}
                    />}
            
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/solar_bag.svg"
                    }
                    alt="logo"
                    onClick={() => {
                      const data =
                 item.product_id.color_size_details[0];
                      handleCart(item.product_id, data);
                    }}
                  />
                </div>
                <p className="sportking_seller">{item.product_title}</p>
                <p className="besiser_down">{item.product_name}</p>
                <h1 className="price">
                ₹{item?.product_id?.color_size_details?.[0]?.sizes?.[0]?.selling_price}
                </h1>
              </div>
            ))
          : bannerProductData?.bestSellers?.length > 0 &&
            bannerProductData?.bestSellers?.map((item, index) => (
              <div className="best_seller_slider" key={index}>
                <img
                  src={IMAGE_BASE_URL + item.image}
                  alt="logo"
                  className="products_img"
                />
                <div className="social_icon">
                  <div className="select_product heart">
                    {isProductInWishlist(item.product_id) ? (
                      <FontAwesomeIcon
                        icon={faHeartRegular} // Use faHeartRegular for unfilled heart
                        style={{
                          color: "#df1b22",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // productDetailsSize ?? item.color_size_details[0];
                          const status = isProductInWishlist(item.product_id);
                          const data=null
                          handleHeartClick(item,data, status);
                        }}
                      />
                    ) : (
                      <CiHeart
                        className="heart_icon profile_header"
                        onClick={() => {
                          const data =null
                          const status=false
                            // productDetailsSize ?? item.color_size_details[0];
                            // const status=isProductInWishlist(item._id)
                            handleHeartClick(item.product_id,data,status);
                        }}
                      />
                    )}
                  </div>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/img/solar_bag.svg"
                    }
                    alt="logo"
                    onClick={() => {
                      handleCart(item.product_id);
                    }}
                  />
                </div>
                <p className="sportking_seller">{item.title}</p>
                <p className="besiser_down">{item.product_name}</p>
   
                <h1 className="price">₹{item?.color_size_details?.[0]?.sizes?.[0]?.selling_price||item.product_price}</h1>

              </div>
            ))}
      </Slider>
      <Modal
        show={showSizepopup}
        onHide={handleClose}
        id="feedback_popup"
        className="select-size"
        centered
      >
        <div className="cross py-0">
          <img
            src={`${images_url}ball_icon.svg`}
            alt="logo"
            className="model_logo"
          />

          <img
            src={`${images_url}cross.svg`}
            alt="logo"
            onClick={handleClose}
            id="icon"
            className="model_logo"
          />
        </div>
        <div>
          <h1>Please Select a Size</h1>
          <div className="product-size">
          <ul>
    {productSieColor &&
      sortedSizes.map((size, index) => (
        (size.productshow === 'true' || size.productshow === true) ? (
          <li
            key={index}
            className={`cursor-pointer ${size.selling_price === '0' ? 'blurred' : ''}`}
            style={{
              border: selectedSize === size.size ? "2px solid black" : "",
            }}
            onClick={() => {
              setSelectedSize(size.size);
              setProductSieColor(prev => ({ ...prev, selling_price: size.selling_price }));
            }}
          >
            {size.size} {" "} (₹ {size.selling_price})
          </li>
        ) : null
      ))}
  </ul>
            {error ? (
              <p className="error_Validtaion" style={{ color: "red" }}>
                {error}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {content.p === !null ? (
          <Modal.Body className="py-0">
            <div className="thank_you_popup">
              <div className="row">
                <div className="col-md-4">
                  <h2>10%</h2>
                  <p className="discount_voucher">Discount Voucher</p>
                </div>
                <div className="col-md-8 px-0">
                  <h2 className="thank_you_heading"> Thank you</h2>
                  <p className="inner_content">
                    {" "}
                    for ordering from sportking Use code <span>
                      ”THANKS”
                    </span>{" "}
                    at checkout for 10% discount.
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          ""
        )}
        <div className="model_footer">
          <button className="view_cart" onClick={handleNavigate}>
            {content?.h3 ? "View my orders" : "Proceed To Next"}
          </button>
        </div>
      </Modal>
      {/* <ThankYoupopup isOpen={showPopup} content={content} /> */}
      <ToastContainer />
    </div>
  );
};

export default Bestseller;
