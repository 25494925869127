import Header from "../component/Header";
import Footer from "../component/Footer";
import "./maindashboard.css";
import Dashboard_sidebar from "./Dashboard_sidebar";
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
const  Return_Payements = () => {
    const navigate=useNavigate();
return(
    <>
    <Header></Header>
    <div className="container mt-3 mb-5">
        <div className="row">
            <div className="col-md-12">
                <div className="dashbaord">
                    <h1>my Account</h1>
                    <img src={process.env.PUBLIC_URL + "./img/address_line.svg"} alt="logo" /><br></br>
                    {/* <p>Lorem Ipsum is simply dummy text of the printing and <br></br> typesetting industry. Lorem Ipsum has been the industry's.</p> */}
                    <button onClick={()=>{navigate('/Listing')}}>Shop Now</button>
                </div>

            </div>
        </div>
        <div className="row">
            <div  className="col-md-3">
            <Dashboard_sidebar></Dashboard_sidebar>
            </div>
            <div className="col-md-9">
                 <div className="profile_detail">
                    <h1>My Orders</h1>
                    <p>The Most-Coveted Essentials. Curated By Us, Inspired By You.</p>

                 </div>
                 <div className="order-status row">
                    <div className="col-md-3 col-12">
                        <div className="order_img">
                            <div>
                            <p>+02</p>
                            </div>
                     <img src={process.env.PUBLIC_URL + "./img/product_two.png"} alt="logo" />
                            
                      
                        </div>
                      
                    </div>
                    <div className="col-md-2 padding-right">
                    <div className="product-up">
                      <span>Order Id</span>
                      <p>5615165413</p>
                      </div>

                       <div className="product-up">
                       <span>Amount</span>
                      <p>₹ 20,000</p>
                       </div>
                     
                    </div>
                    <div className="col-md-4 padding-right">
                    <div className="product-up">
                    <span>Order Date</span>
                    <p>Saturday, 29 Oct, 2023</p>
                    </div>
                        
                    <div className="product-up">
                       <span>Payment Status</span>
                      <p>COD ( Cash on Delivery )</p>
                       </div>

                    </div>
                    <div className="col-md-3 padding-right">
                    <div className="product-up">
                    <span>Delivery Date</span>
                    <p>Saturday, 29 Oct, 2023</p>
                    </div>


                    <div className="product-up">
                         <span>Status</span>
                        <p id="retun">Return</p>
                    </div>

                    </div>
                    
                    
                 </div>

                 <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>View Purchased Items</Accordion.Header>
        <Accordion.Body>
          <div className="row">
            <div className="col-md-7">
            <div className="cart_product_deatil mt-0">
                 <div className="cart_product_img" id="inner-product">
                 <img src={process.env.PUBLIC_URL + "./img/brand_four.png"} alt="logo"/>
                 </div>
                 <div className="total-product">
                 <h1>Women's Cocoon Sleeve Cable Jumper in Ivory</h1>
                 <p className="price mb-0">₹2,075.00</p>
                 <p className="product_color mb-0">Color : purple</p>
                 <p className="product_color">Size : XXL</p>
                
                 </div>
                </div>
            </div>
            <div className="col-md-3">
                <div id="order">
                <button >Return Order</button>
                </div>
               
            </div>
            <div className="col-md-2">
            <div id="order">
                <button >Feedback</button>
                </div>
            
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion>

            </div>
     
        </div>
    </div>
    <div className="footer_top">
      <Footer ></Footer>
      </div>
    </>
)
}
export default Return_Payements;
