import React, { useState } from "react";

const CustomZoom = ({ imgSrc }) => {
  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.clientX - left) / width * 100;
    const y = (e.clientY - top) / height * 100;
    setZoomStyle({
      backgroundPosition: `${x}% ${y}%`
    });
  };

  return (
    <div
      className="zoom-container"
      onMouseMove={handleMouseMove}
      style={{ backgroundImage: `url(${imgSrc})`, ...zoomStyle }}
    >
      <img src={imgSrc} alt="Zoom" className="zoom-image" />
    </div>
  );
};

export default CustomZoom;
