import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { post_Request } from "../Controller/Blog_Controller";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { Skeleton } from "antd";
import "./blog.css";
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../Config/constant";

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categroy, set_category] = useState("");
  const [filtercategory, setfiltercartegory] = useState([]);
  const [currentpage, set_current] = useState(1);
  const [totalpage, set_totalpage] = useState(1);
  const navigate = useNavigate();
  const [filterblog, set_filterblog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBlogs();
  }, [currentpage]);

  const getBlogs = async () => {
    const options = {
      url: "/common/get-blogs",
      postData: {
        type: "",
        condition: {},
        select: {},
        sort: {},
        skip: (currentpage - 1) * 10,
        limit: 60,
      },
    };
    const response = await post_Request(options);
    if (response && response.result && response.result.blogData) {
      const blogs = response.result.blogData.map((blog) => ({
        ...blog,
        createdAt: new Date(blog.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }),
      }));
      setBlog(blogs);
      set_filterblog(blogs);
      set_totalpage(Math.ceil(response.result.nbHits / 10));
      setLoading(false);
    }
  };

  const handleNavigation = (blogData) => {
    navigate(`/blog_detail/${blogData.blog_slug}`, {
      state: {
        title: blogData.title,
        description: blogData.description,
        image: blogData.blog_image,
        createdAt: blogData.createdAt,
        category: blogData.blog_category.blog_category_name,
      },
    });
  };

  const handlefiltercategory = (blog_category_name) => {
    set_category(blog_category_name);
    if (blog_category_name === "") {
      set_filterblog(blog);
    } else {
      const filterData = blog.filter((blogitm) => {
        return blogitm.blog_category.blog_category_name === blog_category_name;
      });
      set_filterblog(filterData);
    }
  };

  const handlesearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredBlogs = blog.filter((blogItem) =>
      blogItem.title.toLowerCase().includes(searchTerm)
    );
    set_filterblog(filteredBlogs);
  };

  // category api
  useEffect(() => {
    getcategory();
    window.scrollTo(0, 0);
  }, []);
  const getcategory = async () => {
    const options = {
      url: "/common/blog-categories",
    };
    const response = await post_Request(options);
    setfiltercartegory(response.result);
  };
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalpage) {
      set_current(pageNumber);
    }
  };

  const handlePrevPage = () => {
    if (currentpage > 1) {
      handlePageChange(currentpage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentpage < totalpage) {
      handlePageChange(currentpage + 1);
    }
  };

  return (
    <>
      <Header></Header>
      <div className="container mt-4 mb-5">
        <div className="row" id="blog">
          <div className="col-md-3">
            <h1 className="main_blog_heading">Blog's</h1>
          </div>
          <div className="col-md-5">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's.
            </p>
          </div>
          <div className="col-md-4">
            <div className="search">
              <input
                placeholder="
                    Search by Topic, Category"
                value={searchTerm}
                onChange={handlesearch}
              />
              <img
                src={process.env.PUBLIC_URL + "./img/search.svg"}
                alt="logo"
              />
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="col-md-12">
            <p className="filter_category_heading">Categories</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="blog_filtering">
              <li
                className={categroy === "" ? "filter_active" : ""}
                onClick={() => handlefiltercategory("")}
              >
                All
              </li>

              {filtercategory?.result &&
                filtercategory?.result?.map((item, index) => (
                  <li
                    key={index}
                    className={
                      item.blog_category_name === categroy
                        ? "filter_active"
                        : ""
                    }
                    onClick={() =>
                      handlefiltercategory(item.blog_category_name)
                    }
                  >
                    {item.blog_category_name}
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="blog_section">
          <div className=" row mt-3">
            {loading
              ? Array.from({ length: 6 }, (_, i) => (
                  <div className="col-md-3" key={i}>
                    <Skeleton active />
                  </div>
                ))
              : filterblog.length > 0 &&
                filterblog.map((blog_data, index) => (
                  <div
                    className="col-md-3 mb-4"
                    key={index}
                    onClick={() => handleNavigation(blog_data)}
                  >
                    <Link to="/blog_detail">
                      <img
                        src={IMAGE_BASE_URL + blog_data.blog_image}
                        alt="blog_detail"
                      />
                      <h2>{blog_data.createdAt}</h2>
                      <h2>{blog_data.title}</h2>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>

      <div className="container section_blog">
        <div className="row">
          <div className="col-md-12">
            <ul className="pagination">
              <li onClick={handlePrevPage}>
                <FaAngleLeft />
              </li>
              {Array.from({ length: totalpage }, (_, i) => (
                <li
                  key={i}
                  className={currentpage === i + 1 ? "active" : ""}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </li>
              ))}
              <li onClick={handleNextPage}>
                <FaAngleRight />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer_top">
        <Footer></Footer>
      </div>
    </>
  );
};
export default Blog;
