export const isValidMobile = (mobile) => {
    // Regular expression to validate mobile number
    const mobileRegex = /^(?!0)[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

export const validateAddAddress = (formData) => {
    const errors = {};

    if (!formData.firstName.trim()) {
        errors.firstName = 'First Name is required';
    }

    if (!formData.lastName.trim()) {
        errors.lastName = 'Last Name is required';
    }

    // if (!formData.phone.trim()) {
    //     errors.phone = 'Mobile Number is required';
    // } else if (!/^\d+$/.test(formData.phone)) {
    //     errors.phone = 'Mobile Number must contain only digits';
    // }
    if (formData && !formData.phone) {
        errors.phone = "Mobile number is required";
      } else if (!isValidMobile(formData.phone)) {
        errors.phone = 'Invalid phone number';
      }
    if (!formData.email.trim()) {
        errors.email = 'Email is required';
    } else if (!isValidEmail(formData.email.trim())) {
        errors.email = 'Invalid email format';
    }

    if (!formData.address1.trim()) {
        errors.address1 = 'Address is required';
    }

    if (!formData.country.trim()) {
        errors.country = 'Country is required';
    }

    if (!formData.state.trim()) {
        errors.state = 'State is required';
    }

    if (!formData.city.trim()) {
        errors.city = 'City is required';
    }

    if (!formData.pincode.trim()) {
        errors.pincode = 'Pincode is required';
    } else if (!/^\d+$/.test(formData.pincode.trim())) {
        errors.pincode = 'Pincode must contain only digits';
    }
    
    if(!formData.landMark.trim()){
        errors.landMark='LandMark is required'
    }

    return errors;
};

// Helper function to validate email format
const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

