
import './App.css';
import { BrowserRouter, Link, Outlet, useRoutes } from 'react-router-dom';
import Listing from './Listing_Page/Listing';
import Home from './Home/Home';
import Prodcut_deatil from './Product_detail/Product_detail';
import Cart from './Cart/Cart';
import Cart_address_detail from './Cart/Cart_address_detail';
import Add_new_address from './Cart/add_new_address';
import Cart_guest_user from './Cart/cart_guest_user';
import Confirmation from './Cart/Confirmation';
import Location from './Store_location/location';
import Maindashboard from './Dashboard/Maindashboard';
import My_Profile from './Dashboard/My_Profile';
import Whishlist from './Dashboard/Whishlist';
import My_order from './Dashboard/My_order';
import Return_Payements from './Dashboard/Return_Payment';
import Payments from './Dashboard/Payments';
import Blog from './Blog/blog';
import Blog_deatil from './Blog/blog_detail';
import Woodburn from './woodenbern/woodburn';
import Kid_homepage from './woodenbern/kid_homepage';
import Privacy_policy from './privacy/privacy_policy';
import Sublime_homepage from './woodenbern/Sublime_homepage';
import Mentor_homepage from './woodenbern/Mentor_homepage';
import OrderDetails from './Dashboard/orderDetails';
import ShippingReturnPolicy from './component/ShippingReturnPolicy';
import TermsNCondition from './component/TermsNCondition';

function App() {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/Listing', element: <Listing /> },
    {path: '/Product_detail/:slug', element:<Prodcut_deatil></Prodcut_deatil>},
    { path: '/Cart', element: <Cart /> },
    { path: '/add_new_address', element: <Add_new_address /> },
    {path: '/Cart_address_detail',element:<Cart_address_detail />},
    {path: '/Cart_guest_user',element:<Cart_guest_user />},
    {path: '/Confirmation',element:<Confirmation />},
    {path: '/Location',element:<Location />},
    {path:'/Maindashbaord' ,element:<Maindashboard></Maindashboard>},
   {path:'/Myprofile' ,element:<My_Profile></My_Profile>},
   {path:'/My_order' ,element:<My_order></My_order>},
   {path:'/order_details' ,element:<OrderDetails/>},
   {path:'/Whishlist' ,element:<Whishlist></Whishlist>},
   {path:'/Payments',element:<Payments></Payments>},
   {path:'/Return_Payements' ,element:<Return_Payements></Return_Payements>},
   {path:'/blog' ,element:<Blog></Blog>},
   {path:'/blog_detail/:slug',element:<Blog_deatil></Blog_deatil>},
   {path:'/woodburn',element:<Woodburn></Woodburn>},
    {path:'/Kid_home',element:<Kid_homepage></Kid_homepage>},
    {path:'/Privacy_policy',element:<Privacy_policy></Privacy_policy>},
    {path:'terms_conditions', element:<TermsNCondition/>},
   {path:'/Mentor_homepage',element:<Mentor_homepage></Mentor_homepage>},
   {path:'/Sublime',element:<Sublime_homepage></Sublime_homepage>},
   {path:'/shipping_return_policy',element:<ShippingReturnPolicy/>}

    
]);
return routes;
}

export default App;
